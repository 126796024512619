import React, { useState, useEffect } from 'react';
import BasicComponent from '../../../components/basicComponents/index';
import {
  Button
} from '@material-ui/core';
import { settingsServices } from '../../../services/api/helpers/settingsServices';

interface Props {
  settings: any,
  onBack?: any
}

interface PlantDetails {
  settings: any,
  pageData: PageData
}

interface PageData {
  name: string,
  buttons: any,
  slug: string,
  details: any
}

const PlantRegister = ({ settings, onBack }: Props) => {
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<PlantDetails>({
    settings,
    pageData: {
      name: '',
      buttons: [],
      slug: '',
      details: {}
    }
  });

  useEffect(() => {
    (async function () {
      await pageData();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, []);

  const pageData = async () => {
    let body;
    const id = parseInt(state.settings.split('-')[1]);
    if (state.settings === 'plant_details-0') {
      body = {
        slug: 'create',
        data: {
          page: 'plant_details'
        }
      };
    } else {
      body = {
        slug: 'read',
        data: {
          id,
          page: 'plant_details'
        }
      };
    }

    const response = await settingsServices.crudSettings('plant', body);
    if (response?.success) {
      const pageData = response.message.data.page_data;
      setState({
        ...state,
        pageData: {
          name: pageData.name,
          buttons: pageData.buttons,
          slug: pageData.slug,
          details: pageData.sections.plant_details
        }
      });
    }
  };

  const onUpdate = async (updateState: any, data: any) => {
    /** Save data
     * Data received from the children
     * On success receive response object to update state or reload the page data */
    const body = {
      slug: updateState,
      data: {}
    };

    if (updateState === 'update') {
      const page = data.slug.split('-')[0];
      const id = parseInt(data.slug.split('-')[1]);
      Object.assign(body.data, { id, page, item: data.data });

      const response = await settingsServices.crudSettings('plant', body);
      if (response?.success) {
        const pageData = response.message.data.page_data;
        setState({
          ...state,
          settings: pageData.sections.plant_details.slug,
          pageData: {
            ...state.pageData,
            name: pageData.name,
            slug: pageData.slug,
            details: pageData.sections.plant_details
          }
        });
      }
    }

    if (updateState === 'delete') {
      const page = data.split('-')[0];
      const id = parseInt(data.split('-')[1]);
      Object.assign(body.data, { id, page });

      const response = await settingsServices.crudSettings('plant', body);
      if (response?.success) {
        onBack();
      }
    }
  };

  return (
    <>
      { loading
        ? <BasicComponent.Loader size={60} message="Loading data..." />
        : <section className="plant-settings gcrg30">
          <div className="heading two gcrg20">
            <h1 className="uppercase pc1 jstart">{state.pageData.name}</h1>
            {state.pageData.buttons.map(({ action, slug, name }: any) => {
              if (action === 'Back') { return <Button key={slug} className="btn small jend" variant="contained" color="primary" onClick={onBack}>{name}</Button>; }
            })}
          </div>
          <div className="card one grg20">
            <div className="details one grg20">
              <BasicComponent.Details detailsPanel="plant-details" data={state.pageData.details} onUpdate={onUpdate}/>
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default PlantRegister;
