import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const ForgotPasswordSuccessful = () => {
  const history = useHistory();

  const onOK = () => {
    return history.push('/login');
  };

  return (
    <section className="user forgot-password">
      <div className="card">
        <div className="header">
          <div className="logo">
            <img src="img/Logo-L.png" alt="logo"/>
          </div>
        </div>
        <form className="body" autoComplete="off">
          <h1 className="center">Password reset email sent</h1>
          <p className="center">Weve sent a email to the provided address.</p>
          <p className="center">Please click on the link in this email to reset your password.</p>
          <Button type="submit" variant="contained" color="primary" onClick={onOK}>Ok</Button>
        </form>
      </div>
    </section>
  );
};

export default ForgotPasswordSuccessful;
