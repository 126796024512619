import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { userServices } from '../../../services/api/helpers/userServices';
import {
  TextField,
  Button,
  Modal,
  InputLabel
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// eslint-disable-next-line no-unused-vars
import { Formik, Form, FormikProps } from 'formik';
// @ts-ignore
import * as Yup from 'yup';
import './styles.sass';

const UploadImageBase64 = ({ upload }: any) => {
  const [image, setImage] = useState({ preview: '', raw: '' });

  const handleChange = (e:any) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        upload(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="upload">
      <label htmlFor="upload-button">
        {image.preview ? (
          <img src={image.preview} alt="dummy" width={150} />
        ) : (
          <>
            <CloudUploadIcon fontSize="large" />
            <h5 className="center">Upload</h5>
          </>
        )}
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: 'none' }}
        onChange={handleChange}
      />
    </div>
  );
};
interface UserObject {
  name: string
  contact_number: string
  website_url: string
  logo_base64: any
}

const RegisterCompany = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('an Error has occured.');

  const handleClose = () => {
    setOpen(false);
  };

  const createNewCompany = async (data: UserObject, actions: any) => {
    const response = await userServices.createAccount(data);
    if (response?.success) {
      actions.setSubmitting(false);
      history.push('/register-successful');
    } else {
      actions.setSubmitting(false);
      setErrorMessage(response?.message.data.data.message);
      setOpen(true);
    }
  };

  const updateLogo = (value:string, values: any) => {
    values.logo_base64 = value;
  };

  const modalBody = (
    <div className="card modal one">
      <div className="header">
        <h2 className="center bold">Error</h2>
      </div>
      <div className="body">
        <p className="center">{errorMessage}</p>
      </div>
    </div>
  );

  return (
    <section className="user registration company">
      <div className="card">
        <div className="header">
          <div className="logo">
            <img src="img/Logo-L.png"/>
          </div>
        </div>
        <Modal open={open} onClose={handleClose}>
          {modalBody}
        </Modal>
        <div className="body">
          <h1 className="center">Create Account</h1>
          <Formik
            initialValues={{
              name: '',
              contact_number: '',
              website_url: '',
              logo_base64: ''
            }}
            onSubmit={(values: UserObject, actions) => {
              createNewCompany(values, actions);
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Please enter Company name'),
              contact_number: Yup.string().required('Please enter contact number'),
              website_url: Yup.string().required('Please enter website')
              // logo_base64: Yup.string().required('Please upload your Company logo')
            })}
          >
            {(props: FormikProps<UserObject>) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                isSubmitting
              } = props;
              return (
                <Form className="one grg40">
                  <div className="one grg20">
                    <TextField
                      name="name"
                      id="company-name"
                      placeholder="Company Name"
                      value={values.name}
                      type="text"
                      helperText={
                        errors.name && touched.name
                          ? errors.name
                          : ''
                      }
                      error={
                        !!(errors.name && touched.name)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      name="contact_number"
                      id="contact-number"
                      placeholder="Contact Number"
                      value={values.contact_number}
                      type="text"
                      helperText={
                        errors.contact_number && touched.contact_number
                          ? errors.contact_number
                          : ''
                      }
                      error={
                        !!(errors.contact_number && touched.contact_number)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      name="website_url"
                      id="website"
                      placeholder="website"
                      value={values.website_url}
                      type="text"
                      helperText={
                        errors.website_url && touched.website_url
                          ? errors.website_url
                          : ''
                      }
                      error={
                        !!(errors.website_url && touched.website_url)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <InputLabel htmlFor="logo-base64">Upload Company Logo</InputLabel>
                    <UploadImageBase64 upload={(value: string) => updateLogo(value, values)}/>
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >Next</Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default RegisterCompany;
