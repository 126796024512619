import React, { useState, useEffect } from 'react';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import BasicComponent from '../../../components/basicComponents/index';
import EnhancedComponent from '../../../components/enhancedComponents';
import {
  Button
} from '@material-ui/core';
import { settingsServices } from '../../../services/api/helpers/settingsServices';
import UserRegister from './user-register';
import './styles.css';

interface ProjectSettingsState {
  slugID: string,
  data: any,
  filters: Array<any>,
  pagination: any,
  total_results: number
}

interface Filters {
  filters: Array<any>
}

const UserManager: React.FC<{}> = () => {
  const { filtersData, pageAndFilterStatus, pagination, paginateSet } = useSearchAndFilterContext();
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(false);
  const [state, setState] = useState<ProjectSettingsState>({
    slugID: 'user_details-0',
    data: [],
    filters: [],
    pagination: {},
    total_results: 0
  });

  useEffect(() => {
    (async function () {
      await pageData();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, [details]);

  useEffect(() => {
    /** Filtering and Pagination */
    if (pageAndFilterStatus === 'UPDATED') {
      filterAndPagination(
        filtersData.filters && filtersData.filters.length >= 0 ? filtersData : null,
        pagination && pagination.length >= 0 ? pagination : null
      );
    }
  }, [filtersData, pageAndFilterStatus]);

  const filterAndPagination = async (filtersData:any, paginationData: any) => {
    const { filters } = filtersData;

    const body: {[key: string]: any} = {
      slug: 'read',
      data: {
        page: 'user_register'
      },
      pagination: {}
    };
    if (filtersData) body.filters = filters;
    if (paginationData && paginationData.length > 0) body.pagination = paginationData[0];

    const response = await settingsServices.crudSettings('user', body);
    if (response?.success) {
      const { filters, total_results, pagination } = response.message;
      const { page_data } = response.message.data;
      setState({
        ...state,
        data: page_data,
        filters,
        pagination,
        total_results
      });
      paginateSet(pagination ? [pagination] : []);
    } else {
      paginateSet(pagination);
    }
  };

  const pageData = async () => {
    const { filters } = filtersData;
    const body: {[key: string]: any} = {
      slug: 'read',
      data: {
        page: 'user_register'
      },
      filters,
      pagination: {}
    };
    if (pagination && pagination.length > 0) body.pagination = pagination[0];

    const response = await settingsServices.crudSettings('user', body);
    if (response?.success) {
      const pageData = response.message.data.page_data;
      const { filters, total_results, pagination } = response.message;
      setState({
        ...state,
        data: pageData,
        filters,
        pagination,
        total_results
      });
      paginateSet(pagination ? [pagination] : []);
    } else {
      paginateSet(pagination);
    }
  };

  const create = () => {
    setState({ ...state, slugID: 'client_details-0' });
    setDetails(true);
  };

  const changeView = (view: any) => {
    setState({ ...state, slugID: view });
    setDetails(true);
  };

  const onBack = () => {
    setLoading(true);
    setDetails(false);
  };

  return (
    <React.Fragment>
      <EnhancedComponent.SideDrawer/>
      <div className="container">
        {
          loading
            ? <BasicComponent.Loader size={60} message="Loading data..." />
            : !details
              ? <section className="user-manager gcrg30">
                <div className="heading two gcrg20">
                  <h1 className="uppercase pc1 jstart">{state.data.heading}</h1>
                  {state.data.buttons.map(({ action, name, has_permissions }: any) => {
                    if (action === 'Create') { return <Button className="btn small jend" variant="contained" color="primary" disabled={!has_permissions} onClick={create}>{name}</Button>; }
                  })}
                </div>
                <div className="card one grg20">
                  <BasicComponent.DynamicSettingsTable section_type={0} tableName="table user-table col2-span" {...state} changeView={changeView}/>
                </div>
              </section>
              : <UserRegister settings={state.slugID} onBack={onBack} />
        }
      </div>
    </React.Fragment>
  );
};

export default UserManager;
