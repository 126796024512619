import React, { useState, useEffect } from 'react';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import EnhancedComponent from '../../../components/enhancedComponents';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  IconButton,
  TablePagination
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import '.././style.css';

interface Props {
  tableName: string,
  data: any,
  total_results: number,
  section_type: number,
  pagination: any
}
const Row = (row:any) => {
  const { cells, collapsed_rows_included } = row;
  const [open, setOpen] = useState<boolean>(false);

  const ExpandTable = () => {
    setOpen(state => {
      return !state;
    });
  };

  return (
    <React.Fragment key={row.row_slug}>
      <TableRow className={!open ? '' : 'opened'}>
        { cells.map((value:any, i:number) => (
          <TableCell key={i} align={value.align}>{value.text}</TableCell>
        ))}
        { !!collapsed_rows_included && collapsed_rows_included.length > 0
          ? <TableCell key={row.id} className="table-btns">
            <div className="buttons">
              <IconButton
                aria-label="expand row"
                className="dropdown-btn"
                size="small"
                onClick={ExpandTable}
              >{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
            </div>
          </TableCell>
          : null
        }
      </TableRow>
      {open && !!collapsed_rows_included && collapsed_rows_included.map((data:any, i:number) => (
        <TableRow key={i} className={`inner ${i === collapsed_rows_included.length - 1 ? 'last-inner' : ''}`}>
          {data?.cells.map((value:any, i:number) => (
            <React.Fragment key={`collapsed-${i}`}>
              <TableCell align={value.align}>{value.text}</TableCell>
              {i + 1 === data.cells.length ? <TableCell></TableCell> : null}
            </React.Fragment>
          ))}
        </TableRow>
      ))}
    </React.Fragment>
  );
};
export const DynamicTable = ({ tableName, data, total_results, section_type, pagination }: Props) => {
  const { paginate } = useSearchAndFilterContext();
  const headerRow = data.header_row.cells;
  const footerRow = data.footer_row.cells;
  const [tableBody, setTableBody] = useState(data.body);

  const [searchAndFilterOpen, setSearchAndFilterOpen] = useState(false);
  const [currentTarget, setCurrentTarget] = useState();
  const [valueData, setValueData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setTableBody(data.body);
  }, [data]);

  useEffect(() => {
    setPage(pagination?.page ?? 0);
    setRowsPerPage(pagination?.rows_per_page ?? 10);
  }, [pagination]);
  /** Search and filter functionality
   * Start
  */
  const closeSearchAndFilter = () => {
    setSearchAndFilterOpen(false);
  };

  const searchAndFilter = ({ currentTarget }: any, value: any) => {
    const { category_name } = value;
    if (category_name) {
      setSearchAndFilterOpen(false);
      setCurrentTarget(currentTarget);
      setValueData(value);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    paginate(rowsPerPage, newPage, section_type);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    paginate(parseInt(event.target.value), 0, section_type);
  };
  /** Search and filter functionality
   * end
  */

  return (
    <React.Fragment>
      <TableContainer className={tableName}>
        <Table stickyHeader>
          <TableHead className="thead">
            <TableRow>
              {headerRow.map((value:any, i:number) => (
                <TableCell key={`theadCell-${i}`} align={value.align} onClick={(event) => searchAndFilter(event, value)}>{value.text}</TableCell>
              ))}
              <EnhancedComponent.SearchAndFilter open={searchAndFilterOpen} currentTarget={currentTarget} data={valueData} openState={closeSearchAndFilter}/>
            </TableRow>
          </TableHead>
          <TableBody className="tbody">
            {tableBody.map((row:any, i:number) => (
              <Row key={`${row.row_slug ?? i}`} {...row} />
            ))}
          </TableBody>
          <TableFooter className="tfoot">
            <TableRow>
              {footerRow.map((value:any, i:number) => (
                <TableCell key={`tfootCell-${i}`} align={value.align}>{value.text}</TableCell>
              ))}
            </TableRow>
          </TableFooter>
        </Table>
        <TablePagination
          component="div"
          count={total_results}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
};
