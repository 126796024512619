import React, { useState, useEffect } from 'react';
import { useTableContext } from '../../../context/tableProvider';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import EnhancedComponent from '../../../components/enhancedComponents';
import BasicComponent from '../../../components/basicComponents/index';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  // TextField,
  FormControl,
  InputLabel,
  TextareaAutosize
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
// import ClearIcon from '@material-ui/icons/Clear';
import '.././style.css';

interface Table {
  tableName: string,
  data: any,
  filters?: Array<any>,
  total_results?: number,
  slug?: string,
  onUpdate?: any
}

interface UpdateTableRow {
  page: string,
  user_id: number,
  item: any
}

const Row = (row:any) => {
  const { globalEdit, updateJobAccessSet } = useTableContext();
  const { cells } = row;
  const [updateData, setUpdateData] = useState<UpdateTableRow>({
    page: 'user_jobs',
    user_id: 0,
    item: {}
  });

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(updateData.item, 'key')) {
      updateJobAccessSet(updateData);
    }
  }, [updateData]);

  const updateCell = (value:string, cellName:string) => {
    /** Update table cell.
     * push data to update State called 'updateTableRow' for global save. */
    let newDataObject;
    cells.forEach((cell:any) => {
      if (cell.name === cellName) {
        newDataObject = { key: cellName, value: value };
      }
    });

    setUpdateData({
      ...updateData,
      item: newDataObject
    });
  };

  const ActiveState = (state: any) => {
    switch (state.state) {
    case true:
      return <CheckIcon fontSize="small" className="correct"/>;

    default:
      // return <ClearIcon fontSize="small" className="in-correct"/>;
      return null;
    }
  };

  const inputType = (input:any, i:number) => {
    const inputType = input.type;
    switch (inputType) {
    case 'empty':
      return (
        <div/>
      );
    case 'text':
      return (
        input.text
      );
    case 'text_field':
      return (
        <BasicComponent.CustomTextField {...input} updateCell={updateCell}/>
      );
    case 'checkbox':
      return (
        <BasicComponent.CheckBox keyName={input.name} label={input.label} value={input.value} onBlur={updateCell} />
      );
    case 'dropdown':
      return (
        <BasicComponent.Dropdown keyName={input.name} label={input.label} val={input.dropdown.active} options={input.dropdown.options} validation={input.validation} onBlur={updateCell}/>
      );
    case 'text_area':
      return (
        <FormControl key={`text-area-${i}`} className="col3-span">
          <InputLabel shrink htmlFor="label">{input.label}</InputLabel>
          <TextareaAutosize aria-label={input.label} rowsMin={3} placeholder="Comment" />
        </FormControl>
      );

    default:
      break;
    }
  };

  return (
    <>
      <TableRow>
        {cells.map((value:any, i:number) => (
          {
            ...!globalEdit
              ? value.type !== 'checkbox'
                ? <TableCell key={`tbodyCell-${i}`} align={value.align}>{value.text}</TableCell>
                : <TableCell key={`tbodyCell-${i}`} align={value.align}><ActiveState state={value.value !== 0}/></TableCell>
              : <TableCell key={`tbodyCell-${i}`} align={value.align}>{inputType(value, i)}</TableCell>
          }
        ))}
      </TableRow>
    </>
  );
};

export const JobAccessTable = (props:any) => {
  const { paginate } = useSearchAndFilterContext();
  const { tableName, filters, total_results, onUpdate, section_type, pagination } = props;
  const { header_row, body, buttons } = props.data.table;

  const [open, setOpen] = useState(false);
  const [currentTarget, setCurrentTarget] = useState();
  const [valueData, setValueData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setPage(pagination?.page ?? 0);
    setRowsPerPage(pagination?.rows_per_page ?? 10);
  }, [pagination]);
  /** Search and filter functionality
   * Start
  */
  const openState = () => {
    setOpen(false);
  };

  const searchAndFilter = ({ currentTarget }: any, value: any) => {
    const { category_name } = value;
    if (category_name) {
      setOpen(true);
      setCurrentTarget(currentTarget);
      setValueData(value);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    paginate(rowsPerPage, newPage, section_type);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    paginate(parseInt(event.target.value), 0, section_type);
  };
  /** Search and filter functionality
   * end
  */

  return (
    <>
      <TableContainer className={tableName}>
        { !!filters && filters.length > 0
          ? <BasicComponent.FilterResults filters={filters}/>
          : null
        }
        <Table stickyHeader>
          <TableHead className="thead">
            <TableRow>
              {header_row.cells.map((value:any, i:number) => (
                <TableCell key={`theadCell-${i}`} align={value.align} onClick={(event:any) => searchAndFilter(event, value)}>{value.text}</TableCell>
              ))}
              <EnhancedComponent.SearchAndFilter open={open} currentTarget={currentTarget} data={valueData} openState={openState}/>
            </TableRow>
          </TableHead>
          <TableBody className="tbody">
            {body.map((row:any) => (
              <Row key={row.row_slug} {...row} buttons={buttons} onUpdate={onUpdate}/>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total_results}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};
