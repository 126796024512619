import React, { useState } from 'react';
import EnhancedComponent from '../../../components/enhancedComponents';
import BasicComponent from '../../../components/basicComponents/index';
// import { settingsServices } from '../../../services/api/helpers/settingsServices';
import ClientManager from './client-manager';
import JobManager from './job-manager';

interface ClientAndJobContainer {
  data: any
}

const ClientAndJobContainer = () => {
  const [reloadNav, setReloadNav] = useState(false);
  const [currentView, setCurrentView] = useState(true);
  const [display, setDisplay] = useState(true);
  const swap = {
    name: 'swap_container',
    dropdown: {
      options: [
        {
          id: 1,
          name: 'Client'
        },
        {
          id: 2,
          name: 'Job'
        }
      ],
      active: 0
    }
  };

  const swapView = (value:string, cellName:string) => {
    /** Change container view. */
    if (parseInt(value) === 1) {
      setCurrentView(true);
    } else {
      setCurrentView(false);
    }
  };

  return (
    <>
      <EnhancedComponent.SideDrawer reload={reloadNav} onReload={() => setReloadNav(false)} />
      <div className="container">
        { display
          ? <section className="client-job-manger gcrg30">
            <div className="heading two gcrg20">
              <div className="text two">
                <h1 className="uppercase pc1 jstart">Client & Job Register</h1>
                <BasicComponent.DropdownClick keyName={swap.name} val={swap.dropdown.active} options={swap.dropdown.options} onClick={swapView} />
              </div>
            </div>
          </section>
          : null
        }
        { currentView
          ? <ClientManager setDisplay={(view:any) => setDisplay(view)} />
          : <JobManager setReloadNav={(reload:boolean) => setReloadNav(reload)}/>
        }
      </div>
    </>
  );
};

export default ClientAndJobContainer;
