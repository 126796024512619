import React, { useState } from 'react';
// import { useTableContext } from '../../../context/tableProvider';
import {
  // IconButton,
  TextField
} from '@material-ui/core';
// import EditIcon from '@material-ui/icons/Edit';
// import SaveIcon from '@material-ui/icons/Save';
import './input-type.styles.sass';
import { Tooltip } from '../tooltip/tooltip.component';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
interface CustomTextField {
  name: string,
  label?: string,
  value: number,
  defaultVal: number,
  text?: string,
  tooltip?: string,
  disabled: boolean,
  validation: any,
  updateCell: any
}

type ErrorValidation = {
  status: boolean,
  message: string
}

const validationProps = (pattern: any) => {
  if (pattern) {
    return {
      inputProps: { pattern }
    };
  }
};

export const CustomTextField: React.FC<CustomTextField> = (props: CustomTextField) => {
  const { name, label, value, text, tooltip, disabled, validation, updateCell, defaultVal } = props;
  const [error, setError] = useState<ErrorValidation>({
    status: false,
    message: ''
  });

  const onBlur = (inputValue: any, name: any) => {
    if (!error.status && inputValue !== value) {
      updateCell(inputValue, name);
    }
  };

  const validate = (event: any) => {
    /** Validity boolean options
     * badInput, customError, patternMismatch, rangeOverflow, rangeUnderflow
     * stepMismatch, tooLong, tooShort, typeMismatch, valid, valueMissing
    */
    const { validationMessage } = event.target;
    const { valid } = event.target.validity;
    setError((prevErrorMessage) => ({
      ...prevErrorMessage,
      status: !valid,
      message: !!validationMessage && validationMessage
    }));
  };

  return (
    <TextField
      error={error.status}
      key={name}
      id={name}
      label={
        label && (
          <React.Fragment>
            <span>{label}</span>
            {tooltip
              ? <Tooltip
                content={tooltip}
                direction="bottom">
                <InfoOutlinedIcon className="info-icon" fontSize="small" />
              </Tooltip>
              : ''}
          </React.Fragment>
        )
      }
      defaultValue={defaultVal ?? (value || text)}
      disabled = {disabled}
      onFocus={(event) => validate(event)}
      onKeyUp={(event) => validate(event)}
      onBlur={(event) => onBlur(event.target.value, name)}
      InputLabelProps={{
        shrink: true
      }}
      { ...validationProps(!!validation && validation.pattern) }
      required={!!validation && validation.required}
      helperText={error.message}
    />
  );
};
