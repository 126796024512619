import React, { useState, useEffect } from 'react';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import BasicComponent from '../../../components/basicComponents/index';
import {
  Button
} from '@material-ui/core';
import { settingsServices } from '../../../services/api/helpers/settingsServices';
import './styles.sass';

interface ProjectSettingsState {
  slugID: string,
  data: any,
  filters: Array<any>,
  pagination: any,
  total_results: number
}

interface Props {
  setReloadNav: any
}

interface Filters {
  filters: Array<any>
}

const JobManager = (props: Props) => {
  const { filtersData, pagination, paginateSet, pageAndFilterStatus } = useSearchAndFilterContext();
  const { setReloadNav } = props;
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<ProjectSettingsState>({
    slugID: 'job_register-0',
    data: [],
    filters: [],
    pagination: pagination || {},
    total_results: 0
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: ''
  });

  useEffect(() => {
    (async function () {
      await pageData();
      setTimeout(() => {
        setLoading(false);
        setReloadNav(false);
      }, 1000);
    })();
  }, []);

  useEffect(() => {
    /** Filtering and Pagination */
    if (pageAndFilterStatus === 'UPDATED') {
      filterAndPagination(
        filtersData.filters && filtersData.filters.length >= 0 ? filtersData : null,
        pagination && pagination.length >= 0 ? pagination : null
      );
    }
  }, [filtersData, pageAndFilterStatus]);

  const filterAndPagination = async (filtersData:any, paginationData:any) => {
    const { filters } = filtersData;

    const body: {[key: string]: any} = {
      slug: 'read',
      data: {
        page: 'job_register'
      },
      pagination: {}
    };
    if (filtersData) body.filters = filters;
    if (paginationData && paginationData.length > 0) body.pagination = paginationData[0];

    const response = await settingsServices.crudSettings('clientAndJob', body);
    if (response?.success) {
      const { filters, total_results, pagination } = response.message;
      const { page_data } = response.message.data;
      setState({
        ...state,
        data: page_data,
        filters,
        pagination,
        total_results
      });
      paginateSet(pagination ? [pagination] : []);
    } else {
      paginateSet(pagination);
    }
  };

  const pageData = async () => {
    const { filters } = filtersData;
    const body = {
      slug: 'read',
      data: {
        page: 'job_register'
      },
      filters,
      pagination: {}
    };
    if (pagination && pagination.length > 0) body.pagination = pagination[0];
    const response = await settingsServices.crudSettings('clientAndJob', body);
    if (response?.success) {
      const pageData = response.message.data.page_data;
      const { filters, total_results } = response.message;
      setState({
        ...state,
        data: pageData,
        filters,
        pagination,
        total_results
      });
    }
  };

  const create = async () => {
    const body = {
      slug: 'create',
      data: {
        page: 'job_register'
      }
    };

    const response = await settingsServices.crudSettings('clientAndJob', body);
    if (response?.success) {
      const newTableRow = response.message.data.new_row;
      setState({
        ...state,
        data: {
          ...state.data,
          table: {
            ...state.data.table,
            body: [
              newTableRow, ...state.data.table.body
            ]
          }
        }
      });
    }
  };

  const onUpdate = async (updateState: any, data: any) => {
    /** Save data
     * Data received from the children
     * On success receive response object to update state or reload the page data */
    const body = {
      slug: updateState,
      data: {}
    };

    if (data.data.length < 1) {
      return;
    }

    if (updateState === 'update') {
      const page = data.slug.split('-')[0];
      const id = parseInt(data.slug.split('-')[1]);
      Object.assign(body.data, { id, page, item: data.data });

      const response = await settingsServices.crudSettings('clientAndJob', body);
      if (response?.success) {
        const newTableRow = response.message.data.new_data;
        const oldTableBody = state.data.table.body;
        const newTableBody = oldTableBody.map((row:any) => {
          if (row.row_slug === newTableRow.row_slug) {
            return newTableRow;
          } else if (row.row_slug === 'job_register-0') {
            return newTableRow;
          }
          return row;
        });
        setReloadNav(true);
        setState({ ...state, data: { ...state.data, table: { ...state.data.table, body: newTableBody } } });
      } else {
        setOpenAlert(true);
        setAlertMessage({
          message: response?.message ? response?.message.data.message : 'There has been an error.',
          severity: 'error'
        });
      }
    }

    if (updateState === 'delete') {
      const page = data.split('-')[0];
      const id = parseInt(data.split('-')[1]);
      Object.assign(body.data, { id, page });

      const response = await settingsServices.crudSettings('clientAndJob', body);
      if (response?.success) {
        setReloadNav(true);
        pageData();
        setOpenAlert(true);
        setAlertMessage({
          message: response.message.data.message,
          severity: 'success'
        });
      }
    }
  };

  return (
    <>
      {
        loading
          ? <BasicComponent.Loader size={60} message="Loading data..." />
          : <section className="job-manager gcrg30">
            <div className="button one gcrg20">
              {/* <h1 className="uppercase pc1 jstart">{state.data.heading}</h1> */}
              {state.data.buttons.map(({ action, name, has_permissions }: any) => {
                if (action === 'Create') { return <Button className="btn small jend" variant="contained" color="primary" disabled={!has_permissions} onClick={create}>{name}</Button>; }
              })}
            </div>
            <div className="card two grg20">
              <BasicComponent.DynamicSettingsCollapsibleTable section_type={0} tableName="table job-table col2-span" {...state} onUpdate={onUpdate}/>
              <BasicComponent.CustomizedSnackbars message={alertMessage.message} severity={alertMessage.severity} onOpen={openAlert} onClose={(open:any) => setOpenAlert(open)}/>
            </div>
          </section>
      }
    </>
  );
};

export default JobManager;
