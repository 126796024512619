import React from 'react';
// import { Grid } from '@material-ui/core';

export const BAR_COLORS = [
  '#1565c0',
  '#00C49F',
  'darkorange',
  'gold',
  'grey',
  'lightblue',
  'purple',
  '#A20050'
];

export const PIE_COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'grey', 'lightblue', 'purple', '#A20050'];
export interface PieChartLegendProps{
  name: string,
  percentage?: string,
  color: string,
  i?: number
}

const legendIcon = (color:string) => ({
  backgroundColor: color,
  borderRadius: 3,
  height: 15,
  width: 15
});

const truncate = (str:string, n:number) => {
  return (str.length > n) ? str.substr(0, n - 1) : str;
};

const concatName = (name:any) => {
  if (name.length > 11 && name.includes('&')) {
    const firstPart = truncate(name.split(' & ')[0], 5);
    const secondPart = truncate(name.split(' & ')[1], 5);

    return `${firstPart} & ${secondPart}`;
  } else if (name.length > 11 && name.includes('and')) {
    const firstPart = truncate(name.split(' and ')[0], 5);
    const secondPart = truncate(name.split(' and ')[1], 5);

    return `${firstPart} & ${secondPart}`;
  } else if (name.length > 11) {
    const firstPart = truncate(name, 5);

    return firstPart;
  } else {
    return name;
  }
};

const pieChartLegendItem = (props: { item: PieChartLegendProps, i: number}) => {
  const { color, percentage, name } = props.item;
  return (
    <div key={`legend-${props.i}`} className="item">
      <div style={legendIcon(color)}/>
      {
        percentage &&
          <p className="item-percentage">{percentage === 'NaN%' ? '0%' : percentage}</p>
      }
      <p className="name" title={name}>{concatName(name)}</p>
    </div>
  );
};

const chartLegendItem = (props: { item: PieChartLegendProps, i: number}) => {
  const { color, percentage, name } = props.item;
  return (
    <div key={`legend-${props.i}`} className="item">
      <div style={legendIcon(color)}/>
      {
        percentage &&
          <p className="item-percentage">{percentage}</p>
      }
      <p className="name" title={name}>{name}</p>
    </div>
  );
};

export const ChartLegend = (props:PieChartLegendProps[]) => {
  return (
    <>
      {
        props.map((item: PieChartLegendProps, i: number) => chartLegendItem({ item, i }))
      }
    </>
  );
};

export const PieChartLegend = (props:PieChartLegendProps[]) => {
  return (
    <>
      {
        props.map((item: PieChartLegendProps, i: number) => pieChartLegendItem({ item, i }))
      }
    </>
  );
};

export const BarChartLegend = (props:PieChartLegendProps[]) => {
  return (
    <>
      {
        props.map((item: PieChartLegendProps, i: number) => pieChartLegendItem({ item, i }))
      }
    </>
  );
};
