/* eslint-disable */
import * as React from 'react';
import { number } from 'yup';

export interface PaginationType {
  type: number,
  rows_per_page: number
  page: number
};
type StatusType = 
  | "UPDATED"
  | "SET"
type SearchAndFilterType = {
  filtersData: {
    filters: Array<any>
  };
  pagination: PaginationType[];
  sortTableBy: (category_name: any, filter: string, sort: string, title: string) => void;
  searchResults: (category_name: any, filter: string, sort: string, title: string) => void;
  removeFilter: (category_name: any) => void;
  clearFilters: () => void;
  paginate: (rows_per_page: number, page: number, section_type: number) => void;
  paginateSet: (pagination: PaginationType[]) => void
  sorting: (category_name: string, sort:string, title: string) => void;
  pageAndFilterStatus: StatusType
}

export const SearchAndFilterContext = React.createContext<SearchAndFilterType>({
  filtersData: {
    filters: []
  },
  pagination: [],
  sortTableBy: () => null,
  searchResults: () => null,
  removeFilter: () => null,
  clearFilters: () => null,
  paginate: () => null,
  paginateSet: () => null,
  sorting: () => null,
  pageAndFilterStatus: "SET"
});

export const useSearchAndFilterContext = () => React.useContext(SearchAndFilterContext);

const SearchAndFilterProvider: React.FC<{}> = ({ children }) => {
  const [filtersData, setFiltersData] = React.useState<any>({
    filters: []
  })
  const [pagination, setPagination] = React.useState<PaginationType[]>([]);
  const [pageAndFilterStatus, setPageAndFilterStatus] = React.useState<StatusType>("SET");

  function cleanSortData(sortData: any) {
    if(!sortData || sortData.length === 0) {
      return [];
    }
    for(let i = 0; i < sortData.length; i++) {
      if(sortData[i].filter === "") {
        sortData.splice(i, 1);
      }
    }
    return sortData;
  }

  function sortTableBy (category_name: string, filter: string, sort: string, title: string) {
    /**Set the filters */
    const sortData: any = cleanSortData(filtersData.filters);
    sortData.push({category_name, filter, sort, title});
    setFiltersData({
      filters: sortData
    })
    setPageAndFilterStatus("UPDATED");
  };

  function searchResults (category_name: string, filter: string, sort: string, title: string) {
    /**Set the filters */
    const filterData = {
      category_name,
      filter,
      sort,
      title
    }
    const foundSearch = filtersData.filters.find((filter: any) => (filter.category_name === filterData.category_name));

    if (foundSearch && filtersData.filters) {
      const index = filtersData.filters.indexOf(foundSearch);
      filtersData.filters[index] = filterData;
      const newFilterData = filtersData.filters;
      setFiltersData({
        filters: newFilterData
      })
    } else {
      setFiltersData({
        filters: [...filtersData.filters, filterData]
      })
    }
    setPageAndFilterStatus("UPDATED");
  };

  function removeFilter (category_name: string,) {
    const newFilters = filtersData.filters.filter((filter: any) => (filter.category_name !== category_name));
    setFiltersData({
      filters: newFilters
    })
    setPageAndFilterStatus("UPDATED");
  };

  function clearFilters () {
    setFiltersData({
      filters: []
    })
    setPageAndFilterStatus("SET");
  }

  function paginateSet(pagination: PaginationType[]) {
    setPagination(pagination);
    setPageAndFilterStatus("SET");
  }
  function paginate (rows_per_page: number, page: number, type: number) {
    const item = { type, rows_per_page, page };
    setPagination(pagination => {
      const index = pagination?.findIndex(obj => obj && obj.type === type);

      if (index >= 0)
        pagination[index] = item;
      else
        pagination.push(item);
      return pagination;
    });
    setPageAndFilterStatus("UPDATED");
  }

  function sorting (category_name: string, sort:string, title: string) {
    const filterData = {
      category_name,
      filter: '',
      sort,
      title
    }
    const foundSearch = filtersData.filters.find((filter: any) => (filter.category_name === category_name));

    if (foundSearch && filtersData.filters) {
      const index = filtersData.filters.indexOf(foundSearch);
      filtersData.filters[index].sort = sort;
      const newFilterData = filtersData.filters;
      setFiltersData({
        filters: newFilterData
      })
    } else {
      setFiltersData({
        filters: [...filtersData.filters, filterData]
      })
    }
    setPageAndFilterStatus("UPDATED");
  };

  return (
    <SearchAndFilterContext.Provider value={{
      filtersData,
      pagination,
      paginateSet,
      sortTableBy,
      searchResults,
      removeFilter,
      clearFilters,
      paginate,
      sorting,
      pageAndFilterStatus
    }}>
      {children}
    </SearchAndFilterContext.Provider>
  );
};

export default SearchAndFilterProvider;

// Build provider
// Create hook (expose context)
// index.tsx - context folder. Wrap childers with providers
// main app wrap in index.tsx
