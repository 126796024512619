import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'material-ui-formik-components';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

const ResetPassword = () => {
  const history = useHistory();
  const [responseMessage, setResponseMessage] = useState('');

  const onSubmit = (values:any) => {
    if (values.password !== values.confirmPassword) {
      return setResponseMessage('The two passwords fields do not match');
    }
    if (values.password.length < 8) {
      return setResponseMessage('Password needs to be at least 8 characters');
    }
    return history.push('/reset-password-successful');
  };

  return (
    <section className="user forgot-password">
      <div className="card">
        <div className="header">
          <div className="logo">
            <img src="img/Logo-L.png"/>
          </div>
        </div>
        <div className="body">
          <h1 className="center">Enter New Password</h1>
          {
            responseMessage.length > 1 &&
            <Alert variant="outlined" severity="error">
              {responseMessage}
            </Alert>
          }
          <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched
            }) => (
              <Form className="one grg40">
                <div>
                  <Field
                    type="password"
                    name="password"
                    value={values.password}
                    component={TextField}
                    placeholder="New Password"
                    variant="standard"
                    required
                  />
                  {errors.password && touched.password && errors.password}
                  <Field
                    type="password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    component={TextField}
                    placeholder="Confirm New Password"
                    variant="standard"
                    required
                  />
                  {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >Update Password</Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
