import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Checkbox } from '@material-ui/core';
import '../style.css';

interface Props {
  className?: string,
  keyName: string,
  label?: string,
  value: any,
  onClick: any,
}

export const CheckBoxClicked = ({ className, keyName, label, value, onClick }:Props) => {
  const [state, setState] = useState({
    value: value
  });

  const handleChange = () => {
    setState({ value: !state.value });
  };

  useEffect(() => setState({ value: value }), [value]);

  return (
    <FormControl key={keyName} className={`${className || ''} checkbox`}>
      { label && <InputLabel shrink htmlFor={label}>{label}</InputLabel>}
      <Checkbox
        checked={state.value}
        color="primary"
        onChange={handleChange}
        onClick={() => onClick(state.value, keyName)}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </FormControl>
  );
};
