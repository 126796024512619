import { SideDrawer } from './sideDrawer/sideDrawer';
import { DashboardComponent as Dashboard } from './dashboard/dashboardComponent';
import { SearchAndFilter } from './popover/searchAndFilter.component';
import { Settings } from './general-settings/settings.component';
import { AssetsSettings } from './general-settings/assetsSettings.component';
import { CompanyDetails } from './general-settings/companyDetails.component';
import { PaymentGateway } from './paymentGateway/paymentGateway.component';

export default {
  SideDrawer,
  Dashboard,
  SearchAndFilter,
  Settings,
  AssetsSettings,
  CompanyDetails,
  PaymentGateway
};
