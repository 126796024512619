import React, { useState } from 'react';
import {
  FormControl, InputLabel, NativeSelect, FormHelperText
} from '@material-ui/core';
import { Tooltip } from '../tooltip/tooltip.component';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface Props {
  className?: string,
  keyName: string,
  label?: string,
  tooltip?: string,
  val: any,
  options: any,
  validation:any,
  onBlur: any,
}

type ErrorValidation = {
  status: boolean,
  message: string
}

const validationProps = (pattern: any) => {
  if (pattern) {
    return {
      inputProps: { pattern }
    };
  }
};

export const Dropdown = ({ className, keyName, label, tooltip, val, options, validation, onBlur }: Props) => {
  let defaultValID;
  val !== -1 ? defaultValID = options[val].id : defaultValID = -1;

  const [state, setState] = useState({
    val: defaultValID
  });
  const [error, setError] = useState<ErrorValidation>({
    status: false,
    message: ''
  });

  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    setState({
      ...state, val: event.target.value
    });
    validate();
  };

  const validate = async () => {
    /** Validity boolean options
     * badInput, customError, patternMismatch, rangeOverflow, rangeUnderflow
     * stepMismatch, tooLong, tooShort, typeMismatch, valid, valueMissing
    */
    setError(() => ({
      status: state.val === -1 && validation && validation.required,
      message: validation ? validation.error_message : ''
    }));
  };

  return (
    <FormControl key={keyName} className={className || 'dropdown'}>
      { label &&
          <InputLabel shrink htmlFor={label}>
            {label}{!!validation && validation.required ? (error.status ? <span style={{ color: 'red' }}> *</span> : ' *') : ''}
            {tooltip
              ? <Tooltip
                content={tooltip}
                direction="bottom">
                <InfoOutlinedIcon className="info-icon" fontSize="small" />
              </Tooltip>
              : ''
            }
          </InputLabel>
      }
      <NativeSelect
        classes={error.status ? { root: 'border-red' } : {}}
        value={state.val}
        onChange={handleChange}
        onFocus={validate}
        variant="outlined"
        { ...validationProps(!!validation && validation.pattern) }
        inputProps={{
          name: keyName,
          id: label
        }}
        onBlur={() => { validate(); onBlur(state.val, keyName); }}
        required={!!validation && validation.required}
      >
        <option value="-1" hidden>Select</option>
        {options.map((option: any) => (
          <option key={option.val} value={option.id}>{option.name}</option>
        ))}
      </NativeSelect>
      {error.status ? <FormHelperText style={{ color: 'red', textAlign: 'left' }}>{error.message}</FormHelperText> : null}
    </FormControl>
  );
};
