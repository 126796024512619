import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Table,
  TableBody
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTableContext } from 'src/context/tableProvider';
import { pageServices } from 'src/services/api/helpers/pageServices';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@material-ui/icons';
import EditCell from './EditCell';
import BasicComponent from 'src/components/basicComponents/index';
import EditRow from './EditRow';

const UPDATE_TABLE_ROW_SKELETON = {
  slug: '',
  data: {
    group_type_id: 0,
    budget_group_type_id: 0,
    collapsed_rows_included: [
      {
        slug: '',
        data: {
          name: '',
          value: ''
        }
      }
    ]
  }
};
interface CollapsedRows {
    slug: string;
    data: CollapsedRowsIncluded;
}
interface CollapsedRowsIncluded {
    group_type_id: any;
    budget_group_type_id: any;
    collapsed_rows_included: CollapsedRowsCell[];
}
interface CollapsedRowsCell {
    slug: string;
    data: any;
}
const Row = (row: any) => {
  const { editBudget, editBudgetSet } = useTableContext();
  const { pageSectionId, tableBody, setTableBody, onUpdate } = row;
  const extra = row.row.extra;
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [activeGroupType, setActiveGroupType] = useState(-1);
  const [open, setOpen] = useState(editBudget || false);
  const [tableState, setTableState] = useState(editBudget || false);
  const [tableRow, setTableRow] = useState(row.row);
  const [updateTableRow, setUpdateTableRow] = useState(
    UPDATE_TABLE_ROW_SKELETON
  );
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: ''
  });

  useEffect(() => {
    if (editBudget) {
      EditContent(tableRow);
    }
  }, [editBudget]);

  const ExpandTable = () => {
    setOpen((state: boolean) => {
      // @MM Clear any updates on close
      if (state) {
        setTableRow && setTableRow(row.row);
        setUpdateTableRow && setUpdateTableRow(UPDATE_TABLE_ROW_SKELETON);
      }
      return !state;
    });
    setTableState(false);
  };

  const EditContent = (row: any) => {
    const activeGroupTypeId = activeGroupType;
    const defaultCollapsedRowData = row.collapsed_rows_included.map(
      (collapsedRow: any) => {
        const collapsedRowData = {
          slug: collapsedRow.collapsed_rows_slug,
          data: {
            name: collapsedRow.cells[2].input.name,
            value: collapsedRow.cells[2].input.value
          }
        };
        return collapsedRowData;
      }
    );
    const tableRow: CollapsedRows = {
      slug: row.collapsed_rows_slug,
      data: {
        group_type_id: activeGroupTypeId,
        budget_group_type_id: activeGroupTypeId,
        collapsed_rows_included: defaultCollapsedRowData
      }
    };
    setUpdateTableRow(tableRow);
    if (!editBudget) {
      setTableState(true);
    }
    if (!open && !editBudget) {
      setOpen(!open);
    }
  };

  const DeleteContent = async () => {
    const { collapsed_rows_slug } = row.row;
    const body = {
      slug: `delete_${collapsed_rows_slug}`
    };
    const response = await pageServices.crudBudget(body);
    if (response?.success) {
      const newTableBody = tableBody.filter(
        (tableRow: any) =>
          tableRow.collapsed_rows_slug !== collapsed_rows_slug
      );
      setTableBody(newTableBody);
      editBudgetSet(false);
      onUpdate('delete-row', pageSectionId, newTableBody);
    } else {
      alert(response?.message.data.data.message);
    }
  };

  const SaveContent = async () => {
    let validated: boolean = true;
    const inputs = document.querySelectorAll('input');
    const selects = document.querySelectorAll('select');

    inputs.forEach((input: any) => {
      if (input.validity.patternMismatch) {
        validated = false;
        input.focus();
        input.blur();
        return false;
      }
      if (!input.value && input.required) {
        validated = false;
        input.focus();
        input.blur();
        return false;
      }
    });

    selects.forEach((select: any) => {
      if (select.required && select.options.selectedIndex === 0) {
        validated = false;
        select.focus();
        return false;
      }
    });

    if (validated) {
      const response = await pageServices.crudBudget(updateTableRow);
      if (response?.success) {
        const newTableRow = response.message.data.table_row;
        const newTableBody = tableBody.map((tableRow: any) => {
          if (
            tableRow.collapsed_rows_slug ===
                        newTableRow.collapsed_rows_slug
          ) {
            return newTableRow;
          }
          return tableRow;
        });
        setTableBody(newTableBody);
        editBudgetSet(false);
        onUpdate('reload_data', pageSectionId, newTableBody);
      } else {
        alert(response?.message.data.message);
      }
    } else {
      setOpenAlert(true);
      setAlertMessage({
        message: 'Make sure all required fields are filled in.',
        severity: 'error'
      });
    }
  };

  const updateRow = (
    inputValue: any,
    inputName: string,
    collapsedRowSlug: string
  ) => {
    const name = inputName;
    const value = inputValue;

    const body = {
      slug: collapsedRowSlug,
      data: { name, value }
    };
    const newCollapsedRows = tableRow.collapsed_rows_included.map(
      (collapsedRow: any) => {
        if (collapsedRow.collapsed_rows_slug === body.slug) {
          const oldCells = collapsedRow.cells;
          const newCells = {
            ...oldCells[2],
            text: body.data.value,
            input: { ...oldCells[2].input, value: body.data.value }
          };
          const newRow = {
            ...collapsedRow,
            cells: [
              ...collapsedRow.cells.slice(0, 2),
              newCells,
              ...collapsedRow.cells.slice(3, 6)
            ]
          };
          return newRow;
        }
        return collapsedRow;
      }
    );
    const updatedRow = updateTableRow.data.collapsed_rows_included.map(
      (collapsedRow: any) => {
        if (collapsedRow.slug === body.slug) {
          return body;
        }
        return collapsedRow;
      }
    );

    setTableRow({ ...tableRow, collapsed_rows_included: newCollapsedRows });
    setUpdateTableRow({
      ...updateTableRow,
      data: {
        ...updateTableRow.data,
        collapsed_rows_included: updatedRow
      }
    });
  };

  const onChange = (number: number) => {
    setActiveGroupType(number);
  };

  return (
    <React.Fragment key={tableRow.collapsed_rows_slug}>
      <TableRow className={!open ? '' : 'opened'}>
        {activeGroupType &&
                    tableRow.cells.map((rowValue: any, rowIndex: number) => {
                      return !tableState ? (
                        <TableCell
                          key={`row-${rowIndex}`}
                          align={rowValue.align}
                        >
                          {rowValue.text}
                        </TableCell>
                      ) : (
                        <EditCell
                          key={`edit-row-${rowIndex}`}
                          data={rowValue}
                          onChange={onChange}
                          value={activeGroupType}
                          tableRow={tableRow}
                          onSetTableRow={setTableRow}
                          updateTableRow={updateTableRow}
                          onSetUpdateTableRow={setUpdateTableRow}
                        />
                      );
                    })}
        <TableCell key={row.id} className='table-btns'>
          <div className='buttons'>
            <IconButton
              aria-label='expand row'
              className='dropdown-btn'
              size='small'
              onClick={ExpandTable}
            >
              {open ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
            {!tableState ? (
              <IconButton
                aria-label='edit'
                className='edit-btn'
                disabled={
                  !!(
                    !!extra &&
                                        extra.button_permissions === 0
                  )
                }
                onClick={() => EditContent(tableRow)}
              >
                <EditIcon
                  fontSize='small'
                  color={
                    !!extra &&
                                        extra.button_permissions === 0
                      ? 'disabled'
                      : 'inherit'
                  }
                />
              </IconButton>
            ) : (
              <IconButton
                aria-label='edit'
                className='edit-btn'
                disabled={
                  !!(
                    !!extra &&
                                        extra.button_permissions === 0
                  )
                }
                onClick={SaveContent}
              >
                <SaveIcon
                  fontSize='small'
                  color={
                    !!extra &&
                                        extra.button_permissions === 0
                      ? 'disabled'
                      : 'inherit'
                  }
                />
              </IconButton>
            )}
            <IconButton
              aria-label='delete'
              className='delete-btn'
              disabled={
                !!(!!extra && extra.button_permissions === 0)
              }
              onClick={() => setConfirmOpen(true)}
            >
              <DeleteIcon
                fontSize='small'
                color={
                  !!extra && extra.button_permissions === 0
                    ? 'disabled'
                    : 'inherit'
                }
              />
            </IconButton>
            <BasicComponent.ConfirmDialog
              confirm={true}
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={DeleteContent}
            >
              <p>Are you sure you want to delete this item?</p>
            </BasicComponent.ConfirmDialog>
          </div>
        </TableCell>
      </TableRow>
      <TableRow className={!open ? '' : 'opened-container'}>
        <TableCell
          className='collapsed'
          colSpan={tableRow.cells.length + 1}
        >
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Table>
              <TableBody>
                {tableRow.collapsed_rows_included.map(
                  (rowValue: any, rowIndex: number) => {
                    return !tableState ? (
                      <TableRow
                        key={`row-${rowValue.collapsed_rows_slug}`}
                      >
                        {rowValue?.cells.map(
                                                    (
                                                      cellValue: any,
                                                      cellIndex: number
                                                    ) => (
                                                      <React.Fragment
                                                        key={`collapsed-${cellIndex}`}
                                                      >
                                                        <TableCell
                                                          align={
                                                            cellValue.align
                                                          }
                                                        >
                                                          {cellValue.text}
                                                        </TableCell>
                                                        {cellIndex + 1 ===
                                                            rowValue.cells
                                                              .length ? (
                                                            <TableCell></TableCell>
                                                          ) : null}
                                                      </React.Fragment>
                                                    )
                                                )}
                      </TableRow>
                    ) : (
                      <TableRow
                        key={`row-${rowValue.collapsed_rows_slug}`}
                      >
                        <EditRow
                          key={`edit-row-${rowValue.collapsed_rows_slug}`}
                          data={rowValue}
                          onUpdate={updateRow}
                        />
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
      <BasicComponent.CustomizedSnackbars
        message={alertMessage.message}
        severity={alertMessage.severity}
        onOpen={openAlert}
        onClose={(open: any) => setOpenAlert(open)}
      />
    </React.Fragment>
  );
};

export default Row;
