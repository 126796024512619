import React, { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Checkbox } from '@material-ui/core';
import { useNavFilterContext } from '../../../context/navFilterProvider';
import './list.sass';

/** CheckboxList requires a Array of objects
 * The Array should be called 'list_items
 */
interface CheckboxList {
  list_items: Array<any>,
  name: string
}

export const CheckboxList = (props: CheckboxList) => {
  const { list_items, name } = props;
  const [listItems, setListItems] = useState(list_items);
  const { navFilter, changeFiltersFunc } = useNavFilterContext();
  const [checked, setChecked] = useState(list_items.filter((item: any) => (item.checked !== 0)));

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex !== -1) {
      listItems[currentIndex].checked = 0;
    }

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setListItems(listItems);
    setChecked(newChecked);
    if (name.includes('divisions')) {
      const checkedId = navFilter.filters.job_ids.find((ids: any) => (ids === value.id));
      if (checkedId) {
        const NewNavFilter = {
          filters: {
            ...navFilter.filters,
            job_ids: navFilter.filters.job_ids.filter((ids: any) => (ids !== value.id))
          }
        };
        changeFiltersFunc(NewNavFilter);
      } else {
        const NewNavFilter = {
          filters: {
            ...navFilter.filters,
            job_ids: [...navFilter.filters.job_ids, value.id]
          }
        };
        changeFiltersFunc(NewNavFilter);
      }
    } else {
      const checkedId = navFilter.filters.status_ids.find((ids: any) => (ids === value.id));
      if (checkedId) {
        const NewNavFilter = {
          filters: {
            ...navFilter.filters,
            status_ids: navFilter.filters.status_ids.filter((ids: any) => (ids !== value.id))
          }
        };
        changeFiltersFunc(NewNavFilter);
      } else {
        const NewNavFilter = {
          filters: {
            ...navFilter.filters,
            status_ids: [...navFilter.filters.status_ids, value.id]
          }
        };
        changeFiltersFunc(NewNavFilter);
      }
    }
  };

  return (
    <List className="checkbox-list">
      {listItems.map((value) => {
        const labelId = `checkbox-list-label-${value.id}`;

        return (
          <ListItem key={value.id} role={undefined} dense={true} disableRipple button onClick={handleToggle(value)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value.name} />
          </ListItem>
        );
      })}
    </List>
  );
};
