import React from 'react';
import Modal from '@material-ui/core/Modal';

interface Props {
  body: any,
  onOpen: any,
  onClose: any
}

export const DynamicModal = ({ body, onOpen, onClose }:Props) => {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Modal open={onOpen} onClose={handleClose}>
      {body}
    </Modal>
  );
};
