import {
  USER_ACTIONS, SETTINGS_ACTIONS,
  COMPANY_ACTIONS, PROJECT_ACTIONS,
  NAVIGATION_ACTIONS
} from './actionTypes';

const globalReducer = (state: any, action: any) => {
  switch (action.type) {
  case (NAVIGATION_ACTIONS.UPDATE):
    return {
      ...state,
      currentPage: action.payload
    };
  case (USER_ACTIONS.UPDATE):
    return {
      ...state,
      user: action.payload
    };
  case (SETTINGS_ACTIONS.UPDATE):
    return {
      ...state,
      settings: action.payload
    };
  case (COMPANY_ACTIONS.UPDATE):
    return {
      ...state,
      company: action.payload
    };
  case (PROJECT_ACTIONS.UPDATE):
    return {
      ...state,
      project: action.payload
    };
  default: return state;
  }
};

export default globalReducer;
