import React, { useState, useEffect } from 'react';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import { useTableContext } from '../../../context/tableProvider';
import EnhancedComponent from '../../../components/enhancedComponents';
import BasicComponent from '../../../components/basicComponents/index';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  TextField,
  FormControl,
  InputLabel,
  TextareaAutosize,
  TablePagination,
  Popover
} from '@material-ui/core';
import Image from 'material-ui-image';
import moment from 'moment';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { pageServices } from '../../../services/api/helpers/pageServices';
import './style.css';

interface Table {
  page_section_id: any,
  tableName: string,
  filters: Array<any>,
  total_results: number,
  data: any,
  changeView: any,
  section_type: number,
  onFilter?: any,
  onUpdate?: any
}

interface UpdateTableRow {
  slug: string,
  data: {
    item_details: Array<any>,
    item_quantities: Array<any>,
    item_financials: Array<any>,
    attachments: Array<any>
  }
}

interface SearchType {
  id: any,
  slug: string
}

const UPDATE_TABLE_ROW_SKELETON = {
  slug: '',
  data: {
    item_details: [],
    item_quantities: [],
    item_financials: [],
    attachments: []
  }
};

const Row = (row:any) => {
  const { editItems, editItemsSet } = useTableContext();
  const { index, pageSectionId, cells, collapsed_rows_included, collapsed_rows_slug, extra, readonly, onUpdate } = row;
  const [tableState, setTableState] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [searchType, setSearchType] = useState<SearchType>({
    id: null,
    slug: 'search_catalogue'
  });
  const [updateTableRow, setUpdateTableRow] = useState<UpdateTableRow>(UPDATE_TABLE_ROW_SKELETON);
  const [popHover, setPopHover] = useState({
    open: false,
    achorEl: null
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: ''
  });

  useEffect(() => {
    if (editItems && index < 1) {
      setTableState(true);
      setOpen(true);
    }
  }, [editItems]);

  const ExpandTable = () => {
    setOpen((state: boolean) => {
      // @MM Clear any updates on close
      if (state) {
        setUpdateTableRow && setUpdateTableRow(UPDATE_TABLE_ROW_SKELETON);
      }
      return !state;
    });
    setTableState(false);
  };

  const EditContent = () => {
    if (!editItems) {
      setTableState(true);
    }
    if (!open && !editItems) {
      setOpen(!open);
    }
  };

  const DeleteContent = async (rowId:any) => {
    const body = {
      slug: `delete_project_item-${rowId}`
    };

    const response = await pageServices.crudItem(body);
    if (response?.success) {
      onUpdate('reload_data', pageSectionId, response);
      editItemsSet(false);
    } else {
      alert(response?.message.data.data.message);
    }
  };

  const CopyContent = async (rowId:any) => {
    const body = {
      slug: 'copy_project_item',
      data: {
        copy_project_item_id: rowId
      }
    };

    const response = await pageServices.crudItem(body);
    if (response?.success) {
      onUpdate('reload_data', pageSectionId, response);
    } else {
      alert(response?.message.data.data.message);
    }
  };

  const SaveContent = async () => {
    let validated: boolean = true;
    const inputs = document.querySelectorAll('input');
    const selects = document.querySelectorAll('select');

    inputs.forEach((input: any) => {
      if (!input.value && input.required) {
        validated = false;
        input.focus();
        input.blur();
        return false;
      }
    });

    selects.forEach((select: any) => {
      if (select.required && select.options.selectedIndex === 0) {
        validated = false;
        select.focus();
        return false;
      }
    });

    if (validated) {
      if (updateTableRow.slug) {
        const response = await pageServices.crudItem(updateTableRow);
        if (response?.success) {
          onUpdate('reload_data', pageSectionId, response.message.data.project_item);
          editItemsSet(false);
        } else {
          setOpenAlert(true);
          setAlertMessage({
            message: response?.message.data.message || 'Unexpected error.',
            severity: 'error'
          });
        }
      } else {
        setOpen(false);
        setTableState(false);
      }
    } else {
      setOpenAlert(true);
      setAlertMessage({
        message: 'Make sure all required fields are filled in.',
        severity: 'error'
      });
    }
  };

  const updateRow = (updateState: any, data: any, slug:any) => {
    switch (updateState) {
    case 'group_type_id':
      setSearchType({ ...searchType, id: data.id });
      collapsed_rows_included.map((collapsedRows:any) => {
        if (collapsedRows) {
          collapsedRows.inputs.map((cell:any) => {
            if (cell.name === 'group_type_id') {
              cell.value = data.name;
              updateCell(data.id, cell.name, slug);
            }
          });
        }
        return collapsedRows;
      });
      break;

    case 'item_description_id':
      collapsed_rows_included.map((collapsedRows:any) => {
        if (collapsedRows) {
          collapsedRows.inputs.map((cell:any) => {
            if (cell.name === 'item_description_id') {
              cell.value = data.item_description;
              updateCell(data.id, cell.name, slug);
            }
            if (cell.name === 'cost_type_id') {
              cell.value = data.cost_type;
            }
            if (cell.name === 'cost_code_id') {
              cell.value = data.cost_code;
            }
          });
        }
        return collapsedRows;
      });
      break;

    case 'group_category_id':
      setSearchType({ ...searchType, id: data.id });
      updateCell(data.id, updateState, slug);
      break;

    default:
      updateCell(data.id, updateState, slug);
      break;
    }
  };

  const upload = async (value:string, cellName:string) => {
    const body = {
      slug: 'update_project_item_icon',
      data: {
        id: extra.row_id,
        storage_url: value
      }
    };

    const response = await pageServices.crudItem(body);
    if (response?.success) {
      // only returning a message. Need to manually update state
    }
  };

  const uploadAttachments = async (value:string, rowId:number) => {
    const fileName = value.split('/').pop();
    const body = {
      slug: `update_project_item-${rowId}`,
      data: {
        item_details: [],
        item_quantities: [],
        item_financials: [],
        attachments: [
          { name: fileName, path: value }
        ]
      }
    };

    const response = await pageServices.crudItem(body);
    if (response?.success) {
      onUpdate('update', pageSectionId, response.message.data.project_item);
    }
  };

  const deleteAttachment = async (id:any) => {
    const body = {
      slug: `delete_project_item-${extra.row_id}`,
      data: {
        key: 'attachment',
        id
      }
    };

    const response = await pageServices.crudItem(body);
    if (response?.success) {
      const attachments = extra.attachments.filter((attachment:any) => (attachment.id !== id)).map((attachment:any) => {
        return attachment;
      });
      onUpdate('delete-attachments', pageSectionId, { collapsed_rows_slug, attachments });
    }
  };

  const updateCell = (value:string, cellName:string, slug:string) => {
    let itemDetailsObject;
    let itemQuantitiesObject;
    let itemFinancialsObject;
    const itemDetailsState = updateTableRow.data.item_details;
    const itemQuantitiesState = updateTableRow.data.item_quantities;
    const itemFinancialsState = updateTableRow.data.item_financials;

    if (cellName.includes('plant_number_id')) {
      setSearchType({ ...searchType, id: parseInt(value) });
    }

    collapsed_rows_included.forEach((collapsedRows:any) => {
      if (collapsedRows) {
        switch (slug) {
        case 'items_details':
          collapsedRows.inputs.forEach((cell:any) => {
            if (cell.name === cellName) {
              itemDetailsObject = { key: cellName, value: value };
            }
            if (!!cell.options && cell.options.name === cellName) {
              itemDetailsObject = { key: cellName, value: value };
            }
          });
          break;

        case 'items_quantities':
          collapsedRows.inputs.forEach((cell:any) => {
            if (cell.name === cellName) {
              itemQuantitiesObject = { key: cellName, value: value };
            }
            if (!!cell.options && cell.options.name === cellName) {
              itemQuantitiesObject = { key: cellName, value: value };
            }
          });
          break;

        case 'items_financials':
          collapsedRows.inputs.forEach((cell:any) => {
            if (cell.name === cellName) {
              itemFinancialsObject = { key: cellName, value: value };
            }
            if (!!cell.options && cell.options.name === cellName) {
              itemFinancialsObject = { key: cellName, value: value };
            }
          });
          break;
        default:
          break;
        }
      }
    });

    if (itemDetailsObject !== undefined) {
      const foundItemDetails = itemDetailsState.find((keyValue) => (keyValue.key === cellName));
      if (foundItemDetails) {
        // Replace the found item
        const rightIndex = itemDetailsState.indexOf(foundItemDetails);
        itemDetailsState[rightIndex].value = value;
      } else {
        // Create new item
        setUpdateTableRow({
          ...updateTableRow,
          slug: `update_project_item-${extra.row_id}`,
          data: {
            ...updateTableRow.data, item_details: [...updateTableRow.data.item_details, itemDetailsObject]
          }
        });
      }
    }

    if (itemQuantitiesObject !== undefined) {
      const foundItemQuantities = itemQuantitiesState.find((keyValue) => (keyValue.key === cellName));
      if (foundItemQuantities) {
        // Replace the found item
        const rightIndex = itemQuantitiesState.indexOf(foundItemQuantities);
        itemQuantitiesState[rightIndex].value = value;
      } else {
        // Create new item
        setUpdateTableRow({
          ...updateTableRow,
          slug: `update_project_item-${extra.row_id}`,
          data: {
            ...updateTableRow.data, item_quantities: [...updateTableRow.data.item_quantities, itemQuantitiesObject]
          }
        });
      }
    }

    if (itemFinancialsObject !== undefined) {
      const foundItemFinancials = itemFinancialsState.find((keyValue) => (keyValue.key === cellName));
      if (foundItemFinancials) {
        // Replace the found item
        const rightIndex = itemFinancialsState.indexOf(foundItemFinancials);
        itemFinancialsState[rightIndex].value = value;
      } else {
        // Create new item
        setUpdateTableRow({
          ...updateTableRow,
          slug: `update_project_item-${extra.row_id}`,
          data: {
            ...updateTableRow.data, item_financials: [...updateTableRow.data.item_financials, itemFinancialsObject]
          }
        });
      }
    }
  };

  const inputType = (input:any, i:number, slug?:any) => {
    const inputType = input.type;
    switch (inputType) {
    case 'empty':
      return (
        <div/>
      );
    case 'text_field':
      return (
        <BasicComponent.CustomTextField
          {...input}
          updateCell={(value: string, cellName: string) => updateCell(value, cellName, slug)}/>
      );
    case 'text_field_dropdown':
      return (
        <div className="custom-text-field">
          <BasicComponent.CustomTextField
            {...input}
            defaultVal={input.value}
            updateCell={(value: string, cellName: string) => updateCell(value, cellName, slug)}/>
          <BasicComponent.Dropdown
            keyName={input.options.name}
            val={input.options.dropdown.default}
            options={input.options.dropdown.options}
            validation={input.validation}
            onBlur={(value: string, cellName: string) => updateCell(value, cellName, slug)}/>
        </div>
      );
    case 'text_dropdown_button':
      return (
        <BasicComponent.OtherCharges
          {...input}
          rowId={collapsed_rows_slug}
          onBlur={(value: string, cellName: string) => updateCell(value, cellName, slug)}/>
      );
    case 'dropdown':
      return (
        <BasicComponent.Dropdown
          keyName={input.name}
          label={input.label}
          val={input.dropdown.default}
          options={input.dropdown.options}
          validation={input.validation}
          tooltip={input.tooltip}
          onBlur={(value: string, cellName: string) => updateCell(value, cellName, slug)}/>
      );
    case 'autocomplete':
      return (
        <BasicComponent.AutoComplete
          data={input}
          searchQuery={searchType}
          onUpdate={(updateState: any, data: any) => updateRow(updateState, data, slug)}/>
      );
    case 'date':
      return (
        <BasicComponent.DatePicker
          keyName={input.name}
          label={input.label}
          val={input.value}
          futureDates={input.future_dates}
          pastDates={input.past_dates}
          onUpdate={(value: string, cellName: string) => updateCell(value, cellName, slug)}/>
      );
    case 'text_area':
      return (
        <FormControl key={`${input.name}-${i}`} className="col3-span">
          <InputLabel shrink htmlFor="label">{input.label}</InputLabel>
          <TextareaAutosize
            aria-label={input.label}
            rowsMin={3}
            placeholder="Comment"
            onBlur={(event) => updateCell(event.target.value, input.name, slug)}/>
        </FormControl>
      );
    case 'image':
      return (
        <BasicComponent.UploadImage
          keyName={input.type}
          upload={upload}
          size={30}
          folder="icons"/>
      );

    default:
      break;
    }
  };

  const handleInfoOpen = (event: any) => {
    setPopHover({
      open: !popHover.open,
      achorEl: event.currentTarget
    });
  };

  return (
    <>
      <TableRow className={`${!open ? '' : 'opened'} ${extra.status ? extra.status : ''}`}>
        {cells.map((value:any, i:number) => (
          value.type === 'image'
            ? !tableState
              ? <TableCell key={i} align={value.align} className="icon">
                { !value.text || value.text === ''
                  ? <CameraAltIcon fontSize="small"/>
                  : <Image
                    src={value.text}
                    aspectRatio={(4 / 3)}
                  />
                }
              </TableCell>
              : <TableCell key={i} align={value.align}>{inputType(value, i)}</TableCell>
            : <TableCell key={i} align={value.align} onClick={handleInfoOpen}>{value.text}</TableCell>
        ))}
        { collapsed_rows_included.length !== 0
          ? <TableCell key={row.id} className="table-btns">
            <div className="buttons">
              <IconButton aria-label="expand row" className="dropdown-btn" size="small" onClick={ExpandTable}>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
              { !tableState
                ? <IconButton
                  aria-label="edit"
                  className="edit-btn"
                  disabled={!!(!!extra && !extra.button_permissions) || !!readonly}
                  onClick={EditContent}
                ><EditIcon fontSize="small" color={!!(!!extra && !extra.button_permissions) || !!readonly ? 'disabled' : 'inherit'}/></IconButton>
                : <IconButton
                  aria-label="edit"
                  className="edit-btn"
                  disabled={!!(!!extra && !extra.button_permissions) || !!readonly}
                  onClick={SaveContent}
                ><SaveIcon fontSize="small" color={!!(!!extra && !extra.button_permissions) || !!readonly ? 'disabled' : 'inherit'}/></IconButton>
              }
              <IconButton
                aria-label="copy"
                className="copy-btn"
                disabled={extra && !extra.button_permissions}
                onClick={() => CopyContent(extra.row_id)}
              ><FileCopyIcon fontSize="small" color={extra && !extra.button_permissions ? 'disabled' : 'inherit'}/></IconButton>
              <IconButton
                aria-label="delete"
                className="delete-btn"
                disabled={!!(!!extra && !extra.button_permissions) || !!readonly}
                onClick={() => setConfirmOpen(true)}
              ><DeleteIcon fontSize="small" color={!!(!!extra && !extra.button_permissions) || !!readonly ? 'disabled' : 'inherit'}/></IconButton>
              <BasicComponent.ConfirmDialog confirm={true} open={confirmOpen} setOpen={setConfirmOpen} onConfirm={() => DeleteContent(extra.row_id)} >
                <p>Are you sure you want to delete this item?</p>
              </BasicComponent.ConfirmDialog>
            </div>
          </TableCell>
          : <TableCell key={row.id}></TableCell>
        }
        { extra.status
          ? <Popover id="employee-status" className="status" open={popHover.open} anchorEl={popHover.achorEl}>
            <div className="card grg10">
              <div className="title two gcg10">
                <h3 className="center">{extra.status}</h3>
                <div className="close jend" onClick={handleInfoOpen}>x</div>
              </div>
              <p className="message">{extra.status_comment}</p>
            </div>
          </Popover>
          : null
        }
      </TableRow>
      <TableRow className={!open ? '' : 'opened-container'}>
        <TableCell className="collapsed" colSpan={cells.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="items-container three gcg20">
              { collapsed_rows_included.map((collapsedRow:any, i:number) => (
                collapsedRow &&
                  <div key={`item-${i}`} className="item one grg5 astart">
                    <div className="text">
                      <h3 className="center bold uppercase">{collapsedRow.title}</h3>
                    </div>
                    <div className="content three gcg5 grg20">
                      { !tableState
                        ? collapsedRow.inputs.map((input:any, i:number) => (
                          <TextField
                            disabled
                            key={`input-${i}`}
                            id={`input-${i}`}
                            value={input.type === 'text_field_dropdown' || input.type === 'text_dropdown_button' ? `${input.currency ? input.currency.text : input.value} / ${input.options.text}` : input.currency ? input.currency.text : input.value}
                            label={
                              <React.Fragment>
                                {(input.type === 'text_field_dropdown' || input.type === 'text_dropdown_button' ? `${input.label} / ${input.options.label}` : input.label)}
                                {
                                  input.tooltip
                                    ? <BasicComponent.Tooltip
                                      content={input.tooltip}
                                      direction="bottom">
                                      <InfoOutlinedIcon className="info-icon" fontSize="small" />
                                    </BasicComponent.Tooltip>
                                    : ''
                                }
                              </React.Fragment>
                            }
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        ))
                        : collapsedRow.inputs.map((input:any) => (inputType(input, i, collapsedRow.slug)))
                      }
                    </div>
                  </div>
              ))}
            </div>
            <div className="creator three gcg20">
              { !!extra && extra.attachments && extra.attachments.length > 0
                ? <div className="attachments col3-span jstart">
                  <h3 className="bold uppercase">Attachments:</h3>
                  <BasicComponent.CustomLink {...extra} onDelete={deleteAttachment}/>
                </div>
                : null
              }
              <BasicComponent.UploadFile key={`upload-${extra.row_id}`} id={extra.row_id} disabled={!!(!!extra && extra.button_permissions === 0)} upload={(url:string) => uploadAttachments(url, extra.row_id)}/>
              { !!extra && extra.creater
                ? <React.Fragment>
                  <p className="jstart"><strong>Item created by {extra.creater} on {moment(extra.creation_date).format('DD/MM/YYYY')}</strong></p>
                  <p className="jend"><strong>Item changed by {extra.updated_by} on {moment(extra.last_change_date).format('DD/MM/YYYY')}</strong></p>
                </React.Fragment>
                : <p className="jstart"><strong>Item to be created</strong></p>
              }
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
      <BasicComponent.CustomizedSnackbars message={alertMessage.message} severity={alertMessage.severity} onOpen={openAlert} onClose={(open:any) => setOpenAlert(open)}/>
    </>
  );
};

export const DynamicCollapsibleItemsTable: React.FC<Table> = (props: any) => {
  const { paginate } = useSearchAndFilterContext();
  const { tableName, page_section_id, onUpdate, section_type, pagination } = props;
  const { header_row, body } = props.table;
  const { total_results, filters } = props.filter;

  const [open, setOpen] = useState(false);
  const [currentTarget, setCurrentTarget] = useState();
  const [valueData, setValueData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setPage(pagination?.page ?? 0);
    setRowsPerPage(pagination?.rows_per_page ?? 0);
  }, [pagination]);
  /** Search and filter functionality
   * Start
  */
  const openState = () => {
    setOpen(false);
  };

  const searchAndFilter = ({ currentTarget }: any, value: any) => {
    const { category_name } = value;
    if (category_name) {
      setOpen(true);
      setCurrentTarget(currentTarget);
      setValueData(value);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    paginate(rowsPerPage, newPage, section_type);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    paginate(parseInt(event.target.value), 0, section_type);
  };

  /** Search and filter functionality
   * end
  */

  return (
    <TableContainer className={tableName}>
      { !!filters && filters.length > 0
        ? <BasicComponent.FilterResults filters={filters}/>
        : null
      }
      <Table stickyHeader>
        <TableHead className="thead">
          <TableRow>
            {header_row.cells.map((value:any, i:number) => (
              <TableCell key={`theadCell-${i}`} align={value.align} onClick={(event) => searchAndFilter(event, value)}>{value.text}</TableCell>
            ))}
            <EnhancedComponent.SearchAndFilter open={open} currentTarget={currentTarget} data={valueData} openState={openState}/>
          </TableRow>
        </TableHead>
        <TableBody className="tbody">
          {body.map((row:any, i:number) => (
            <Row key={`tbody-${i}`} {...row} index={i} pageSectionId={page_section_id} onUpdate={onUpdate}/>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={total_results}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};
