import React, { useState } from 'react';
import { Button, IconButton, Popover } from '@material-ui/core';
import { userServices } from '../../../services/api/helpers/userServices';
import { useNotificationsContext } from '../../../context/notificationsProvider';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsIcon from '@material-ui/icons/Notifications';
import './notifications.sass';

export const Notifications = (props: any) => {
  const { notifications, paginationData, paginationSet } = useNotificationsContext();
  /*eslint-disable */
  let { total_results, unread_state, notification_group } = props;
  /* eslint-enable */
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    async function notify () {
      const response = await userServices.getNotificationsStatus();
      if (response?.success) {
        notifications(total_results, 0, notification_group);
      }
    }
    notify();
  };

  const handleClose = () => {
    setAnchorEl(null);
    const pagination = {
      rows_per_page: 10,
      page: 1
    };
    paginationSet(pagination);
  };

  const moreNotifications = () => {
    const { rows_per_page } = paginationData.pagination;
    const pagination = {
      rows_per_page: rows_per_page + 10,
      page: 1
    };
    paginationSet(pagination);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton className="notify-btn" onClick={handleClick}>
        <NotificationsIcon color={unread_state === 0 ? 'primary' : 'error'} fontSize="small"/>
      </IconButton >
      <Popover
        id="notifications"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div className="notifications card grg20">
          <div className="title two gcg10">
            <h1 className="uppercase">Notifications</h1>
            <IconButton onClick={handleClose}><CloseIcon fontSize="small"/></IconButton>
            <div className="separator col2-span"></div>
          </div>
          { !!notification_group && notification_group.length > 0 && notification_group.map((notification:any) => (
            <div key={notification.heading} className="notification one grg10">
              <h1 className="uppercase date">{notification.heading}</h1>
              { notification.notifications.map(({ time, notification, unread }:any) => (
                <div key={time} className={`item one ${unread === 1 ? 'unread' : ''}`}>
                  <p className="time">{time}</p>
                  <p className="message">{notification}</p>
                </div>
              ))}
            </div>
          ))}
          { !!total_results && total_results > 10
            ? <Button className="more-btn" color="primary" onClick={moreNotifications}>More</Button>
            : null
          }
        </div>
      </Popover>
    </>
  );
};
