import React, { useEffect, useState } from 'react';
import {
  FormControl, InputLabel, NativeSelect
} from '@material-ui/core';

interface Props {
  className?: string,
  keyName: string,
  label?: string,
  val: any,
  options: any,
  onClick: any,
}

export const DropdownClick = ({ className, keyName, label, val, options, onClick }: Props) => {
  let defaultValID: number;
  val !== -1 ? defaultValID = options[val].id : defaultValID = -1;
  const [state, setState] = useState({
    val: defaultValID
  });

  useEffect(() => { setState({ ...state, val: val || defaultValID }); }, [val]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setState({
      ...state, val: Number(event.target.value)
    });
    onClick(event.target.value, keyName);
  };

  return (
    <FormControl key={keyName} className={className || 'dropdown'}>
      { label && <InputLabel shrink htmlFor={label}>{label}</InputLabel>}
      <NativeSelect
        value={state.val}
        onChange={handleChange}
        inputProps={{
          name: keyName,
          id: label
        }}
        // onClick={() => onClick(state.val, keyName)}
      >
        <option value="-1" hidden>Select</option>
        {options.map((option: any) => (
          <option key={option.name} value={option.id}>{option.name}</option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};
