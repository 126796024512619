/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { globalLocalStorage } from '../../../services/storage/localstorage';
import EnhancedComponent from '../../../components/enhancedComponents';
import BasicComponent from '../../../components/basicComponents/index';
import { GlobalContext } from '../../../context/globalState';
import { BarChartComponentProps } from '../../../components/basicComponents/barChart/barChart.component';
import { ChartPair } from '../../../components/enhancedComponents/dashboard/dashboardComponent';
import { dashboardServices } from '../../../services/api/helpers/dashboardServices';

// import { PieChartComponentProps } from '@components/basicComponents/barChart/barChart.component';

const CompanyDashboard = () => {
  const [loading, setLoading] = useState(true);
  const { currentPage } = useContext(GlobalContext);
  const [barData1, setMainBarChart] = useState<any[]>([]);
  const [barData2, setTotalBarChart] = useState<any[]>([]);
  const [paymentPieChart, setPaymentPieChart] = useState<any[]>([]);
  const [paymentVarianceBarChart, setPaymentVarianceBarChart] = useState<any[]>([]);
  const [budgetPieChart, setBudgetPieChart] = useState<any[]>([]);
  const [budgetVarianceBarChart, setBudgetVarianceBarChart] = useState<any[]>([]);
  const [summaryTable, setSummaryTable] = useState();

  useEffect(() => {
    (async () => {
      if (!currentPage) {
        const page = globalLocalStorage.getItem('projectDashboard');
        await initializeData(page);
      } else {
        globalLocalStorage.storeItem('projectDashboard', currentPage);
        await initializeData(currentPage);
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, [currentPage]);

  const initializeData = (currentPage: any) => {
    const body = {
      slug: 'get_project_dashboard',
      data: {
        project_id: parseInt(currentPage.split('-')[1])
      }
    };

    pageData(body);
  };

  const pageData = async (body: any) => {
    const resp = await dashboardServices.getDashboard(body);
    if (resp?.success) {
      const { chart } = resp.message.data;

      const {
        sections_bar_data, totals_bar_data,
        actual_payments_pie_data, actual_payments_variance_bar_data,
        budget_pie_data, budget_variance_bar_data
      } = chart;
      const { summary_table } = resp.message.data;

      setMainBarChart(sections_bar_data);
      setTotalBarChart(totals_bar_data);
      setPaymentPieChart(actual_payments_pie_data);
      setPaymentVarianceBarChart(actual_payments_variance_bar_data);
      setBudgetPieChart(budget_pie_data);
      setBudgetVarianceBarChart(budget_variance_bar_data);

      setSummaryTable(summary_table);
    }
  };
  const update = async (update:any) => {
    const page = currentPage || globalLocalStorage.getItem('projectDashboard');
    if (page) {
      if (update.slug.includes('created_at')) {
        const newBody = {
          slug: 'get_project_dashboard',
          data: {
            project_id: parseInt(page.split('-')[1])
          },
          filters: [
            {
              category_name: update.slug,
              filter: `${update.start_date} - ${update.end_date}`,
              sort: 'DESC',
              title: update.title
            }
          ]
        };
        pageData(newBody);
      }
    }
  };

  const barData1Presensation = {
    showLegend: false,
    showYAxisLabel: true,
    showTickLabel: true,
    width: 860,
    height: 400
  };

  const barData2Presensation = {
    showLegend: true,
    showYAxisLabel: true,
    showTickLabel: true,
    width: 360,
    height: 400
  };

  const barData3Presensation = {
    showLegend: true,
    showYAxisLabel: false,
    showTickLabel: false,
    width: 160,
    height: 160
  };

  const firstRowChartProps: BarChartComponentProps[] = [
    {
      ...barData1Presensation,
      heading: 'Chart One',
      data: barData1
    },
    {
      ...barData2Presensation,
      heading: 'Chart Two',
      data: barData2
    }
  ];
  const secondRowChartProps: ChartPair[] = [
    {
      PieChartComponent: {
        heading: 'ACTUAL PAYMENT',
        data: paymentPieChart,
        showLegend: true,
        width: 160,
        height: 160

      },
      barChartComponent: {
        ...barData3Presensation,
        heading: 'ACTUAL VALUE VARIANCES',
        data: paymentVarianceBarChart
      }
    },
    {
      PieChartComponent: {
        heading: 'ACTUAL BUDGET',
        data: budgetPieChart,
        showLegend: true,
        width: 160,
        height: 160
      },
      barChartComponent: {
        ...barData3Presensation,
        heading: 'BUDGET VALUE VARIANCES',
        data: budgetVarianceBarChart
      }
    }
  ];

  return (
    <>
      <EnhancedComponent.SideDrawer/>
      <div className="container">
        {
          loading ? <BasicComponent.Loader size={60} message="Loading data..." />
            : barData1 &&
              <>
                <EnhancedComponent.Dashboard
                  title={'Project Dashboard'}
                  type={'project'}
                  onUpdate={update}
                  firstRowChartProps={firstRowChartProps}
                  secondRowChartProps={secondRowChartProps}
                  summaryTable={summaryTable}
                />
              </>
        }
      </div>
    </>
  );
};

export default CompanyDashboard;
