import React, { useState } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';

export const RadioButtons = ({ name, default_value, controlledValue, radio_options, pageSectionId, onChange }: any) => {
  const [value, setValue] = useState(default_value);

  const handleRadioChange = (event: any) => {
    // @MM If we do not have a controlled value then update state;
    !controlledValue && setValue(event.target.value);
    onChange(event.target.value, pageSectionId);
  };

  React.useEffect(() => controlledValue && setValue(controlledValue), [controlledValue]);

  return (
    <FormControl component="fieldset">
      <RadioGroup className="radio-btns" row aria-label="position" name={name} value={value} onChange={handleRadioChange}>
        { radio_options.map(({ value, label, placement }: any) => (
          <FormControlLabel
            key={label}
            value={value}
            className="radio-btn"
            control={<Radio color="primary" />}
            label={label}
            labelPlacement={placement}
          />
        )
        )}
      </RadioGroup>
    </FormControl>
  );
};
