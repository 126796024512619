import React, { useState } from 'react';
import BasicComponent from '../../basicComponents/index';
import {
  Button
} from '@material-ui/core';
import { settingsServices } from '../../../services/api/helpers/settingsServices';

// import axios from 'axios';

export const PaymentGateway = (props:any) => {
  const [state, setState] = useState({
    form_fields: {
      merchant_id: '',
      merchant_key: '',
      return_url: '',
      cancel_url: '',
      notify_url: '',
      name_first: '',
      name_last: '',
      email_address: '',
      m_payment_id: '',
      amount: '',
      item_name: '',
      custom_int1: '',
      subscription_type: '',
      frequency: '',
      cycles: '',
      signature: ''
    },
    submit_url: ''
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: ''
  });

  const { heading, details } = props;
  const { merchant_id, merchant_key, return_url, cancel_url, notify_url, name_first, name_last, email_address, m_payment_id, amount, item_name, custom_int1, subscription_type, frequency, cycles, signature } = state.form_fields;

  const generateRequest = async () => {
    const response = await settingsServices.SubscriptionRequest(null);
    if (response?.success) {
      const { merchant_id, merchant_key, return_url, cancel_url, notify_url, name_first, name_last, email_address, m_payment_id, amount, item_name, custom_int1, subscription_type, frequency, cycles, signature } = response.message.data.form_fields;
      const submit_url = response.message.data.submit_url;
      setState({
        form_fields: {
          merchant_id,
          merchant_key,
          return_url,
          cancel_url,
          notify_url,
          name_first,
          name_last,
          email_address,
          m_payment_id,
          amount,
          item_name,
          custom_int1,
          subscription_type,
          frequency,
          cycles,
          signature
        },
        submit_url
      });

      /** SHIT code since Axios not responding */
      const sumbit = () => {
        const submit = document.getElementById('submitBTN');
        submit?.click();
      };
      sumbit();
    }
  };

  const cancelSubscription = async () => {
    const response = await settingsServices.SubscriptionCancel(null);
    if (response?.success) {
      setOpenModal(false);
      const message = 'Subscription cancelled successfully';
      setOpenAlert(true);
      setAlertMessage({
        message: message,
        severity: 'success'
      });
    } else {
      const { message } = response?.message.data.data;
      setOpenModal(false);
      setOpenAlert(true);
      setAlertMessage({
        message: message,
        severity: 'error'
      });
    }
  };

  const modalBody = (
    <div className="card modal big one">
      <div className="header">
        <h2 className="center bold">Confirmation</h2>
      </div>
      <div className="body">
        <p className="center">Are you sure that you want to cancel your subscription?</p>
        <div className="buttons">
          <Button className="btn small jend" variant="contained" color="primary" onClick={() => setOpenModal(false)}>Keep Subscription</Button>
          <Button className="btn small jend btn-cancel" variant="contained" onClick={cancelSubscription}>Cancel Subscription</Button>
        </div>
      </div>
    </div>
  )

  const handleModalClose = () => {
    setOpenModal(false);
  }

  return (
    <>
      <div className="heading underline one gcrg20">
        <h3 className="uppercase jstart">{heading}</h3>
      </div>
      <div className="billing one grg20">
        {details?.map(({ label, text }:any) => (
          <div key={label} className="detail">
            <h3 className="uppercase jstart">{label}</h3>
            <p className="jstart">{text}</p>
          </div>
        ))}
        <div className="buttons two gcrg20">
          <Button className="btn small jend" variant="contained" color="primary" disabled={!details[0].buttons[0].has_permissions} onClick={() => generateRequest()}>Update Payment Method</Button>
          <Button className="btn small jend btn-cancel" variant="contained" disabled={!details[1].buttons[0].has_permissions} onClick={() => setOpenModal(true)}>Cancel Subscription</Button>
        </div>
        <BasicComponent.CustomizedSnackbars message={alertMessage.message} severity={alertMessage.severity} onOpen={openAlert} onClose={(open:any) => setOpenAlert(open)}/>
      </div>
      <form action={state.submit_url} method="POST" className="subscription-form" autoComplete="off">
        <input hidden key="merchant-id" name="merchant_id" value={merchant_id}/>
        <input hidden key="merchant-key" name="merchant_key" value={merchant_key}/>
        <input hidden key="return-url" name="return_url" value={return_url}/>
        <input hidden key="cancel-url" name="cancel_url" value={cancel_url}/>
        <input hidden key="notify-url" name="notify_url" value={notify_url}/>
        <input hidden key="name-first" name="name_first" value={name_first}/>
        <input hidden key="name-last" name="name_last" value={name_last}/>
        <input hidden key="email-address" name="email_address" value={email_address}/>
        <input hidden key="m-payment-id" name="m_payment_id" value={m_payment_id}/>
        <input hidden key="amount" name="amount" value={amount}/>
        <input hidden key="item-name" name="item_name" value={item_name}/>
        <input hidden key="custom-int1" name="custom_int1" value={custom_int1}/>
        <input hidden key="subscription-type" name="subscription_type" value={subscription_type}/>
        <input hidden key="frequency" name="frequency" value={frequency}/>
        <input hidden key="cycles" name="cycles" value={cycles}/>
        <input hidden key="signature" name="signature" value={signature}/>
        <input hidden id="submitBTN" type="submit" />
      </form>
      <BasicComponent.DynamicModal
        onOpen={openModal}
        body={modalBody}
        onClose={handleModalClose}
      />
    </>
  );
};
