import React, { useState, useEffect } from 'react';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import BasicComponent from '../../../components/basicComponents/index';
import {
  Button
} from '@material-ui/core';
import { settingsServices } from '../../../services/api/helpers/settingsServices';
import ClientRegister from './client-register';
import './styles.sass';

interface ProjectSettingsState {
  slugID: string,
  data: any,
  filters: Array<any>,
  pagination: any,
  total_results: number
}

interface Filters {
  filters: Array<any>
}

const ClientManager = (props: any) => {
  const { filtersData, pagination, paginateSet, pageAndFilterStatus } = useSearchAndFilterContext();
  const { setDisplay } = props;
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(false);
  const [state, setState] = useState<ProjectSettingsState>({
    slugID: 'client_details-0',
    data: [],
    filters: [],
    pagination: pagination || {},
    total_results: 0
  });

  useEffect(() => {
    (async function () {
      await pageData();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, [details]);

  useEffect(() => {
    /** Filtering and Pagination */
    if (pageAndFilterStatus === 'UPDATED') {
      filterAndPagination(
        filtersData.filters && filtersData.filters.length >= 0 ? filtersData : null,
        pagination && pagination.length >= 0 ? pagination : null
      );
    }
  }, [filtersData, pageAndFilterStatus]);

  const filterAndPagination = async (filtersData:any, paginationData:any) => {
    const { filters } = filtersData;

    const body: {[key: string]: any} = {
      slug: 'read',
      data: {
        page: 'client_register'
      },
      pagination: {}
    };
    if (filtersData) body.filters = filters;
    if (paginationData && paginationData.length > 0) body.pagination = paginationData[0];

    const response = await settingsServices.crudSettings('clientAndJob', body);
    if (response?.success) {
      const { filters, total_results, pagination } = response.message;
      const { page_data } = response.message.data;
      setState({
        ...state,
        data: page_data,
        filters,
        pagination,
        total_results
      });
      paginateSet(pagination ? [pagination] : []);
    } else {
      paginateSet(pagination);
    }
  };

  const pageData = async () => {
    const { filters } = filtersData;
    const body = {
      slug: 'read',
      data: {
        page: 'client_register'
      },
      filters,
      pagination: {}
    };
    if (pagination && pagination.length > 0) body.pagination = pagination[0];
    const response = await settingsServices.crudSettings('clientAndJob', body);
    if (response?.success) {
      const pageData = response.message.data.page_data;
      const { filters, total_results, pagination } = response.message;
      setState({
        ...state,
        data: pageData,
        filters,
        pagination,
        total_results
      });
      paginateSet(pagination ? [pagination] : []);
    } else {
      paginateSet(pagination);
    }
  };

  const create = () => {
    setState({ ...state, slugID: 'client_details-0' });
    setDetails(true);
    setDisplay(false);
  };

  const changeView = (view: any) => {
    setState({ ...state, slugID: view });
    setDetails(true);
    setDisplay(false);
  };

  const onBack = () => {
    setLoading(true);
    setDetails(false);
    setDisplay(true);
  };

  return (
    <>
      {
        loading
          ? <BasicComponent.Loader size={60} message="Loading data..." />
          : !details
            ? <section className="client-manager gcrg30">
              <div className="button one gcrg20">
                {/* <h1 className="uppercase pc1 jstart">{state.data.heading}</h1> */}
                {state.data.buttons.map(({ action, name, has_permissions }: any) => {
                  if (action === 'Create') { return <Button className="btn small jend" variant="contained" color="primary" disabled={!has_permissions} onClick={create}>{name}</Button>; }
                })}
              </div>
              <div className="card two grg20">
                <BasicComponent.DynamicSettingsTable section_type={0} tableName="table client-table col2-span" {...state} changeView={changeView}/>
              </div>
            </section>
            : <ClientRegister settings={state.slugID} onBack={onBack} />
      }
    </>
  );
};

export default ClientManager;
