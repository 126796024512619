/* eslint-disable */
import * as React from 'react';

type NotificationsType = {
  notifyData: {
    total_results: number,
    unread_state: number,
    notification_group: any
  };
  paginationData: {
    pagination: {
      rows_per_page: number,
      page: number
    };
  };
  notifications: (total_results: number, unread_state: number, notification_group: any) => void;
  paginationSet: (pagination: any) => void;
}

export const NotificationsContext = React.createContext<NotificationsType>({
  notifyData: {
    total_results: 0,
    unread_state: 1,
    notification_group: []
  },
  paginationData: {
    pagination: {
      rows_per_page: 10,
      page: 1

    }
  },
  notifications: () => null,
  paginationSet: () => null
});

export const useNotificationsContext = () => React.useContext(NotificationsContext);

const NotificationsProvider: React.FC<{}> = ({ children }) => {
  const [notifyData, setNotifyData] = React.useState({
    total_results: 0,
    unread_state: 1,
    notification_group: []
  });
  const [paginationData, setPaginationData] = React.useState({
    pagination: {
      rows_per_page: 10,
      page: 1
    }
  });

  function notifications (total_results: number, unread_state: number, notification_group: any) {
    setNotifyData({
      total_results,
      unread_state,
      notification_group
    });
  };

  function paginationSet (pagination: any) {
    setPaginationData({
      pagination
    })
  };

  return (
    <NotificationsContext.Provider value={{
      notifyData,
      paginationData,
      notifications,
      paginationSet,
    }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;

// Build provider
// Create hook (expose context)
// index.tsx - context folder. Wrap childers with providers
// main app wrap in index.tsx
