import React from 'react';
import Chip from '@material-ui/core/Chip';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';

export const FilterResults = (filters: any) => {
  const { removeFilter } = useSearchAndFilterContext();

  return (
    <div className="filter-results">
      { filters.filters.filter((filter:any) => (!!filter.category_name && !!filter.filter)).map(({ category_name, filter, sort, title }: any) => (
        <Chip
          key={category_name}
          size="small"
          label={`${title}: ${filter}`}
          title={ sort ? `${filter} / ${sort}` : filter }
          onDelete={() => removeFilter(category_name)}
        />
      ))}
    </div>
  );
};
