/* eslint-disable no-unused-vars */
import React, { createContext, useReducer } from 'react';
import globalReducer from './globalReducer';
import initialState, { User, Settings, Company, Project } from './initialState';

import {
  USER_ACTIONS, SETTINGS_ACTIONS,
  COMPANY_ACTIONS, PROJECT_ACTIONS,
  NAVIGATION_ACTIONS
} from './actionTypes';

const GlobalContext = createContext(initialState);

const GlobalProvider = ({ children }:any) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  const updateCurrentPage = (currentPage: string) => {
    dispatch({
      type: NAVIGATION_ACTIONS.UPDATE,
      payload: currentPage
    });
  };
  const updateUser = (user: User) => {
    dispatch({
      type: USER_ACTIONS.UPDATE,
      payload: user
    });
  };

  const updateSettings = (settings: Settings) => {
    dispatch({
      type: SETTINGS_ACTIONS.UPDATE,
      payload: settings
    });
  };

  const updateCompany = (company: Company) => {
    dispatch({
      type: COMPANY_ACTIONS.UPDATE,
      payload: company
    });
  };

  const updateProject = (project: Project) => {
    dispatch({
      type: PROJECT_ACTIONS.UPDATE,
      payload: project
    });
  };

  const actions = {
    updateCurrentPage,
    updateUser,
    updateSettings,
    updateCompany,
    updateProject
  };

  return (
    <GlobalContext.Provider
      value={{
        ...state,
        ...actions
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export {
  GlobalContext,
  GlobalProvider
};
