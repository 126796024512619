import React from 'react';
import { ListItemIcon } from '@material-ui/core';
import './style.sass';

export const NavSectionIcon = ({ slug, isActive }: any) => (
  <ListItemIcon >
    <div className={`custom-section-icon ${isActive ? 'active' : 'inactive'}`}>
      <p>{slug.split('-').slice(-1).pop()}</p>
    </div>
  </ListItemIcon>
);
