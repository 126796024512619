import React from 'react';
import {
  PieChart, Pie, Cell, Tooltip
} from 'recharts';
import BasicComponent from './../';
import { BAR_COLORS as COLORS } from '../chartLegend/chartLegend.component';
import './style.css';

export interface PieData{
  name: string,
  value: number
}
export interface PieChartComponentProps{
  heading: string,
  data: PieData[],
  showLegend: boolean,
  width: number,
  height: number
}
export const PieChartComponent = (props:PieChartComponentProps) => {
  const { heading, data, showLegend, width, height } = props;
  return (
    <>
      {
        heading &&
          <h3>{heading}</h3>
      }
      <div className="chart">
        <div className="percentage">
          <PieChart
            width={width}
            height={height}
          >
            <Tooltip
              contentStyle={{ borderRadius: 5, backgroundColor: 'rgba(240,240,240,0.85)' }}
              labelStyle={{ fontWeight: 'bold' }}
              itemStyle={{ fontWeight: 600 }}
            />
            <Pie
              data={data}
              cx={width / 2 - 5}
              cy={height / 2 - 5}
              labelLine={false}
              // label={customLabel}
              innerRadius={width / 2.8}
              outerRadius={width / 2}
              dataKey={'value'}
              legendType={'square'}
            >
              {
                data.map((entry: any, index:number) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
              }
            </Pie>
          </PieChart>
        </div>
        {
          showLegend &&
            <div className="legend">
              {customLegend(data)}
            </div>
        }
      </div>
    </>
  );
};

const customLegend = (data:PieData[]) => {
  const sum = data.reduce((a, b) => a + b.value, 0);
  const pieLegend = data.map((item, index) => ({
    name: item.name,
    percentage: `${(item.value / sum * 100).toFixed(0)}%`,
    color: COLORS[index % COLORS.length]
  }));
  return BasicComponent.PieChartLegend(pieLegend);
};

// const customLabel = (data: any) => {
//   const {
//     cx, cy, midAngle, innerRadius, outerRadius, percent
//   } = data;

//   const RADIAN = Math.PI / 180;
//   const radius = innerRadius + 0.25 * (outerRadius - innerRadius);
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text
//       x={x}
//       y={y}
//       fill={'white'}
//       fontWeight={'bold'}
//       fontSize={8}
//       textAnchor={x > cx ? 'start' : 'end'}
//       dominantBaseline={'central'}
//       className={'percentages'}
//     >
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };
