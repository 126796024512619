import React from 'react';

const ForgotPasswordSuccessful = () => {
  return (
    <section className="user forgot-password">
      <div className="card">
        <div className="header">
          <div className="logo">
            <img src="img/Logo-L.png" alt="logo"/>
          </div>
        </div>
        <div className="body">
          <h1 className="center">Password successfuly update</h1>
          <p className="center">Your password has been updated successfully, you may now log in with your new password.</p>
          {/* <a href="#"></a> */}
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordSuccessful;
