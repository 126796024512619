import React, { useState } from 'react';
import { FormControl, InputLabel, Checkbox } from '@material-ui/core';
import '../style.css';

interface Props {
  className?: string,
  keyName: string,
  label?: string,
  value: any,
  onClick: any,
}

export const CheckBoxClick = ({ className, keyName, label, value, onClick }:Props) => {
  const defaultVal = value !== 0;
  const [state, setState] = useState({
    value: defaultVal
  });

  const handleChange = () => {
    setState({ value: !state.value });
    onClick(!state.value, keyName);
  };

  return (
    <FormControl key={keyName} className={`${className || ''} checkbox`}>
      { label && <InputLabel shrink htmlFor={label}>{label}</InputLabel>}
      <Checkbox
        defaultChecked={state.value}
        color="primary"
        onChange={handleChange}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </FormControl>
  );
};
