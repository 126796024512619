import React, { useState, useEffect } from 'react';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import BasicComponent from '../../../components/basicComponents/index';
import EnhancedComponent from '../../../components/enhancedComponents';
import {
  Button
} from '@material-ui/core';
// eslint-disable-next-line no-unused-vars
import ExcelJS, { Workbook } from 'exceljs';
import { settingsServices } from '../../../services/api/helpers/settingsServices';
import './styles.css';

interface ProjectSettingsState {
  data: any,
  filters: Array<any>,
  total_results: number,
  pagination: any
}

interface Filters {
  filters: Array<any>
}

const Catalogue: React.FC = () => {
  const { filtersData, pagination, paginateSet, pageAndFilterStatus } = useSearchAndFilterContext();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<ProjectSettingsState>({
    data: [],
    filters: [],
    total_results: 0,
    pagination: []
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: ''
  });
  const [modal, setModal] = useState({
    items: [],
    open: false
  });

  let fileRef: any = null;

  useEffect(() => {
    (async function () {
      await pageData();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, []);

  useEffect(() => {
    if (pageAndFilterStatus === 'UPDATED') {
      filterAndPagination(
        filtersData.filters && filtersData.filters.length >= 0 ? filtersData : null,
        pagination && pagination.length >= 0 ? pagination : null
      );
    }
  }, [filtersData, pageAndFilterStatus]);

  const filterAndPagination = async (filtersData:any, paginationData: any) => {
    const { filters } = filtersData;

    const body: {[key: string]: any} = {
      slug: 'read',
      data: {},
      filters,
      pagination: {}
    };
    if (filtersData) body.filters = filters;
    if (paginationData && paginationData.length > 0) body.pagination = paginationData[0];

    const response = await settingsServices.crudCatalogue(body);
    if (response?.success) {
      const pageData = response.message.data.page;
      const { filters, total_results, pagination } = response.message;
      setState({
        ...state,
        data: pageData,
        filters,
        pagination,
        total_results
      });
      paginateSet(pagination ? [pagination] : []);
    } else {
      paginateSet(pagination);
    }
  };

  const pageData = async () => {
    const { filters } = filtersData;
    const body = {
      slug: 'read',
      data: {},
      filters,
      pagination: {}
    };
    if (pagination && pagination.length > 0) body.pagination = pagination[0];
    const response = await settingsServices.crudCatalogue(body);
    if (response?.success) {
      const pageData = response.message.data.page;
      const { filters, total_results, pagination } = response.message;
      setState({
        ...state,
        data: pageData,
        filters,
        pagination,
        total_results
      });
      paginateSet(pagination ? [pagination] : []);
    } else {
      paginateSet(pagination);
    }
  };

  const modalBody = (
    <div className="card modal big one">
      <div className="header">
        <h2 className="center bold">Errors</h2>
      </div>
      <div className="body">
        <div>
          {modal.items.map((item: any) => {
            if (item && item.error && item.catalogue_code) {
              return (
                <p key={item.catalogue_code} style={{ textAlign: 'center' }}>
                  {`${item.catalogue_code} - ${item.error}`}
                </p>
              );
            }
          })}
        </div>
        <div className="buttons">
          <Button className="btn small jend" variant="contained" color="primary" onClick={() => setModal({ items: [], open: false })}>Ok</Button>
        </div>
      </div>
    </div>
  );

  const onUpdate = async (updateState: any, data: any) => {
    const body = {
      slug: updateState,
      data: {}
    };
    if (updateState === 'batch' && data.data.length > 0) {
      const response = await settingsServices.crudCatalogue(data);
      if (response?.success && response?.message.data.message.length > 0) {
        let isErr = false;
        let count = 0;
        const errorCount = response?.message.data.message.length;
        while (!isErr && count < response?.message.data.message.length) {
          const result = response?.message.data.message[count];
          if (!result || result.error) {
            isErr = true;
            if (response?.message.data.message.length > 0) {
              if (response?.message.data.message.length > 10) {
                setOpenAlert(true);
                setAlertMessage({
                  message: `There ${errorCount === 1 ? 'was' : 'were'} ${errorCount} error${errorCount > 1 ? 's' : ''} while trying to add to the catalogue.`,
                  severity: 'error'
                });
              } else {
                setModal({
                  open: true,
                  items: response?.message.data.message
                });
              }
            }
          }
          count++;
        }
        if (!isErr) {
          setOpenAlert(true);
          setAlertMessage({
            message: 'Catalogue items added successfully.',
            severity: 'success'
          });
        }
        pageData();
      } else {
        if (response?.message.data.message.length === 0) {
          setOpenAlert(true);
          setAlertMessage({
            message: 'There were no new items to add.',
            severity: 'error'
          });
        } else {
          setOpenAlert(true);
          setAlertMessage({
            message: 'There was an error.',
            severity: 'error'
          });
        }
      }
    };

    if (updateState === 'update') {
      const catalogueItemID = parseInt(data.slug.split('-')[1]);
      const catalogueItem: any[] = [];
      data.data.forEach((update:any) => { catalogueItem.push(update); });
      Object.assign(body.data, { catalogue_item_id: catalogueItemID, catalogue_item: catalogueItem });

      const response = await settingsServices.crudCatalogue(body);
      if (response?.success) {
        pageData();
      }
    }

    if (updateState === 'delete') {
      const newId = parseInt(data.split('-')[1]);
      Object.assign(body.data, { catalogue_item_id: newId });

      const response = await settingsServices.crudCatalogue(body);
      if (response?.success) {
        pageData();
        setOpenAlert(true);
        setAlertMessage({
          message: response.message.data.message,
          severity: 'success'
        });
      }
    }

    if (updateState === 'copy') {
      Object.assign(body.data, { copy_from_id: data });

      const response = await settingsServices.crudCatalogue(body);
      if (response?.success) {
        const newCatalogueItem = response.message.data.catalogue_item_row;
        setState({ ...state, data: { ...state.data, table: { ...state.data.table, body: [...state.data.table.body, newCatalogueItem] } } });
      }
    }
  };

  const createCatalogueItem = async () => {
    const body = {
      slug: 'create',
      data: {}
    };
    const response = await settingsServices.crudCatalogue(body);
    if (response?.success) {
      const newRow = response.message.data.new_row;
      setState({
        ...state, data: { ...state.data, table: { ...state.data.table, body: [newRow, ...state.data.table.body] } }
      });
    }
  };

  const createTemplate = async () => {
    const body = {
      slug: 'create',
      data: {}
    };
    const response = await settingsServices.crudCatalogue(body);
    if (response?.success) {
      const fields = response?.message.data.new_row.cells;
      const workbook = new Workbook();
      const sheet = workbook.addWorksheet('Catalogue Item Template');
      const headingRow = sheet.getRow(1);
      const nextRows = sheet.getRows(2, 49)!;
      let headingCount = 1;
      fields.forEach((field: any) => {
        if (field.label) {
          headingRow.getCell(headingCount).value = field.label;
          if (field.type === 'dropdown') {
            const arr: any[] = [];
            field.dropdown.options.forEach((option: any) => {
              arr.push(`${option.id} -- ${option.name}`);
            });
            nextRows.forEach((row: any) => {
              row.getCell(headingCount).dataValidation = {
                type: 'list',
                allowBlank: false,
                formulae: [`"${arr}"`]
              };
            });
          }
          headingCount++;
        }
        if (field.name === 'bowser') {
          headingRow.getCell(headingCount).value = 'Bowser';
          nextRows.forEach((row: any) => {
            row.getCell(headingCount).dataValidation = {
              type: 'list',
              allowBlank: false,
              formulae: ['"Yes,No"']
            };
          });
          headingCount++;
        }
      });
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const anchor = document.createElement('a');
        const url = URL.createObjectURL(blob);
        anchor.download = 'catalogue-template';
        anchor.href = url;
        anchor.click();
        anchor.remove();
      });
    }
  };

  const uploadTemplate = async (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const body = {
      slug: 'batch',
      // eslint-disable-next-line no-array-constructor
      data: Array()
    };
    const file = e.target.files[0];
    if (file) {
      const workbook = new Workbook();
      const fileReader = new FileReader();
      fileReader.onload = (buffer: any) => {
        workbook.xlsx.load(buffer.target.result).then((data: any) => {
          if (!workbook || !workbook.getWorksheet(1) || !workbook.getWorksheet(1).getSheetValues() || workbook.getWorksheet(1).getCell('A1').value !== 'Division *') {
            setOpenAlert(true);
            setAlertMessage({
              message: 'Please upload a compatible file.',
              severity: 'error'
            });
            return;
          }
          const values = workbook.getWorksheet(1).getSheetValues()!;
          const d = [];
          for (let i = 2; i < 52; i++) {
            let row: any = null;
            const temp: any[] = [];
            if (values[i]) {
              row = values[i] || null;
            }
            if (
              row &&
              row[1] &&
              row[2] &&
              row[6] &&
              row[7] &&
              row[8]
            ) {
              temp.push({ key: 'catalogue_item_division_id', value: row[1].split(' -- ')[0] });
              temp.push({ key: 'catalogue_code', value: row[2] });
              temp.push({ key: 'item_description', value: row[3] ? row[3] : null });
              temp.push({ key: 'bowser', value: row[4] && row[4] === 'Yes' ? 1 : 0 });
              temp.push({ key: 'fuel_id', value: row[5] ? row[5][0] : null });
              temp.push({ key: 'group_type_id', value: row[6].split(' -- ')[0] });
              temp.push({ key: 'cost_code_id', value: row[7].split(' -- ')[0] });
              temp.push({ key: 'cost_type_id', value: row[8].split(' -- ')[0] });
              if (temp.length > 0) {
                d.push(temp);
              }
            } else if (row && !row[1] && !row[2] && !row[3] && !row[4] && !row[5] && !row[6] && !row[7] && !row[8]) {
            } else if (row) {
              setOpenAlert(true);
              setAlertMessage({
                message: 'Some items have missing required fields. Please check your file contents.',
                severity: 'error'
              });
              return;
            }
          }
          if (d.length === 0) {
            setOpenAlert(true);
            setAlertMessage({
              message: 'There were no new items to add.',
              severity: 'error'
            });
            return;
          }
          body.data = d;
          onUpdate(body.slug, body);
        });
      };
      fileReader.readAsArrayBuffer(file);
    }
  };
  return (
    <>
      <EnhancedComponent.SideDrawer/>
      <div className="container">
        {
          loading ? <BasicComponent.Loader size={60} message="Loading..." />
            : <section className="catalogue grg30">
              <div className="heading two gcrg20">
                <h1 className="uppercase pc1 jstart">{state.data.heading}</h1>
                <div style={{ textAlign: 'right' }}>
                  <Button style={{ marginRight: '10px' }} className="btn small jstart" variant="contained" color="primary" disabled={!state.data.buttons[0].has_permissions} onClick={createTemplate}>Download Template</Button>
                  <Button className="btn small jstart" variant="contained" color="primary" disabled={!state.data.buttons[0].has_permissions} onClick={() => fileRef.click()}>Bulk Upload</Button>
                  {/* eslint-disable-next-line no-return-assign */}
                  <input ref={(r) => fileRef = r} type="file" accept=".xls,.xlsx" onClick={(e: any) => e.target.value = null} onChange={uploadTemplate} hidden={true} />
                </div>
              </div>
              <div className="card two grg20">
                {state.data.buttons.map(({ action, has_permissions, slug, name }:any) => {
                  if (action === 'Create') { return <Button key={slug} className="btn small jstart" variant="contained" color="primary" disabled={!has_permissions} onClick={createCatalogueItem}>{name}</Button>; }
                })}
                <BasicComponent.DynamicSettingsTable section_type={0} tableName="table catalogue-table col2-span" {...state} onUpdate={onUpdate}/>
                <BasicComponent.CustomizedSnackbars message={alertMessage.message} severity={alertMessage.severity} onOpen={openAlert} onClose={(open:any) => setOpenAlert(open)}/>
              </div>
            </section>
        }
        {modal.open ? <BasicComponent.DynamicModal
          onOpen={modal.open}
          body={modalBody}
          onClose={() => setModal({ items: [], open: false })}
        /> : null}
      </div>
    </>
  );
};

export default Catalogue;
