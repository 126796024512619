/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { userServices } from '../../../services/api/helpers/userServices';
import { useNavFilterContext } from '../../../context/navFilterProvider';
import BasicComponent from '../index';
import _ from 'lodash';
import { IconButton, Popover, TextField, InputAdornment, Button } from '@material-ui/core';
import CustomIcons from '../../basicComponents/customIcons/customIcons.component';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import './nav-filter.sass';

export const NavFilter = (props: any) => {
  const { divisions, project_status } = props;
  const { navFilterFunc } = useNavFilterContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchQuery, setSearchQuery] = useState({});

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // const updateNavByFilter = async () => {
  //   const response = await userServices.get();
  //   if (response?.success) {
  //     console.log(response);
  //     // notifications(total_results, 0, notification_group);
  //   }
  // }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const searchHandler = ({ target: { value } }: any) => {
    const search = _.debounce(sendQuery, 1000);

    setSearchQuery((prevSearch:any) => {
      if (prevSearch.cancel) {
        prevSearch.cancel();
      }
      return search;
    });

    search(value);
  };

  const sendQuery = (value: any) => {
    navFilterFunc(undefined, undefined, value, undefined);
  };

  return (
    <>
      <IconButton className="filter-btn" onClick={handleClick}>
        <FilterListIcon color="primary" fontSize="small"/>
      </IconButton >
      <Popover
        id="nav-filters"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div className="nav-filters card grg20">
          <div className="title two gcg10">
            <h1 className="uppercase">Filter</h1>
            <IconButton onClick={handleClose}><CloseIcon fontSize="small"/></IconButton>
          </div>
          <div className="filter">
            <TextField
              id="filter"
              placeholder="By Keyword"
              autoComplete='off'
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              autoFocus={true}
              onKeyUp={searchHandler}/>
            {!!divisions && divisions.map((division: any) => (
              <BasicComponent.NestedCheckboxList key={division.name} {...division}/>
            ))}
          </div>
          <div className="separator"></div>
          <div className="sort two gcrg20">
            <h1 className="uppercase">Sort</h1>
            <Button
              aria-label="Sort"
              className="sort-btn jend"
              variant="contained"
              color="primary"
              onClick={() => navFilterFunc(undefined, undefined, undefined, 'ASC')}
            ><CustomIcons.AscendingIcon fontSize="small"/></Button>
            <Button
              aria-label="Sort"
              className="sort-btn jstart"
              variant="contained"
              color="primary"
              onClick={() => navFilterFunc(undefined, undefined, undefined, 'DESC')}
            ><CustomIcons.DescendingIcon fontSize="small"/></Button>
          </div>
          <div className="separator"></div>
          <div className="project-status">
            <h1 className="uppercase">Project Status</h1>
            <BasicComponent.CheckboxList {...project_status} name="status"/>
          </div>
        </div>
      </Popover>
    </>
  );
};
