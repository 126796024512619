import React, { useContext, useState, useEffect } from 'react';
import BasicComponent from '../../basicComponents/index';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import { useTableContext } from '../../../context/tableProvider';
import { useNavFilterContext } from '../../../context/navFilterProvider';
import { GlobalContext } from '../../../context/globalState';
import { useHistory } from 'react-router-dom';
import {
  List, ListItem,
  ListItemIcon, ListItemText, Collapse
} from '@material-ui/core';

interface Props {
  menuItem: any,
  projectDashboardPermissions: Boolean | null
}

interface APIMenuItem{
  isActive: boolean,
  inactive_icon: string,
  active_icon: string,
  title: string,
  slug:string,
  children: any,
}

export const ListItemSubMenu = ({ menuItem, projectDashboardPermissions }: Props) => {
  const { user, updateUser, updateCurrentPage } = useContext(GlobalContext);
  const { clearFilters, paginateSet } = useSearchAndFilterContext();
  const { editItemsSet } = useTableContext();
  const { isActive, navIsActiveSet } = useNavFilterContext();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!!isActive.menuSlug && !!isActive.subMenuSlug) {
      updateMenuItems(isActive.menuSlug, isActive.subMenuSlug);
      setOpen(isActive.menuSlug.includes(menuItem.slug));
    } else if (!isActive.menuSlug.includes(menuItem.slug)) {
      updateMenuItems('reset', 'reset');
      setOpen(false);
    }
  }, [isActive]);

  const redirect = (component: string, page:string): void => {
    clearFilters();
    paginateSet([]);
    editItemsSet(false);
    updateCurrentPage(page);
    if (component.includes('project') && component.includes('dashboard')) {
      history.push('/projectDashboard');
      navIsActiveSet(menuItem.slug, page);
    } else if (component.includes('project')) {
      history.push('/projectSection');
      navIsActiveSet(menuItem.slug, page);
    } else if (component.includes('settings')) {
      history.push(`/${page}`);
      navIsActiveSet(menuItem.slug, page);
    } else {
      history.push('/pageUnderContruction');
      navIsActiveSet(menuItem.slug, page);
    }
  };

  const updateMenuItems = (menuSlug: string, subMenuSlug: string) => {
    const { navigation } = user;
    // const { menuSlug } = isActive;

    const theMenuItems = navigation.map((nav: APIMenuItem) => {
      const { children } = nav;
      const menu = {
        ...nav,
        isActive: !!nav.slug.includes(menuSlug),
        children: activeSubMenu(children, subMenuSlug)
      };
      return menu;
    });
    updateUser({ ...user, navigation: theMenuItems });
  };

  const activeSubMenu = (children: any, subMenuSlug: string) => {
    // const { subMenuSlug } = isActive;
    const activeSubMenu = children.map((child:any) => {
      const newChild = {
        ...child,
        isActive: !!child.slug.includes(subMenuSlug)
      };
      return newChild;
    });
    return activeSubMenu;
  };

  return (
    <div className='listItem submenu'>
      <ListItem className={open ? 'submenu open' : 'submenu'} button alignItems='center' onClick={() => setOpen(!open)}>
        { menuItem.slug.includes('project')
          ? <BasicComponent.NavIcon key={menuItem.slug} { ...menuItem }/>
          : <ListItemIcon>
            <div className={`leftIcon ${menuItem.isActive ? 'active' : 'inactive'}`}>
              <img
                src={ menuItem.isActive ? menuItem.active_icon : menuItem.active_icon } alt='leftIcon'
                width='25px' height='25px'
              />
            </div>
          </ListItemIcon>
        }
        <ListItemText primary={menuItem.title} />
        {
          menuItem.rightIcon &&
            <div className='rightIcon'>
              <img
                src={menuItem.rightIcon} alt='rightIcon'
                width='12px' height='12px'
              />
            </div>
        }
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List className="menu" component="div" disablePadding>
          { menuItem.children.map((menuChild:any, i:number) => (
            <ListItem key={i} className={`menu-${i}`} button disabled={menuChild.slug.includes('dashboard') && !projectDashboardPermissions} onClick={() => redirect(menuChild.slug, menuChild.slug)}>
              { menuChild.slug.includes('project') && !menuChild.slug.includes('dashboard')
                ? <BasicComponent.NavSectionIcon key={menuChild.slug} { ...menuChild }/>
                : <ListItemIcon >
                  <div className={`leftIcon ${menuChild.isActive ? 'active' : 'inactive'}`}>
                    <img
                      src={ menuChild.isActive ? menuChild.active_icon : menuChild.inactive_icon } alt={`leftIcon-${menuChild.isActive ? 'active' : 'inactive'}`}
                      width='25px' height='25px'
                    />
                  </div>
                </ListItemIcon>
              }
              <ListItemText primary={menuChild.title} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
};
