import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { userServices } from 'src/services/api/helpers/userServices';
import EnhancedComponent from '../../components/enhancedComponents';

import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';

const PageUnderContruction = () => {
  // const {
  //   user
  // } = useContext(GlobalContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const userNavigation = await userServices.getUserNavigation4(null);
      if (!userNavigation || !userNavigation.success) {
        history.push('/login');
      } else {
        setLoading(false);
      }
    })();
  });

  const homeStyle = {
    marginTop: 20,
    marginLeft: 80,
    marginRight: 20,
    marginBottom: 80
  };

  return (
    <>
      <EnhancedComponent.SideDrawer/>
      <div style={homeStyle}>
        <Grid><h1 className='uppercase'>Page Under Contruction</h1></Grid>
        {
          loading ? <div className="loader">
            <CircularProgress size={80}/>
          </div> : <div className="loader">
            <p>
              This page is still under contruction. Thank you.
            </p>
          </div>
        }
      </div>
    </>
  );
};

export default PageUnderContruction;
