import { PieChartComponent } from './pieChart/pieChart.component';
import { BarChartComponent } from './barChart/barChart.component';
import { SimpleBarChartComponent } from './barChart/simpleBarChart.component';
import { PieChartLegend, ChartLegend } from './chartLegend/chartLegend.component';

import { DynamicTable } from './dynamicTables/dynamicTable.component';
import { DynamicCollapsibleTable } from './dynamicTables/dynamicCollapsibleTable.component';
import { DynamicCollapsibleItemsTable } from './dynamicTables/dynamicCollapsibleItemsTable.component';
import { DynamicSettingsTable } from './dynamicTables/dynamicSettingsTable.component';
import { DynamicSettingsCollapsibleTable } from './dynamicTables/dynamicSettingsCollapsibleTable.component';
import { AssetsTable } from './dynamicTables/assetsTable.component';
import { PermissionsTable } from './dynamicTables/permissionsTable.component';
import { DashboardTable } from './dynamicTables/dashboardTable.component';
import { JobAccessTable } from './dynamicTables/jobAccessTable.component';

import { SortFilter } from './sortFilter/sortFilter.component';
import { NavFilter } from './navFilter/navFilter.component';
import { DynamicModal } from './modals/dynamicModal.component';
import { Dropdown } from './dropdown/dropdown.component';
import { DropdownClick } from './dropdown/dropdownClick.component';
import { DatePicker } from './datePicker/datePicker.component';
import { CheckBox } from './checkBox/checkBox.component';
import { CheckBoxClick } from './checkBox/checkBoxClick.component';
import { CheckBoxClicked } from './checkBox/checkBoxClicked.component';
import { Details } from './details/details.component';
import { AutoComplete } from './autoComplete/autoComplete.component';
import { GoogleMaps } from './googleMaps/googleMaps.component';
import { UploadImage } from './uploadImage/uploadImage.component';
import { UploadFile } from './uploadFile/uploadFile.component';
import { Loader } from './loader/loader.component';
import { NavIcon } from './navIcon/navIcon.component';
import { NavSectionIcon } from './navIcon/navSectionIcon.component';
import { ConfirmDialog } from './confirmDialog/confirmDialog.component';
import { BasicTabs } from './tabs/basicTabs.component';
import { CustomizedSnackbars } from './snackBar/customizedSnackbars.component';
import { Tooltip } from './tooltip/tooltip.component';
import { Notifications } from './notifications/notifications.component';
import { RadioButtons } from './radioButtons/radioButtons.component';
import { FilterResults } from './filterResults/filterResults.component';
import { CustomLink } from './customLink/customLink.component';

import { NestedCheckboxList } from './list/nestedCheckboxList.component';
import { CheckboxList } from './list/checkboxList.component';

import { CustomTextField } from './inputType/customTextField.component';
import { TextFieldButton } from './inputType/textFieldButton.component';
import { FitlerDropdown } from './inputType/filterDropdown.component';
import { OtherCharges } from './inputType/otherCharges.component';

export default {
  ChartLegend,
  PieChartLegend,
  PieChartComponent,
  SimpleBarChartComponent,
  BarChartComponent,
  CheckBox,
  CheckBoxClick,
  CheckBoxClicked,
  Dropdown,
  DropdownClick,
  DatePicker,
  SortFilter,
  NavFilter,
  DynamicModal,
  DynamicTable,
  DynamicCollapsibleTable,
  DynamicCollapsibleItemsTable,
  DynamicSettingsTable,
  DynamicSettingsCollapsibleTable,
  AssetsTable,
  PermissionsTable,
  DashboardTable,
  JobAccessTable,
  Details,
  AutoComplete,
  GoogleMaps,
  UploadImage,
  UploadFile,
  Loader,
  NavIcon,
  NavSectionIcon,
  ConfirmDialog,
  BasicTabs,
  CustomizedSnackbars,
  Tooltip,
  Notifications,
  RadioButtons,
  FilterResults,
  CustomLink,
  CustomTextField,
  TextFieldButton,
  FitlerDropdown,
  OtherCharges,
  NestedCheckboxList,
  CheckboxList
};
