import React, { useState, useEffect } from 'react';
import { useTableContext } from '../../../context/tableProvider';
import { useSettingsContext } from '../../../context/settingsProvider';
import BasicComponent from '../../basicComponents/index';
import {
  AppBar,
  Tabs,
  Tab,
  IconButton,
  Button
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { settingsServices } from '../../../services/api/helpers/settingsServices';
import './basic-tabs.styles.css';

interface Update {
  slug: string,
  data: UpdateData
}

interface UpdateData {
  page?: string,
  id?: number,
  item?: any
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="settings one grg20">
          {children}
        </div>
      )}
    </div>
  );
};

const a11yProps = (index: any) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
};

export const BasicTabs = (props: any) => {
  const { heading, buttons, section_permissions, userId, updateState } = props;
  const { updateJobAccess, globalEditSet } = useTableContext();
  const { reloadDetailsSet } = useSettingsContext();
  const [jobsAssign, setJobsAssign] = useState(false);
  const [value, setValue] = useState(0);
  const [edit, setEdit] = useState(false);
  const [detailsState, setDetailsState] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [jobsAccess, setJobsAccess] = useState({
    data: {
      heading: '',
      buttons: [],
      slug: '',
      table: []
    },
    total_results: 0,
    filters: []
  });

  const [updatePermissions, setUpdatePermissions] = useState<Update>({
    slug: '',
    data: {
      page: '',
      id: 0,
      item: []
    }
  });

  useEffect(() => {
    jobsAccessData();
  }, []);

  const jobStatus = {
    name: 'access_status',
    dropdown: {
      options: [
        {
          id: 1,
          name: 'Job Status: Active'
        },
        {
          id: 2,
          name: 'Job Status: Inactive'
        },
        {
          id: 3,
          name: 'Job Status: Archived'
        },
        {
          id: 4,
          name: 'Job Status: Blocked'
        }
      ],
      active: 0
    }
  };

  const jobsAccessData = async () => {
    /** Get data for Jobs Access (API call)
     * This is used to set user access
     */
    const body = {
      slug: 'read',
      data: {
        page: 'user_jobs',
        id: userId
      }
    };

    const response = await settingsServices.crudSettings('user', body);
    if (response?.success) {
      const { page_data } = response.message.data;
      const { total_results, filters } = response.message;
      setJobsAccess({
        data: page_data,
        total_results,
        filters
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    setUpdatePermissions({
      slug: '',
      data: {
        page: '',
        id: 0,
        item: []
      }
    });
  };

  const editDetails = () => {
    setDetailsState(true);
    globalEditSet(true);
    setEdit(true);
  };

  const saveDetails = async () => {
    setDetailsState(false);
    globalEditSet(false);
    setEdit(false);

    if (jobsAssign) {
      /** User Job Access rights.
       *  This api updates JobsAccess state.
       *  When prop lifting is fixed move this too Parent component or to context API.
       */
      const newItems = updateJobAccess.data.items.filter((item:any) => (Object.prototype.hasOwnProperty.call(item, 'key')));
      const data = {
        ...updateJobAccess,
        data: {
          ...updateJobAccess.data,
          user_id: userId,
          items: newItems
        }
      };

      const response = await settingsServices.crudSettings('user', data);
      if (response?.success) {
        const { page_data } = response.message.data;
        const { total_results, filters } = response.message;
        setJobsAccess({
          data: page_data,
          total_results,
          filters
        });
      }
    } else {
      /** Bottles to parent.
       *  Parent updates state from API call.
       *  note: prop lifting functionality needs to be rewotked
       */
      updateState(updatePermissions);
    }
  };

  const deleteDetails = () => {
    // let tableBodyArr = tableBody;
    // tableBodyArr = tableBody.filter((rows:any) => rows.row_slug !== rowSlug);
    // setTableBody(tableBodyArr);
    // onUpdate('delete', details.data.slug);
  };

  const onUpdate = (updateState: any, data: any) => {
    if (updateState === 'update') {
      const currentState = updatePermissions.data.item;
      const foundItem = currentState?.find((keyValue: any) => (keyValue.key === data.item.key));
      if (foundItem) {
        // Replace the found item
        if (currentState) {
          const rightIndex = currentState.indexOf(foundItem);
          currentState[rightIndex].value = data.item.value;
        }
      } else {
        // Add item to update body
        setUpdatePermissions({
          ...updatePermissions,
          slug: updateState,
          data: {
            page: 'access_levels',
            id: userId,
            item: [...updatePermissions.data.item, data.item]
          }
        });
      }
    }
  };

  const activeInactive = (value: any, name: any) => {
    // console.log(value, name);
  };

  const assignJobs = async () => {
    setJobsAssign(!jobsAssign);
    setDetailsState(false);
    globalEditSet(false);
    if (jobsAssign) {
      reloadDetailsSet(true);
    }
  };

  return (
    <>
      <div className="heading underline two gcrg20">
        <h3 className="uppercase jstart acenter">{heading}</h3>
        <BasicComponent.DropdownClick
          className="access-status"
          keyName={jobStatus.name}
          val={jobStatus.dropdown.active}
          options={jobStatus.dropdown.options}
          onClick={activeInactive}
        />
        <div className="buttons jend">
          { buttons.map(({ action, slug, has_permissions }: any) => {
            if (action === 'Edit') {
              return (
                !detailsState
                  ? <IconButton key={slug} aria-label="edit" className="edit-btn" disabled={!has_permissions} onClick={() => editDetails()}><EditIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                  : <IconButton key={slug} aria-label="edit" className="edit-btn" disabled={!has_permissions} onClick={saveDetails}><SaveIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
              );
            } else if (action === 'Delete') {
              return (
                <>
                  <IconButton key={slug} aria-label="delete" className="delete-btn" disabled={!has_permissions} onClick={() => setConfirmOpen(true)}><DeleteIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                  <BasicComponent.ConfirmDialog confirm={true} open={confirmOpen} setOpen={setConfirmOpen} onConfirm={deleteDetails} >
                    <p>Are you sure you want to delete this item?</p>
                  </BasicComponent.ConfirmDialog>
                </>
              );
            }
          })}
        </div>
      </div>

      <div className="one grg20">
        <AppBar className="tab-container" position="static" color="default">
          <Button className="btn" variant="contained" color="primary" onClick={assignJobs}>{jobsAssign ? 'Back' : 'Add / Remove Job'}</Button>
          { !jobsAssign
            ? <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
              aria-label="scrollable auto tabs example"
            >
              { section_permissions.map((tab: any, i: number) => (
                <Tab key={tab.name} label={tab.name} {...a11yProps(i)} />
              ))}
            </Tabs>
            : null
          }
        </AppBar>
        { !jobsAssign
          ? section_permissions.map((tab: any, i: number) => (
            <TabPanel key={tab.id} value={value} index={i}>
              <div className="permissions two gcrg40">
                { tab.sections.map(({ section_name, permissions }:any) => (
                  <div key={section_name} className="permission one grg10 astart">
                    <div className="text">
                      <h2 className="bold">{section_name}</h2>
                    </div>
                    <BasicComponent.PermissionsTable tableName="table permission-table" id={tab.id} table={permissions} onUpdate={onUpdate} onEdit={edit}/>
                  </div>
                ))}
              </div>
            </TabPanel>
          ))
          : <div className="jobs-access">
            <BasicComponent.JobAccessTable {...jobsAccess} />
          </div>
        }
      </div>
    </>
  );
};
