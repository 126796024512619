import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './style.css';

type ConfirmDialog = {
  title?: string,
  children: React.ReactNode,
  confirm: boolean,
  open: boolean,
  setOpen: Function,
  onConfirm: Function
}
export const ConfirmDialog = (props: ConfirmDialog) => {
  const { title, children, confirm, open, setOpen, onConfirm } = props;
  return (
    <Dialog
      className="confirm-dialog"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      { title ? <DialogTitle className="confirm-dialog">{title}</DialogTitle> : null }
      <DialogContent>{children}</DialogContent>
      <DialogActions className="confirm-actions">
        { confirm
          ? <>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                onConfirm();
              }}
              color="primary"
              className="btn"
            >
                Yes
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpen(false)}
              color="secondary"
              className="btn"
            >
                No
            </Button>
          </>
          : <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            className="btn"
          >
              Accept
          </Button>

        }
      </DialogActions>
    </Dialog>
  );
};
