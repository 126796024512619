export interface User {
  token_type: string,
  api_access_token: string,
  message: string,
  company_name: string,
  navigation: any[]
};
export interface Settings {};
export interface Project {};
export interface Company {};

const initialState = {
  currentPage: '',
  user: {
    token_type: '',
    api_access_token: '',
    message: '',
    company_name: '',
    navigation: []
  },
  settings: {},
  project: {},
  company: {},

  updateCurrentPage: (currentPage:string) => {},
  updateUser: (user:User) => {},
  updateSettings: (settings:Settings) => {},
  updateCompany: (company:Company) => {},
  updateProject: (project:Project) => {},
  showModal: () => {},
  hideModal: () => {}
};

export default initialState;
