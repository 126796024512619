import React, { useState, useEffect } from 'react';
import BasicComponent from '../../../components/basicComponents/index';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  // TextField,
  FormControl,
  InputLabel,
  TextareaAutosize
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
// import ClearIcon from '@material-ui/icons/Clear';
import './style.css';

interface Props {
  tableName: string,
  id: any,
  table: any,
  onUpdate?: any
  onEdit?: any
}

export const PermissionsTable = ({ tableName, id, table, onUpdate, onEdit }: Props) => {
  const [tableHead] = useState(table.header_row);
  const [tableBody, setTableBody] = useState(table.body);

  useEffect(() => {
    setTableBody(table.body);
  }, [table]);

  const Row = (row:any) => {
    const [tableState] = useState(!onEdit);
    const [tableRow] = useState(row);

    const updateCell = (value:string, cellName:string, itemId?:number) => {
      /** Update table cell.
       * push data to update State called 'updateTableRow' for global save. */
      let item;
      tableRow.row.cells.forEach((cell:any) => {
        if (cell.name === cellName) {
          value ? cell.value = 1 : cell.value = 0;
          item = { key: cellName, value: value ? 1 : 0, id: itemId };
        }
      });
      onUpdate('update', { id, item });
    };

    const ActiveState = (state: any) => {
      switch (state.state) {
      case true:
        return <CheckIcon fontSize="small" className="correct"/>;

      default:
        return null;
      }
    };

    const inputType = (input:any, i:number) => {
      const inputType = input.type;
      switch (inputType) {
      case 'empty':
        return (
          <div/>
        );
      case 'text':
        return (
          input.text
        );
      case 'text_field':
        return (
          <BasicComponent.CustomTextField {...input} updateCell={updateCell}/>
        );
      case 'checkbox':
        return (
          <BasicComponent.CheckBox keyName={input.name} {...input} onBlur={updateCell} />
        );
      case 'dropdown':
        return (
          <BasicComponent.Dropdown keyName={input.name} label={input.label} val={input.dropdown.active} options={input.dropdown.options} validation={input.validation} onBlur={updateCell}/>
        );
      case 'text_area':
        return (
          <FormControl key={`text-area-${i}`} className="col3-span">
            <InputLabel shrink htmlFor="label">{input.label}</InputLabel>
            <TextareaAutosize aria-label={input.label} rowsMin={3} placeholder="Comment" />
          </FormControl>
        );

      default:
        break;
      }
    };

    return (
      <>
        <TableRow>
          {tableRow.row.cells.map((value:any, i:number) => (
            {
              ...tableState
                ? value.type !== 'checkbox'
                  ? <TableCell key={`tbodyCell-${i}`} align={value.align}>{value.text}</TableCell>
                  : <TableCell key={`tbodyCell-${i}`} align={value.align}><ActiveState state={value.value !== 0}/></TableCell>
                : <TableCell key={`tbodyCell-${i}`} align={value.align}>{inputType(value, i)}</TableCell>
            }
          ))}
        </TableRow>
      </>
    );
  };

  return (
    <>
      <TableContainer className={tableName}>
        <Table stickyHeader>
          <TableHead className="thead">
            <TableRow>
              {tableHead.cells.map((value:any, i:number) => (
                <TableCell key={`theadCell-${i}`} align={value.align}>{value.text}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="tbody">
            {tableBody.map((row:any) => (
              <Row key={row.row_slug} row={row}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
