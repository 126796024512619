import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'material-ui-formik-components';
import { Alert } from '@material-ui/lab';
import { useHistory, Link } from 'react-router-dom';
import { userServices } from '../../../services/api/helpers/userServices';

const ForgotPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const onSubmit = async (value:any) => {
    if (loading) {
      return;
    }

    setLoading(true);
    const response = await userServices.forgotPassword(value.email);
    if (response.message.status === 'success') {
      return history.push('/forgotPasswordSuccessful');
    }
    setLoading(false);
    setResponseMessage(response.message.data.message);
  };

  return (
    <section className="user forgot-password">
      <div className="card">
        <div className="header">
          <div className="logo">
            <img src="img/Logo-L.png"/>
          </div>
        </div>
        <div className="body">
          <h1 className="center">Forgot Your Password?</h1>
          <p className="center">Enter your email address to request password reset.</p>
          {
            responseMessage.length > 1 &&
            <Alert variant="outlined" severity="error">
              {responseMessage}
            </Alert>
          }
          <Formik
            initialValues={{ email: '' }}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched
            }) => (
              <Form className="one grg40">
                <Field
                  type="email"
                  name="email"
                  value={values.email}
                  component={TextField}
                  placeholder="Email Address"
                  variant="standard"
                  required
                />
                {errors.email && touched.email && errors.email}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {
                    loading ? <CircularProgress size={28} color={'secondary'} />
                      : 'Reset Password'
                  }
                </Button>
                <div className="links one">
                  <Link to="/login" style={{ color: 'grey' }}>
                    login
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
