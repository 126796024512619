import { getRequest, postRequest } from '../root/restRequests';
import { endpoints } from '../root/endPoints';
import { globalLocalStorage as storage } from '../../storage/localstorage';

const login = async (user: any) => {
  const response = await postRequest(endpoints.login, user);
  if (response.success) {
    storage.storeItem('api_access_token', response.message.data.api_access_token);
  }
  return response;
};

const getUserNavigation4 = async (body:any) => {
  const token = storage.getItem('api_access_token');
  if (!token) { return null; }
  return await postRequest(endpoints.getUserNavigation, body, token);
};

const userProfile = async (body:any) => {
  const token = storage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(endpoints.userProfile, body, token);
  return response;
};

const logout = async () => {
  const token = storage.getItem('api_access_token');
  if (!token) { return null; }

  const response = await getRequest(endpoints.logout, token);
  if (response.success) {
    storage.removeItem('api_access_token');
  }
  return response;
};

const register = async (user: any) => {
  const response = await postRequest(endpoints.register, user);
  return response;
};

const createAccount = async (body: any) => {
  const token = storage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(endpoints.createAccount, body, token);
  return response;
};

const forgotPassword = async (email:string) => {
  const body = { email };
  return await postRequest(endpoints.forgotPassword, body);
};

const getUserMetaData = async () => {
  return await postRequest(endpoints.getUserMetaData, {});
};

const verificationEmail = async () => {
  return await getRequest(endpoints.verificationEmail);
};

const resendVerificationEmail = async () => {
  return await getRequest(endpoints.resendVerificationEmail);
};

const getNotifications = async (body:any) => {
  const token = storage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(endpoints.notifications, body, token);
  return response;
};

const getNotificationsStatus = async () => {
  const token = storage.getItem('api_access_token');
  if (!token) { return null; }
  return await getRequest(endpoints.notificationsStatus, token);
};

export const userServices = {
  login,
  getUserNavigation4,
  logout,
  register,
  createAccount,
  forgotPassword,
  userProfile,
  getNotifications,
  getNotificationsStatus,

  getUserMetaData,
  verificationEmail,
  resendVerificationEmail
};
