import React, { useEffect, useState } from 'react';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import BasicComponent from '../../basicComponents/index';
import {
  Button
} from '@material-ui/core';
import { settingsServices } from '../../../services/api/helpers/settingsServices';

interface onLoad {
  children?: React.ReactNode;
  projectId: number,
  settingType: string
}

interface PageData {
  projectId: number,
  data: any,
  filters: Array<any>,
  pagination: any,
  total_results: number
}

export const Settings: React.FC<onLoad> = (props) => {
  const { filtersData, pagination, pageAndFilterStatus, paginateSet, clearFilters } = useSearchAndFilterContext();
  /*eslint-disable */
  const { projectId, settingType } = props;
  /* eslint-enable */
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<PageData>({
    projectId: 1,
    data: [],
    filters: [],
    pagination: {},
    total_results: 0
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: ''
  });

  useEffect(() => {
    (async function () {
      await pageData();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, [props]);

  useEffect(() => {
    /** Filtering and Pagination */
    if (pageAndFilterStatus === 'UPDATED') {
      filterAndPagination(
        filtersData.filters && filtersData.filters.length >= 0 ? filtersData : null,
        pagination && pagination.length >= 0 ? pagination : null
      );
    }
    return clearFilters();
  }, [pageAndFilterStatus]);

  const filterAndPagination = async (filtersData:any, paginationData: any) => {
    const { filters } = filtersData;

    const body: {[key: string]: any} = {
      slug: 'read',
      data: {
        tab: settingType
      },
      filters: filters ?? [],
      pagination: {}
    };
    if (pagination && pagination.length > 0) body.pagination = pagination[0];

    const response = await settingsServices.crudGeneralSettings(body);
    if (response?.success) {
      const { filters, total_results, pagination } = response.message;
      const { page_data } = response.message.data;
      setState({
        ...state,
        data: page_data,
        filters,
        pagination,
        total_results
      });
      paginateSet(pagination ? [pagination] : []);
    } else {
      paginateSet(pagination);
    }
  };

  const pageData = async () => {
    const { filters } = filtersData;
    const body = {
      slug: 'read',
      data: { tab: settingType },
      filters: filters ?? [],
      pagination: {}
    };
    if (pagination && pagination.length > 0) body.pagination = pagination[0];

    const response = await settingsServices.crudGeneralSettings(body);
    if (response?.success) {
      const pageData = response.message.data.page_data;
      const { filters, total_results, pagination } = response.message;
      setState({
        projectId,
        data: pageData,
        filters,
        pagination,
        total_results
      });
      paginateSet(pagination ? [pagination] : []);
    } else {
      paginateSet(pagination);
    }
  };

  const onUpdate = async (updateState: any, data: any) => {
    const { filters } = filtersData;
    // setState({ ...state, data: { ...state.data, table: { ...state.data.table, body: data } } });
    const body = {
      slug: updateState,
      data: {},
      filters: filters ?? [],
      pagination: {}
    };
    if (pagination && pagination.length > 0) body.pagination = pagination[0];

    if (updateState === 'update') {
      const itemID = parseInt(data.slug.split('-')[1]);
      const itemArray: any[] = [];
      data.data.forEach((update:any) => { itemArray.push(update); });
      Object.assign(body.data, { tab: settingType, id: itemID, item: itemArray });

      const response = await settingsServices.crudGeneralSettings(body);
      if (response?.success) {
        pageData();
        // const newCells = response.message.data.settings_item_row;
        // const oldTableBody = state.data.table.body;
        // const newTableBody = oldTableBody.map((row:any) => {
        //   if (row.row_slug === newCells.row_slug) {
        //     return newCells;
        //   } else if (row.row_slug === 'settings-0') {
        //     return newCells;
        //   }
        //   return row;
        // });
        // setState({ ...state, data: { ...state.data, table: { ...state.data.table, body: newTableBody } } });
        setOpenAlert(true);
        setAlertMessage({
          message: response.message.data.message,
          severity: 'success'
        });
      }
    }

    if (updateState === 'delete') {
      const id = parseInt(data.split('-')[1]);
      if (id === 0) {
        pageData();
        return;
      }
      Object.assign(body.data, { tab: settingType, id });

      const response = await settingsServices.crudGeneralSettings(body);
      if (response?.success) {
        pageData();
        setOpenAlert(true);
        setAlertMessage({
          message: response.message.data.message,
          severity: 'success'
        });
      }
    }
  };

  const createItem = async () => {
    const { filters } = filtersData;
    const body = {
      slug: 'create',
      data: { tab: settingType },
      filters: filters ?? [],
      pagination: {}
    };
    if (pagination && pagination.length > 0) body.pagination = pagination[0];

    const response = await settingsServices.crudGeneralSettings(body);
    if (response?.success) {
      const newRow = response.message.data.new_row;
      const currentTableBody = state.data.table.body;
      const foundItem = currentTableBody.find((row:any) => (row.row_slug.includes('-0')));
      if (!foundItem) {
        setState({ ...state, data: { ...state.data, table: { ...state.data.table, body: [newRow, ...state.data.table.body] } } });
      }
    }
  };

  return (
    <>
      { loading
        ? <BasicComponent.Loader size={60} message="Loading data..." />
        : <>
          {state.data.buttons.map(({ action, name, has_permissions }: any) => {
            if (action === 'Create') { return <Button className="btn small jstart" variant="contained" color="primary" disabled={!has_permissions} onClick={createItem}>{name}</Button>; }
          })}
          <BasicComponent.DynamicSettingsTable section_type={0} tableName="table settings-table col2-span" {...state} onUpdate={onUpdate}/>
          <BasicComponent.CustomizedSnackbars message={alertMessage.message} severity={alertMessage.severity} onOpen={openAlert} onClose={(open:any) => setOpenAlert(open)}/>
        </>
      }
    </>
  );
};
