/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import BasicComponents from '../../basicComponents';
import BasicComponent from '../../../components/basicComponents/index';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import { Grid, Button } from '@material-ui/core';
import './styles.css';
import { BarChartComponentProps } from '@components/basicComponents/barChart/barChart.component';
import { PieChartComponentProps } from '@components/basicComponents/pieChart/pieChart.component';
import moment from 'moment';

export interface ChartPair{
  barChartComponent: BarChartComponentProps,
  PieChartComponent: PieChartComponentProps
}

export interface DashboardComponentProps{
  title:string,
  type?: string,
  currencySymbol?: string,
  firstRowChartProps:BarChartComponentProps[],
  secondRowChartProps:ChartPair[],
  summaryTable?:any,
  onUpdate?:any
}

export const DashboardComponent = (props:DashboardComponentProps) => {
  const { title, type, currencySymbol, firstRowChartProps, secondRowChartProps, summaryTable, onUpdate } = props;
  const [filterDate, setFilterDate] = useState({
    slug: 'created_at',
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    title: 'Date'
  });

  const setDate = (val: any, key:any) => {
    if (key === 'start_date') {
      setFilterDate({ ...filterDate, start_date: val });
    } else {
      setFilterDate({ ...filterDate, end_date: val });
    }
  };

  return (
    <>
      <section className={`${type}-dashboard`}>
        <div className="heading underline two gcg20">
          <h1 className="uppercase">{title}</h1>
          <div className="date-container three gcg10">
            <BasicComponent.DatePicker keyName="start_date" onUpdate={setDate} val={Date.now()}/>
            <BasicComponent.DatePicker keyName="end_date" onUpdate={setDate} val={Date.now()}/>
            <Button variant="contained" color="primary" onClick={() => onUpdate(filterDate)}>Filter</Button>
          </div>
        </div>
      </section>
      <section className={`${type}-dashboard`}>
        <div className="card">
          <div className="dashboard-container">
            <div className="first-row">
              {
                firstRowChartProps.map((barChart:BarChartComponentProps, i) => (
                  <BasicComponents.BarChartComponent
                    key={i}
                    data={barChart.data}
                    currencySymbol={currencySymbol}
                    showLegend={barChart.showLegend}
                    showYAxisLabel={barChart.showYAxisLabel}
                    showTickLabel={barChart.showTickLabel}
                    width={barChart.width}
                    height={barChart.height}
                  />
                ))
              }
            </div>
            <Grid xs={12} item>
              <hr className={'horizontalRule'}/>
            </Grid>
            <div className="second-row" >
              {
                secondRowChartProps.map((chartPair:ChartPair, i:number) => (
                  <React.Fragment key={i}>
                    <div className="chart-item">
                      <BasicComponents.PieChartComponent
                        data={chartPair.PieChartComponent.data}
                        heading={chartPair.PieChartComponent.heading}
                        showLegend={chartPair.PieChartComponent.showLegend}
                        width={chartPair.PieChartComponent.width}
                        height={chartPair.PieChartComponent.height}
                      />
                    </div>
                    {/* </Grid> */}
                    {/* <Divider orientation={'vertical'} flexItem /> */}
                    <div className="chart-item">
                      <BasicComponents.SimpleBarChartComponent
                        data={chartPair.barChartComponent.data}
                        heading={chartPair.barChartComponent.heading}
                        showLegend={chartPair.barChartComponent.showLegend}
                        showYAxisLabel={chartPair.barChartComponent.showYAxisLabel}
                        showTickLabel={chartPair.barChartComponent.showTickLabel}
                        width={chartPair.barChartComponent.width}
                        height={chartPair.barChartComponent.height}
                      />
                    </div >
                  </React.Fragment>
                ))
              }
            </div>

            <Grid xs={12} item>
              <hr className={'horizontalRule'}/>
            </Grid>
            { summaryTable &&
              <BasicComponent.DashboardTable
                pagination={{ rows_per_page: 10, page: 0, type: 0 }}
                section_type={0}
                tableName={`table ${type}`}
                colSpan={summaryTable.header_row.cells.length + 2}
                data={summaryTable}
                onUpdate={onUpdate}
              />
            }
          </div>
        </div>
      </section>
    </>
  );
};
