/** Currently this table is a prop lifter.
 * This needs to be reworked to save to context API.
 * Props data gets send from children through this parent and then to companyDashboard.tsx
*/
import React, { useEffect, useState } from 'react';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import { useTableContext } from '../../../context/tableProvider';
import EnhancedComponent from '../../../components/enhancedComponents';
// import BasicComponent from '../../../components/basicComponents/index';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  IconButton
} from '@material-ui/core';
import CustomIcons from '../../basicComponents/customIcons/customIcons.component';
import '.././style.css';

import Row from './tableRow.component';
import { Close } from '@material-ui/icons';

interface Props {
  tableName: string,
  slug?: string,
  colSpan?: number,
  data: any,
  onUpdate?: any,
  section_type: number,
  pagination: any
}

export const DashboardTable = ({ tableName, slug, colSpan, data, onUpdate, section_type }: Props) => {
  const { paginate } = useSearchAndFilterContext();
  const { filterChecks, filterCheckSet } = useTableContext();
  const { header_row, body, footer_row, total_results, pagination } = data;

  const [open, setOpen] = useState(false);
  const [currentTarget, setCurrentTarget] = useState();
  const [valueData, setValueData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setPage(pagination?.page ?? 0);
    setRowsPerPage(pagination?.rows_per_page ?? 10);
  }, [pagination]);
  /** Search and filter functionality
   * Start
  */
  const openState = () => {
    setOpen(false);
  };

  const searchAndFilter = ({ currentTarget }: any, value: any) => {
    const { category_name } = value;
    if (category_name) {
      setOpen(true);
      setCurrentTarget(currentTarget);
      setValueData(value);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    paginate(rowsPerPage, newPage, section_type);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    paginate(parseInt(event.target.value), 0, section_type);
  };
  /** Search and filter functionality
   * end
  */

  const handleFilterCheck = () => {
    if (filterChecks) {
      onUpdate({ slug: 'reload' });
    }
    filterCheckSet(!filterChecks);
  };

  return (
    <>
      <TableContainer className={tableName}>
        <Table stickyHeader>
          <TableHead className="thead">
            <TableRow>
              { tableName.includes('company')
                ? <TableCell
                  align="center"
                  className="filter-cell">
                  <IconButton aria-label="filter" className="filter-btn" disableRipple onClick={handleFilterCheck}>{!filterChecks ? <CustomIcons.FilterIcon fontSize="small" /> : <div className="filter-icon"><p>FILTER</p><div className="close-icon"><p><Close /></p></div></div>}</IconButton></TableCell>
                : null }
              {header_row.cells.map((value:any, i:number) => (
                <TableCell key={`theadCell-${i}`} align={value.align} onClick={(event) => searchAndFilter(event, value)}>{value.text}</TableCell>
              ))}
              <TableCell align="right"></TableCell>
              <EnhancedComponent.SearchAndFilter open={open} currentTarget={currentTarget} data={valueData} openState={openState}/>
            </TableRow>
          </TableHead>
          <TableBody className="tbody">
            {body.map((row:any, i:number) => (
              <Row key={`tbody-${row.row_slug ?? i}`} {...row} colSpan={colSpan} onUpdate={onUpdate}/>
            ))}
          </TableBody>
          <TableFooter className="tfoot">
            <TableRow>
              <TableCell align="right"></TableCell>
              {footer_row.cells.map((value:any, i:number) => (
                <TableCell key={`tfootCell-${i}`} align={value.align}>{value.text}</TableCell>
              ))}
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableFooter>
        </Table>
        { tableName.includes('company')
          ? <TablePagination
            component="div"
            count={total_results}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          : null
        }
      </TableContainer>
    </>
  );
};
