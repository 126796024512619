import React, { useEffect, useState } from 'react';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import EnhancedComponent from '../../../components/enhancedComponents';
import BasicComponent from '../../../components/basicComponents/index';
import { settingsServices } from '../../../services/api/helpers/settingsServices';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  IconButton,
  // TextField,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Button
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';
// import ClearIcon from '@material-ui/icons/Clear';
import '.././style.css';

interface Table {
  tableName: string,
  data: any,
  filters?: Array<any>,
  total_results?: number,
  slug?: string,
  changeView?: Function,
  onUpdate?: any
}

interface UpdateTableRow {
  slug: string,
  end_point?: string,
  data: Array<any>
}

const Row = (row:any) => {
  const { cells, row_slug, redirect, buttons, changeView, onUpdate, sectionSlug } = row;
  const [tableState, setTableState] = useState(!!row_slug.includes('-0'));
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [updateTableRow, setUpdateTableRow] = useState<UpdateTableRow>({
    slug: '',
    data: []
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: ''
  });

  const redirected = () => {
    changeView(row_slug);
  };

  const editContent = () => {
    setTableState(true);
  };

  const copyContent = () => {
    const itemId = parseInt(row_slug.split('-')[1]);
    onUpdate('copy', itemId);
  };

  const deleteContent = () => {
    onUpdate('delete', row_slug);
  };

  const saveContent = async () => {
    const { slug, data } = updateTableRow;
    const catalogueCode = data.find((input: any) => (input.key.includes('catalogue_code')));
    const inputs = document.querySelectorAll('input');
    const selects = document.querySelectorAll('select');
    let validated: boolean = true;

    inputs.forEach((input: any) => {
      if (input.validity.patternMismatch) {
        validated = false;
        input.focus();
        input.blur();
        return false;
      }
      if (input.required && !input.value) {
        validated = false;
        input.focus();
        input.blur();
        return false;
      }
    });

    selects.forEach((select: any) => {
      if (select.required && select.options.selectedIndex === 0) {
        validated = false;
        select.focus();
        return false;
      }
    });

    let value = null;
    data.forEach(d => {
      if (d.key === 'name') {
        value = d.value;
      }
    });
    if (validated) {
      if (value) {
        const duplicateCheck = {
          slug: catalogueCode ? catalogueCode.key : sectionSlug,
          id: slug.split('-').pop(),
          value: catalogueCode ? catalogueCode.value : value
        };
        const validator = await settingsServices.DuplicateValidator(
          duplicateCheck
        );
        if (!validator?.success) {
          setOpenAlert(true);
          setAlertMessage({
            message: validator?.message.data.message,
            severity: 'error'
          });
          return false;
        }
      }
      setTableState(false);
      onUpdate('update', updateTableRow);
    } else {
      setOpenAlert(true);
      setAlertMessage({
        message: 'Make sure all required fields are filled in.',
        severity: 'error'
      });
    }
  };

  const updateCell = (value:string, cellName:string) => {
    /** Update table cell.
     * push data to update State called 'updateTableRow' for global save. */
    let newDataObject;
    cells.forEach((cell:any) => {
      if (cell.name === cellName) {
        newDataObject = { key: cellName, value: value };
      }
    });

    const currentState = updateTableRow.data;
    const foundItem = currentState.find((keyValue) => (keyValue.key === cellName));
    if (foundItem) {
      // Replace the found item
      const rightIndex = currentState.indexOf(foundItem);
      currentState[rightIndex].value = value;
    } else {
      // Create new item
      setUpdateTableRow({
        ...updateTableRow,
        slug: row_slug,
        end_point: row_slug,
        data: [...updateTableRow.data, newDataObject]
      });
    }
  };

  const ActiveState = (state: any) => {
    switch (state.state) {
    case true:
      return <CheckIcon fontSize="small" className="correct"/>;

    default:
      // return <ClearIcon fontSize="small" className="in-correct"/>;
      return null;
    }
  };

  const inputType = (input:any, i:number) => {
    const inputType = input.type;
    switch (inputType) {
    case 'empty':
      return (
        <div/>
      );
    case 'text':
      return (
        input.text
      );
    case 'text_field':
      return (
        <BasicComponent.CustomTextField {...input} updateCell={updateCell}/>
      );
    case 'checkbox':
      return (
        <BasicComponent.CheckBox keyName={input.name} label={input.label} value={input.value} onBlur={updateCell} />
      );
    case 'dropdown':
      return (
        <BasicComponent.Dropdown keyName={input.name} label={input.label} val={input.dropdown.active} options={input.dropdown.options} validation={input.validation} onBlur={updateCell}/>
      );
    case 'text_area':
      return (
        <FormControl key={`text-area-${i}`} className="col3-span">
          <InputLabel shrink htmlFor="label">{input.label}</InputLabel>
          <TextareaAutosize aria-label={input.label} rowsMin={3} placeholder="Comment" />
        </FormControl>
      );

    default:
      break;
    }
  };

  return (
    <>
      <TableRow>
        {cells.map((value:any, i:number) => (
          {
            ...redirect
              ? <TableCell key={`tbodyCell-${i}`} align={value.align} onClick={redirected} className="click">
                {value.type !== 'icon'
                  ? value.text
                  : <ActiveState state={value.icon}/>
                }
              </TableCell>
              : {
                ...!tableState
                  ? value.type !== 'checkbox'
                    ? <TableCell key={`tbodyCell-${i}`} align={value.align}>{value.text}</TableCell>
                    : <TableCell key={`tbodyCell-${i}`} align={value.align}><ActiveState state={value.value !== 0}/></TableCell>
                  : <TableCell key={`tbodyCell-${i}`} align={value.align}>{inputType(value, i)}</TableCell>
              }
          }
        ))}
        { buttons &&
          <TableCell key={row.id} className="table-btns">
            <div className="buttons">
              { buttons.map(({ action, has_permissions }: any) => {
                if (action === 'Edit') {
                  return (
                    {
                      ...!tableState
                        ? <IconButton aria-label="edit" className="edit-btn" disabled={!has_permissions} onClick={editContent}><EditIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                        : <IconButton aria-label="edit" className="edit-btn" disabled={!has_permissions} onClick={saveContent}><SaveIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                    }
                  );
                } else if (action === 'Copy') {
                  return <IconButton aria-label="copy" className="copy-btn" disabled={!has_permissions} onClick={copyContent}><FileCopyIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>;
                } else if (action === 'Delete') {
                  return (
                    <>
                      <IconButton aria-label="delete" className="delete-btn" disabled={!has_permissions} onClick={() => setConfirmOpen(true)}><DeleteIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                      <BasicComponent.ConfirmDialog confirm={true} open={confirmOpen} setOpen={setConfirmOpen} onConfirm={deleteContent} >
                        <p>Are you sure you want to delete this item?</p>
                      </BasicComponent.ConfirmDialog>
                    </>
                  );
                }
                return null;
              })}
            </div>
          </TableCell>
        }
        <BasicComponent.CustomizedSnackbars message={alertMessage.message} severity={alertMessage.severity} onOpen={openAlert} onClose={(open:any) => setOpenAlert(open)}/>
      </TableRow>
    </>
  );
};

export const DynamicSettingsTable = (props:any) => {
  const { paginate } = useSearchAndFilterContext();
  const { tableName, filters, total_results, changeView, onUpdate, section_type, pagination } = props;
  const { slug } = props.data;
  const { header_row, body, buttons } = props.data.table;

  const [open, setOpen] = useState(false);
  const [currentTarget, setCurrentTarget] = useState();
  const [valueData, setValueData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setPage(pagination?.page ?? 0);
    setRowsPerPage(pagination?.rows_per_page ?? 10);
  }, [pagination]);
  /** Search and filter functionality
   * Start
  */
  const openState = () => {
    setOpen(false);
  };

  const searchAndFilter = ({ currentTarget }: any, value: any) => {
    const { category_name } = value;
    if (category_name) {
      setOpen(true);
      setCurrentTarget(currentTarget);
      setValueData(value);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    paginate(rowsPerPage, newPage, section_type);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    paginate(parseInt(event.target.value), 0, section_type);
  };
  /** Search and filter functionality
   * end
  */

  const createTableItem = async () => {
    const endpoint = slug.split('_')[0];
    const body = {
      slug: 'create',
      end_point: endpoint,
      data: {
        page: 'contacts'
      }
    };
    onUpdate('create', body);
  };

  return (
    <>
      <TableContainer className={tableName}>
        {
          !!filters && filters.length > 0 && <BasicComponent.FilterResults filters={filters}/>
        }
        <Table stickyHeader>
          <TableHead className="thead">
            <TableRow>
              {header_row.cells.map((value:any, i:number) => (
                <TableCell key={`theadCell-${i}`} align={value.align} onClick={(event:any) => searchAndFilter(event, value)}>{value.text}</TableCell>
              ))}
              <EnhancedComponent.SearchAndFilter open={open} currentTarget={currentTarget} data={valueData} openState={openState}/>
            </TableRow>
          </TableHead>
          <TableBody className="tbody">
            {body.map((row:any) => (
              <Row key={row.row_slug} {...row} buttons={buttons} changeView={changeView} onUpdate={onUpdate} sectionSlug={slug}/>
            ))}
          </TableBody>
        </Table>
        { buttons && buttons.map(({ action, slug, name, has_permissions }: any) => {
          if (action === 'Create') { return <Button key={slug} className="btn small jstart" variant="contained" color="primary" disabled={!has_permissions} onClick={createTableItem}>{name}</Button>; }
        })}
        <TablePagination
          component="div"
          count={total_results}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};
