import React from 'react';
import { ListItemIcon } from '@material-ui/core';
import './style.sass';

export const NavIcon = ({ icon_text, isActive }: any) => (
  <ListItemIcon >
    <div className={`custom-nav-icon ${isActive ? 'active' : 'inactive'}`}>
      <p>{icon_text}</p>
    </div>
  </ListItemIcon>
);
