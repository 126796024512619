import React, { useState, useEffect } from 'react';
import { useTableContext } from '../../../context/tableProvider';
import BasicComponent from '../../../components/basicComponents/index';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Checkbox
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import '.././style.css';

interface UpdateTableRow {
  slug: string,
  data: Array<any>
}

const Row = (row:any) => {
  const { cells, collapsed_rows_included, row_slug, colSpan, onUpdate, unchecked } = row;
  const { filterChecks } = useTableContext();
  const [tableState] = useState(true);
  const [expanded, setExpandable] = useState(false);
  const [updateTableRow] = useState<UpdateTableRow>({
    slug: '',
    data: []
  });

  useEffect(() => {
    if (updateTableRow.data.length > 0) {
      onUpdate(updateTableRow);
    }
  }, [updateTableRow]);

  const ExpandTable = () => {
    setExpandable(!expanded);
  };

  const updateCell = (value:any, cellName:any) => {
    /** Update table cell.
     * push data to update State called 'updateTableRow' for global save. */
    /** Parent cell update */
    const body = {
      slug: 'toggle_checkbox',
      data: {
        name: cellName.split('-')[0],
        id: parseInt(cellName.split('-')[1]),
        status: value ? 0 : 1
      }
    };

    onUpdate(body);
  };

  const inputType = (input:any, i:number, id?:any) => {
    /** Component render switch.
     * Whenever you edit a cell
     */
    const inputType = input.type;
    switch (inputType) {
    case 'empty':
      return (
        <div/>
      );
    case 'text':
      return (
        <>
          <div title={input.value ? input.value : null}>{input.text}</div>
        </>
      );
    case 'text_field':
      return (
        <BasicComponent.CustomTextField {...input} updateCell={updateCell}/>
      );
    case 'text_field_button':
      return (
        <BasicComponent.TextFieldButton {...input} id={id} />
      );
    case 'checkbox':
      return (
        <Checkbox
          key={`${row_slug}-checkbox-${i}`}
          defaultChecked={!!input.value}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          onChange={(event) => updateCell(event.target.checked, input.name)}
        />
      );
    case 'dropdown':
      return (
        <BasicComponent.Dropdown keyName={input.name} label={input.label} val={input.dropdown.default} options={input.dropdown.options} validation={input.validation} onBlur={updateCell}/>
      );
    case 'date':
      return (
        <BasicComponent.DatePicker keyName={input.name} label={input.label} val={input.text} futureDates={input.future_dates} pastDates={input.past_dates} onUpdate={updateCell}/>
      );
    case 'text_area':
      return (
        <FormControl key={`${row_slug}-text-area-${i}`} className="col3-span">
          <InputLabel shrink htmlFor="label">{input.label}</InputLabel>
          <TextareaAutosize aria-label={input.label} rowsMin={3} placeholder="Comment" />
        </FormControl>
      );

    default:
      break;
    }
  };

  return (
    <>
      <TableRow className={`collapse-parent ${!expanded ? '' : 'opened'} ${unchecked ? 'unchecked' : 'checked'}`}>
        { collapsed_rows_included && filterChecks
          ? (
            <TableCell key={`${row_slug}-checkbox`} align="center" className="filter-cell">
              <BasicComponent.CheckBoxClicked className="divions-filter" keyName={row_slug} value={!unchecked} onClick={updateCell} />
            </TableCell>
          )
          : <TableCell key={`${row_slug}-empty`} align="left" className="filter-cell"></TableCell>
        }
        {cells.map((value:any, i:number) =>
          !tableState
            ? <TableCell key={`${row_slug}-tbodyCell-${i}`} align={value.align}> { expanded ? <InputLabel>{value.label}</InputLabel> : null } {value.text}</TableCell>
            : <TableCell key={`${row_slug}-tbodyCell-${i}`} align={value.align}>{inputType(value, i)}</TableCell>
        )}
        { collapsed_rows_included &&
          <TableCell key={`${row_slug}-buttons-${row.id}`} className="table-btns">
            <div className="buttons">
              <IconButton aria-label="expand row" className="dropdown-btn" size="small" onClick={ExpandTable}>{expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
            </div>
          </TableCell>
        }
      </TableRow>
      { collapsed_rows_included &&
        <TableRow className={`collapse-child ${!expanded ? '' : 'opened-container'}`}>
          <TableCell className="collapsed" colSpan={colSpan}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Table>
                <TableBody className={filterChecks ? 'checked-state' : ''}>
                  {collapsed_rows_included.map((collapsedRows:any, i:number) => (
                    <TableRow key={collapsedRows.row_slug} className={collapsedRows.unchecked ? 'unchecked' : 'checked'}>
                      <TableCell className="job-filter filter-cell" key={`${collapsedRows.row_slug}-checkbox`} align="right">{ filterChecks ? <BasicComponent.CheckBoxClicked keyName={collapsedRows.row_slug} value={!collapsedRows.unchecked} onClick={updateCell} /> : null}</TableCell>
                      {!tableState
                        ? collapsedRows?.cells.map((value:any, cellIndex: number) => (
                          <TableCell key={`${collapsedRows.row_slug}-${value.name ?? cellIndex}`} align={value.align}>{value.text}</TableCell>
                        ))
                        : collapsedRows?.cells.map((value:any, cellIndex: number) => (
                          <TableCell key={`${collapsedRows.row_slug}-${value.name ?? cellIndex}`} align={value.align}>{inputType(value, i, collapsedRows.row_slug)}</TableCell>
                        ))
                      }
                      <TableCell key={`${collapsedRows.row_slug}-empty`} align="right"><div></div></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      }
    </>
  );
};

export default Row;
