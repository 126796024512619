import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// import BasicComponent from '../../components/basicComponents/index';
import EnhancedComponent from '../../components/enhancedComponents';
import { userServices } from 'src/services/api/helpers/userServices';
import './home.css';

const Home = () => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const userNavigation = await userServices.getUserNavigation4(null);
      if (!userNavigation || !userNavigation.success) {
        history.push('/login');
      }
    })();
  });

  return (
    <>
      <EnhancedComponent.SideDrawer/>
      <div className="container">
        <section className="hero">
          <div className="content one grg20">
            <h1 className='uppercase center'>Welcome to SkyBuild</h1>
            <p className="center">Construction software that simplifies the process of managing and reporting on-site project costs.</p>
          </div>
          <div className="overlay"></div>
          <div className="background"><img src="img/SkyBuild_Landing.jpg" alt="hero"/></div>
        </section>
      </div>
    </>
  );
};

export default Home;
