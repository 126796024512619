import React, { useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../../context/globalState';
import { useNavFilterContext } from '../../../context/navFilterProvider';
import { useNotificationsContext } from '../../../context/notificationsProvider';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import {
  Drawer, List, CssBaseline, ListItem,
  ListItemIcon, ListItemText
} from '@material-ui/core';

import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { userServices } from '../../../services/api/helpers/userServices';
// import { globalLocalStorage } from '../../../services/storage/localstorage';
import { useHistory, useLocation } from 'react-router-dom';
import { globalLocalStorage } from 'src/services/storage/localstorage';
import { ListItemSubMenu } from './listItemSubMenu';
import BasicComponent from '../../../components/basicComponents/index';
import SessionExpireDialog from '../../../components/basicComponents/confirmDialog/sessionExpireDialog.component';
import { useIdleTimer } from 'react-idle-timer';
import './styles.css';

// import NAVFILTER_DATA from '../../basicComponents/navFilter/navFilter.data';

// export enum RouteMenu {
//   HOME = '/home',
//   PROFILE = '/profile',
//   MATERIALANDSERVICE = '/materialAndService',
//   COMPANYDASHBOARD = '/companyDashboard',
//   COSTING = '/costing',
//   SETTINGS = '/settings'
// };

const drawerWidth = 'auto';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: drawerWidth,
      backgroundColor: '#001C38',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      backgroundColor: '#001C38',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1
      }
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    }
  })
);

interface APIMenuItem{
  isActive: boolean,
  inactive_icon: string,
  active_icon: string,
  title: string,
  icon_text: string,
  slug:string,
  children: any
}

interface MenuItem{
  inactive_icon: string,
  active_icon: string,
  isActive: boolean,
  title: string,
  slug: string,
  children: any,
  onClick: any,
  rightIcon?: string,
}

export const SideDrawer = (props?:any) => {
  const { user, updateUser } = useContext(GlobalContext);
  const { filtering, companyDashboardPermissions, companyDashboardPermissionsSet, projectDashboardPermissions, projectDashboardPermissionsSet, filteringSet, navIsActiveSet, navFilter, navFilterOptions, navFilterOptionsSet, updateNavFilterOptions } = useNavFilterContext();
  const { notifyData, notifications, paginationData } = useNotificationsContext();
  const { clearFilters, paginateSet } = useSearchAndFilterContext();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [activeMenuIndex, setActiveMenuIndex] = useState(-1);
  const [navigation, setNavigation] = useState<APIMenuItem[]>(user.navigation);
  const [menuItemState, setMenuItemState] = useState<MenuItem[]>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [sessionExpire, setSessionExpire] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!user.api_access_token) {
      return getUser();
    }
    if (!user.navigation || user.navigation.length < 1) {
      return getUser();
    }
    if (!userLoaded) {
      return getUser();
    }
    getUpdateMenuItems();
  }, []);

  useEffect(() => {
    setMenuItemState(user.navigation);
  }, [user]);

  useEffect(() => {
    if (filtering) {
      (async function () {
        const userInfo = await userServices.getUserNavigation4(navFilter);
        if (userInfo?.success) {
          const { can_view_company_dashboard, can_view_project_dashboards, navigation, filter_options } = userInfo?.message.data;
          setNavigation(navigation);
          updateUser({ ...user, navigation: navigation });
          updateNavFilterOptions(filter_options);
          companyDashboardPermissionsSet(can_view_company_dashboard);
          projectDashboardPermissionsSet(can_view_project_dashboards);
          filteringSet(false);
        }
      })();
    }
  }, [navFilter]);

  useEffect(() => {
    const interval = setInterval(() => {
      getNotificatons();
    }, 1000 * 30);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (sessionExpire) {
      const interval = setInterval(() => {
        userLogout();
      }, 1000 * 30);
      return () => clearInterval(interval);
    }
  }, [sessionExpire]);

  useEffect(() => {
    if (props.reload) {
      getUserOnReload(null);
      const { onReload } = props;
      onReload();
    }
  }, [props.reload]);

  useEffect(() => {
    if (paginationData) {
      getNotificatons();
    }
  }, [paginationData]);

  /** Check user idle state */
  const handleOnIdle = (event: any) => {
    setSessionExpire(true);
  };

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500
  });
  /** End - Check user idle state */

  const getUser = () => {
    (async () => {
      if (!user.navigation || user.navigation.length < 1 || !userLoaded) {
        const userInfo = await userServices.getUserNavigation4(null);
        if (!userInfo || !userInfo.success) {
          globalLocalStorage.removeItem('api_access_token');
          history.push('/login');
          return;
        }
        const api_access_token = globalLocalStorage.getItem('api_access_token');
        const { can_view_company_dashboard, can_view_project_dashboards, navigation, filter_options } = userInfo?.message.data;
        setUserLoaded(true);
        setNavigation(navigation);
        navFilterOptionsSet(filter_options);
        companyDashboardPermissionsSet(can_view_company_dashboard);
        projectDashboardPermissionsSet(can_view_project_dashboards);
        updateUser({ ...userInfo?.message.data, api_access_token });
      }
    })();
  };

  const getUserOnReload = (body: any) => {
    (async () => {
      const userInfo = await userServices.getUserNavigation4(body);
      const api_access_token = globalLocalStorage.getItem('api_access_token');
      const { can_view_company_dashboard, can_view_project_dashboards, navigation, filter_options } = userInfo?.message.data;
      setNavigation(navigation);
      navFilterOptionsSet(filter_options);
      companyDashboardPermissionsSet(can_view_company_dashboard);
      projectDashboardPermissionsSet(can_view_project_dashboards);
      updateUser({ ...userInfo?.message.data, api_access_token });
      updateMenuItems('', '', -1000, navigation);
    })();
  };

  const getNotificatons = () => {
    (async () => {
      const body = {
        data: {
          pagination: paginationData.pagination
        }
      };
      const response = await userServices.getNotifications(body);
      if (response?.success) {
        const { total_results, unread_state, notification_group } = response.message.data;
        notifications(total_results, unread_state, notification_group);
      }
    })();
  };

  const toggleDrawer = () => {
    setDrawerIsOpen((state:boolean) => !state);
  };

  const userLogout = async () => {
    await userServices.logout();
    globalLocalStorage.removeItem('api_access_token');
    history.push('/login');
  };

  const getUpdateMenuItems = (currentSlug = '', currentTitle = '', navIndex = -1000): void => {
    if (navigation.length < 1) {
      return;
    }
    if (activeMenuIndex === navIndex) {
      return;
    }
    const theMenuItems = navigation.map((nav:APIMenuItem, index:number) => ({
      active_icon: nav.active_icon,
      inactive_icon: nav.inactive_icon,
      title: nav.title,
      icon_text: nav.icon_text,
      slug: nav.slug,
      children: nav.children,
      isActive: index === navIndex || currentSlug === nav.slug,
      onClick: () => {
        switch (nav.slug) {
        case 'user_profile':
          history.push('/pageUnderContruction');
          break;

        default:
          history.push(`/${nav.slug}`);
          break;
        }
      }
    }));
    setActiveMenuIndex(navIndex);
    setMenuItemState(theMenuItems);
  };

  const updateMenuItems = (currentSlug: string, currentTitle: string, navIndex: number, navigation: any): void => {
    const theMenuItems = navigation.map((nav:APIMenuItem, index:number) => ({
      active_icon: nav.active_icon,
      inactive_icon: nav.inactive_icon,
      title: nav.title,
      icon_text: nav.icon_text,
      slug: nav.slug,
      children: nav.children,
      isActive: index === navIndex || currentSlug === nav.slug,
      onClick: () => {
        switch (nav.slug) {
        case 'user_profile':
          history.push('/profile');
          break;

        default:
          history.push(`/${nav.slug}`);
          break;
        }
        // getUpdateMenuItems(nav.slug, nav.title, index);
      }
    }));
    setActiveMenuIndex(navIndex);
    setMenuItemState(theMenuItems);
  };

  const redirect = (page: string) => {
    history.push(`/${page}`);
    clearFilters();
    paginateSet([]);
    toggleDrawer();
    navIsActiveSet(page, '');
  };

  const RenderMenuItems = (menuItem:any) => {
    const menuItemChildren = menuItem.children.length;
    switch (menuItemChildren > 0) {
    case true:
      return <ListItemSubMenu key={`menu-item_${menuItem.slug}`} menuItem={menuItem} projectDashboardPermissions={projectDashboardPermissions}/>;

    default:
      return <div key={`menu-item_${menuItem.slug}`} className='listItem'>
        <ListItem button alignItems='center' onClick={() => redirect(menuItem.slug)}>
          <ListItemIcon >
            <div className={`leftIcon ${menuItem.isActive ? 'active' : 'inactive'}`}>
              <img
                src={ menuItem.isActive ? menuItem.active_icon : menuItem.inactive_icon } alt='leftIcon'
                width='25px' height='25px'
              />
            </div>
          </ListItemIcon>
          <ListItemText primary={menuItem.title} />
        </ListItem>
        {
          menuItem.slug.includes('profile') &&
          <BasicComponent.Notifications {...notifyData} />
        }
      </div>;
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant='permanent'
        onMouseEnter={() => setDrawerIsOpen(true)}
        onMouseLeave={() => setDrawerIsOpen(false)}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerIsOpen,
          [classes.drawerClose]: !drawerIsOpen
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerIsOpen,
            [classes.drawerClose]: !drawerIsOpen
          })
        }}
      >
        <div className={classes.toolbar}>
          <div className='navlogo' onClick={toggleDrawer}>
            {
              drawerIsOpen
                ? <img src='img/navAssets/SkyBuild-Logo-white-text.png' alt='sky-build-logo' width='215px'height='25px'/>
                : <img src='img/SB-logo-circle.png' alt='logo-sm' width='25px'height='25px'/>
            }
          </div>
        </div>
        <List disablePadding>
          {
            menuItemState?.map((menuItem, index) => {
              if (index < 1) {
                return RenderMenuItems(menuItem);
              }
              // if (index === 1) {
              // return <div className="listItem">
              //   <ListItem button alignItems='center' onClick={() => history.push('/pageUnderContruction')}>
              //     <ListItemIcon >
              //       <div className='leftIcon'>
              //         <img
              //           src={`img/navAssets/${location.pathname === '/companyDashboard' ? 'costing-active.png' : 'costing-inactive.png'}`} alt='leftIcon'
              //           width='25px' height='25px'
              //         />
              //       </div>
              //     </ListItemIcon>
              //     <ListItemText className="btn" primary="Costing"/>
              //   </ListItem>
              // </div>;
              // }
              if (index === 2) {
                return <div key={`menu-item_${menuItem.slug}`} className="listItem">
                  <ListItem button alignItems='center' disabled={!companyDashboardPermissions} onClick={() => redirect('companyDashboard')}>
                    <ListItemIcon >
                      <div className={`leftIcon ${menuItem.isActive ? 'active' : 'inactive'}`}>
                        <img
                          src={`img/navAssets/${location.pathname === '/companyDashboard' ? 'dashboard-active.png' : 'dashboard-inactive.png'}`} alt='leftIcon'
                          width='25px' height='25px'
                        />
                      </div>
                    </ListItemIcon>
                    <ListItemText className="company-dashboard" primary="Company Dashboard"/>
                  </ListItem>
                  <BasicComponent.NavFilter {...navFilterOptions}/>
                </div>;
              }
            })
          }
          {
            menuItemState?.map((menuItem, index) => {
              if (index > 0) {
                return RenderMenuItems(menuItem);
              }
            })
          }
          <ListItem button alignItems='center' onClick={() => setConfirmOpen(true)}>
            <ListItemIcon >
              <div className='leftIcon'>
                <img
                  src='img/navAssets/shutdown.png' alt='leftIcon'
                  width='25px' height='25px'
                />
              </div>
            </ListItemIcon>
            <ListItemText className="logout-btn" primary="Log out"/>
          </ListItem>
          <BasicComponent.ConfirmDialog confirm={!sessionExpire} open={confirmOpen} setOpen={setConfirmOpen} onConfirm={userLogout} >
            <p>You are about to log out, are you sure?</p>
          </BasicComponent.ConfirmDialog>
          <SessionExpireDialog open={sessionExpire} setOpen={setSessionExpire} onConfirm={() => setSessionExpire(false)} >
            <div className="message one grg10 jcenter">
              <h1 className="bold center">Your Session Will Expire!</h1>
              <p className="center">You will be logged out in 30 seconds.<br />If you wish to remain logged in click Continue below</p>
            </div>
          </SessionExpireDialog>
        </List>
      </Drawer>
    </div>
  );
};
