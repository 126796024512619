import React, { useEffect, useState } from 'react';
import BasicComponent from '../../basicComponents/index';
import {
  Button
} from '@material-ui/core';
import { settingsServices } from '../../../services/api/helpers/settingsServices';
import { useSearchAndFilterContext } from 'src/context/searchAndFilterProvider';

interface onLoad {
  children?: React.ReactNode;
  projectId: number,
  settingType: string
}

interface PageData {
  projectId: number,
  data: any,
  filters: Array<any>,
  pagination: any,
  total_results: number
}

export const AssetsSettings = (props: onLoad) => {
  const { filtersData, pagination, pageAndFilterStatus, paginateSet, clearFilters } = useSearchAndFilterContext();
  const { projectId, settingType } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: ''
  });
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<PageData>({
    projectId: 1,
    data: [],
    filters: [],
    pagination: {},
    total_results: 0
  });

  useEffect(() => {
    (async function () {
      await pageData();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, []);

  useEffect(() => {
    /** Filtering and Pagination */
    if (pageAndFilterStatus === 'UPDATED') {
      filterAndPagination(
        filtersData.filters && filtersData.filters.length >= 0 ? filtersData : null
      );
    };
    return clearFilters();
  }, [pageAndFilterStatus]);

  const filterAndPagination = async (filtersData:any) => {
    if (filtersData) {
      const { filters } = filtersData;
      const body: {[key: string]: any} = {
        slug: 'read',
        data: {
          tab: settingType
        },
        filters: filters ?? [],
        pagination: {}
      };
      if (pagination && pagination.length > 0) body.pagination = pagination[0];
      const response = await settingsServices.crudGeneralSettings(body);
      if (response?.success) {
        const { filters, total_results, pagination } = response.message;
        const { page_data } = response.message.data;
        setState({
          ...state,
          data: page_data,
          filters,
          pagination,
          total_results
        });
        paginateSet(pagination ? [pagination] : []);
      } else {
        paginateSet(pagination);
      }
    }
  };

  const pageData = async () => {
    const { filters } = filtersData;
    const body: {[key: string]: any} = {
      slug: 'read',
      data: { tab: settingType },
      filters,
      pagination
    };

    const response = await settingsServices.crudGeneralSettings(body);
    if (response?.success) {
      const pageData = response.message.data.page_data;
      const { filters, total_results, pagination } = response.message;
      setState({
        projectId,
        data: pageData,
        filters,
        pagination,
        total_results
      });
      paginateSet(pagination ? [pagination] : []);
    } else {
      paginateSet(pagination);
    }
  };

  const onUpdate = async (updateState: string, data: any, level?: string) => {
    const body = {
      slug: updateState,
      data: {}
    };

    if (updateState === 'create') {
      Object.assign(body.data, { tab: settingType, asset_id: data.data.asset_id });

      const response = await settingsServices.crudGeneralSettings(body);
      if (response?.success) {
        const { body } = state.data.table;
        const { new_row } = response.message.data;
        const newTableBody = body.map((row:any) => {
          if (row.row_slug === new_row.row_slug) {
            row.collapsed_rows_included = new_row.collapsed_rows_included;
            return row;
          }
          return row;
        });

        setState({ ...state, data: { ...state.data, table: { ...state.data.table, body: newTableBody } } });
      }
    }

    if (updateState.includes('update')) {
      const itemID = parseInt(data.slug.split('-').pop());
      body.data = { tab: settingType, id: itemID, ...data.data };
      if (data.data.asset) {
        const duplicateCheck = {
          slug: 'asset',
          id: itemID,
          asset: data.data.asset[0],
          value: data.data.asset[0].value
        };
        const validator = await settingsServices.DuplicateValidator(
          duplicateCheck
        );
        if (!validator?.success) {
          setOpenAlert(true);
          setAlertMessage({
            message: validator?.message.data.message,
            severity: 'error'
          });
          return false;
        }

        if (data.data.item_categories) {
          const itemCategoryNames: any[] = [];
          const itemCategories = data.data.item_categories;
          for (let i = 0; i < itemCategories.length; i++) {
            if (itemCategoryNames.indexOf(itemCategories[i].name) === -1) {
              itemCategoryNames.push(itemCategories[i].name);
            } else {
              setOpenAlert(true);
              setAlertMessage({
                message: 'Another item in this group category already exists with this name',
                severity: 'error'
              });
              return false;
            }
          }
        }
      }
      const response = await settingsServices.crudGeneralSettings(body);
      if (response?.success) {
        const newCells = response.message.data.settings_asset_row;
        const oldTableBody = state.data.table.body;
        const newTableBody = oldTableBody.map((cell:any) => {
          if (cell.row_slug === newCells.row_slug || cell.row_slug.includes('-0')) {
            return newCells;
          }
          return cell;
        });

        setState({ ...state, data: { ...state.data, table: { ...state.data.table, body: newTableBody } } });
      }

      // if (level === 'parent') {
      //   const assetArray: any[] = [];
      //   data.data.forEach((update:any) => { assetArray.push(update); });
      //   Object.assign(body.data, { tab: settingType, id: itemID, asset: assetArray });

      //   const response = await settingsServices.crudGeneralSettings(body);
      //   if (response?.success) {
      //     const newCells = response.message.data.settings_asset_row;
      //     const oldTableBody = state.data.table.body;
      //     const newTableBody = oldTableBody.map((cell:any) => {
      //       if (cell.row_slug === newCells.row_slug || cell.row_slug.includes('-0')) {
      //         return newCells;
      //       }
      //       return cell;
      //     });

      //     setState({ ...state, data: { ...state.data, table: { ...state.data.table, body: newTableBody } } });
      //   }
      // } else if (level === 'child') {
      //   const itemCategoriesArray: any[] = [];
      //   data.data.forEach((update:any) => {
      //     itemCategoriesArray.push(update);
      //   });

      //   Object.assign(body.data, { tab: settingType, id: itemID, item_categories: itemCategoriesArray });

      //   const response = await settingsServices.crudGeneralSettings(body);
      //   if (response?.success) {
      //     const newCells = response.message.data.settings_asset_row;
      //     const oldTableBody = state.data.table.body;
      //     const newTableBody = oldTableBody.map((cell:any) => {
      //       if (cell.row_slug === newCells.row_slug || cell.row_slug.includes('-0')) {
      //         return newCells;
      //       }
      //       return cell;
      //     });
      //     setState({ ...state, data: { ...state.data, table: { ...state.data.table, body: newTableBody } } });
      //   }
      // }
    }

    if (updateState === 'delete') {
      if (level === 'parent') {
        const id = data.data.id;
        if (id === 0) {
          pageData();
        } else {
          Object.assign(body.data, { tab: settingType, id: id });
          const response = await settingsServices.crudGeneralSettings(body);
          if (response?.success) {
            pageData();
          }
        }
      } else if (level === 'child') {
        const id = data.data.category_id;
        if (id === 0) {
          pageData();
        } else {
          Object.assign(body, data);
          const response = await settingsServices.crudGeneralSettings(body);
          if (response?.success) {
            pageData();
          }
        }
      }
    }
  };

  const createItem = async () => {
    const body = {
      slug: 'create',
      data: { tab: settingType }
    };
    const response = await settingsServices.crudGeneralSettings(body);
    if (response?.success) {
      const newRow = response.message.data.new_row;
      setState({ ...state, data: { ...state.data, table: { ...state.data.table, body: [newRow, ...state.data.table.body] } } });
    }
  };

  return (
    <>
      { loading
        ? <BasicComponent.Loader size={60} message="Loading data..." />
        : <>
          {state.data.buttons.map(({ action, name, has_permissions }: any) => {
            if (action === 'Create') { return <Button className="btn small jstart" variant="contained" color="primary" disabled={!has_permissions} onClick={createItem}>{name}</Button>; }
            return false;
          })}
          <BasicComponent.AssetsTable pagination={state.pagination} section_type={0} tableName="table settings-table col2-span" colSpan={3} data={state.data.table} filters={state.filters} total_results={state.total_results} onFilter={filterAndPagination} onUpdate={onUpdate}/>
        </>
      }
      <BasicComponent.CustomizedSnackbars message={alertMessage.message} severity={alertMessage.severity} onOpen={openAlert} onClose={(open:any) => setOpenAlert(open)}/>
    </>
  );
};
