const NAVIGATION_ACTIONS = {
  UPDATE: 'NAVIGATION_UPDATE'
};
const USER_ACTIONS = {
  UPDATE: 'USER_UPDATE'
};

const SETTINGS_ACTIONS = {
  UPDATE: 'SETTINGS_UPDATE'
};

const COMPANY_ACTIONS = {
  UPDATE: 'COMPANY_UPDATE'
};

const PROJECT_ACTIONS = {
  UPDATE: 'PROJECT_UPDATE'
};

export {
  NAVIGATION_ACTIONS,
  USER_ACTIONS,
  SETTINGS_ACTIONS,
  COMPANY_ACTIONS,
  PROJECT_ACTIONS
};
