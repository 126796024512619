import React, { useState, useEffect } from 'react';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import _ from 'lodash';
import { Popover, TextField, Button, InputLabel, InputAdornment } from '@material-ui/core';
import CustomIcons from '../../basicComponents/customIcons/customIcons.component'
import SearchIcon from '@material-ui/icons/Search';
import './styles.css';

interface Props {
  open: any,
  currentTarget: any,
  data: any,
  onSearch?: any,
  openState: any,
}

export const SearchAndFilter = ({ open, currentTarget, data, onSearch, openState }:Props) => {
  const { searchResults, sorting } = useSearchAndFilterContext();
  const { category_name, text } = data || '';
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [searchQuery, setSearchQuery] = useState({});

  useEffect(() => {
    setAnchorEl(currentTarget);
  }, [open]);

  const handleClose = () => {
    setAnchorEl(null);
    openState();
  };

  const searchHandler = ({ target: { value } }: any) => {
    const search = _.debounce(sendQuery, 1000);

    setSearchQuery((prevSearch:any) => {
      if (prevSearch.cancel) {
        prevSearch.cancel();
      }
      return search;
    });

    search(value);
  };

  const sendQuery = (value: any) => {
    searchResults(category_name, value, 'DESC', text);
  };

  return (
    <React.Fragment>
      <Popover
        id="search-filter"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: !data ? 'left' : data.align
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: !data ? 'left' : data.align
        }}
      >
        <div className="card grg20">
          <TextField
            id="filter"
            label="FILTER"
            placeholder="By Keyword"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            autoFocus={true}
            onKeyUp={searchHandler}/>
          <div className="separator"></div>
          <div className="two gcg20">
            <InputLabel className="col2-span">SORT</InputLabel>
            <Button
              aria-label="Sort"
              className="sort-btn jend"
              variant="contained"
              color="primary"
              onClick={() => sorting(category_name, 'ASC', text)}
            ><CustomIcons.AscendingIcon fontSize="small"/></Button>
            <Button
              aria-label="Sort"
              className="sort-btn jstart"
              variant="contained"
              color="primary"
              onClick={() => sorting(category_name, 'DESC', text)}
            ><CustomIcons.DescendingIcon fontSize="small"/></Button>
          </div>
        </div>
      </Popover>
    </React.Fragment>
  );
};
