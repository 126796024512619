// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';
// eslint-disable-next-line no-unused-vars
import { stdResponse } from './restRequests';

const httpResponseCodes = {
  success: 200,
  serverError: 400,
  unauthorised: 401,
  notReachable: 404,
  internalError: 500
};

const handleHttpResponse = (response: AxiosResponse): stdResponse => {
  switch (response.status) {
  case httpResponseCodes.success: {
    return { success: true, message: response.data };
  }
  default:
    return { success: true, message: response.data };
  }
};
const handleHttpResponseError = (error: any) => {
  if (!error.response) {
    return { success: false, message: error };
  }
  switch (error.response.status) {
  case httpResponseCodes.serverError: {
    return { success: false, message: error.response };
  }
  case httpResponseCodes.unauthorised: {
    return { success: false, message: error.response };
  }
  case httpResponseCodes.notReachable: {
    return { success: false, message: error.esponse };
  }
  case httpResponseCodes.internalError: {
    return { success: false, message: error.response };
  }
  default:
    return { success: false, message: error };
  }
};

export const axiosGlobalErrorHandler = {
  handleHttpResponse,
  handleHttpResponseError
};
