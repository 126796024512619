import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import EnhancedComponent from '../components/enhancedComponents';

import Registration from './../containers/user/registration/registration';
import RegisterCompany from './../containers/user/registration/registrationCompany';
import RegisterSuccessful from './../containers/user/registration/registrationSuccessful';
import Login from './../containers/user/login/login';
import Home from './../containers/home/home';
import ForgotPassword from './../containers/user/forgotPassword/forgotPassword';
import ForgotPasswordSuccessful from './../containers/user/forgotPassword/forgotPasswordSuccessful';
import ResetPassword from './../containers/user/forgotPassword/ResetPassword';
import ResetPasswordSuccessful from './../containers/user/forgotPassword/ResetPasswordSuccessful';
import Profile from './../containers/user/profile/profile';

// import ProjectDashboard from '../containers/projectDashboard/dashboard/projectDashboard';
import ProjectSection from '../containers/projectDashboard/projectSection/project-section';
import ProjectDashboard from '../containers/projectDashboard/projectDashboard/projectDashboard';
import CompanyDashboard from './../containers/companyDashboard/companyDashboard';
import Catalogue from './../containers/settings/catalogue/catalogue';
import UserManager from './../containers/settings/userManager/user-manager';
import UserRegister from './../containers/settings/userManager/user-register';
import ClientAndJobContainer from './../containers/settings/clientAndJobManager/client-and-job-container';
import ClientRegister from './../containers/settings/clientAndJobManager/client-register';
import SupplierManager from './../containers/settings/supplierManager/supplier-manager';
import SupplierRegister from './../containers/settings/supplierManager/supplier-register';
import WorkforceManager from './../containers/settings/workforceManager/workforce-manager';
import WorkforceRegister from './../containers/settings/workforceManager/workforce-register';
import PlantManager from './../containers/settings/plantManager/plant-manager';
import PlantRegister from './../containers/settings/plantManager/plant-register';
import GeneralSettings from './../containers/settings/generalSettings/generalSettings';
import PageUnderContruction from './../containers/pageUnderContruction/pageUnderContruction';
import PaymentCancel from './../containers/payments/paymentCancel';
import PaymentSuccessful from './../containers/payments/paymentSuccessful';

const Routes = () => {
  // console.log('rendered routes')
  // const getRoute = () => {
  //   return window.location.pathname !== '/login';
  // };

  return (
    <BrowserRouter>
      {/* { getRoute() ? <EnhancedComponent.SideDrawer/> : null } */}
      <Switch>
        <Route exact path={'/'} component={Home}/>
        <Route exact path={'/login'} component={Login}/>
        <Route exact path={'/register'} component={Registration}/>
        <Route exact path={'/register-company'} component={RegisterCompany}/>
        <Route exact path={'/register-successful'} component={RegisterSuccessful}/>
        <Route exact path={'/user_profile'} component={Profile}/>

        <Route exact path={'/home'} component={Home}/>
        <Route exact path={'/companyDashboard'} component={CompanyDashboard}/>

        <Route exact path={'/projectDashboard'} component={ProjectDashboard}/>
        <Route exact path={'/projectSection'} component={ProjectSection}/>
        <Route exact path={'/settings-catalogue'} component={Catalogue}/>
        <Route exact path={'/settings-user'} component={UserManager}/>
        <Route exact path={'/user-register/:user'} component={UserRegister}/>
        <Route exact path={'/settings-client'} component={ClientAndJobContainer}/>
        <Route exact path={'/clients_register/:client'} component={ClientRegister}/>
        <Route exact path={'/settings-supplier'} component={SupplierManager}/>
        <Route exact path={'/supplier-register/:supplier'} component={SupplierRegister}/>
        <Route exact path={'/settings-workforce'} component={WorkforceManager}/>
        <Route exact path={'/workforce_register'} component={WorkforceRegister}/>
        <Route exact path={'/settings-plant'} component={PlantManager}/>
        <Route exact path={'/plant-register/:plant'} component={PlantRegister}/>
        <Route exact path={'/settings-general'} component={GeneralSettings}/>
        <Route exact path={'/pageUnderContruction'} component={PageUnderContruction}/>

        <Route exact path={'/payment-success'} component={PaymentSuccessful}/>
        <Route exact path={'/payment-cancel'} component={PaymentCancel}/>

        <Route exact path={'/forgotPassword'} component={ForgotPassword}/>
        <Route exact path={'/forgotPasswordSuccessful'} component={ForgotPasswordSuccessful}/>

        <Route exact path={'/resetPassword'} component={ResetPassword}/>
        <Route exact path={'/resetPasswordSuccessful'} component={ResetPasswordSuccessful}/>
        <Route exact path={'*'} component={Home}/>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
