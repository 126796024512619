import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../../../context/globalState';
import { useHistory, Link } from 'react-router-dom'
import {
  TextField,
  Button
} from '@material-ui/core';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { userServices } from '../../../services/api/helpers/userServices';
import './styles.css';

import BasicComponent from '../../../components/basicComponents/index';
import { globalLocalStorage } from 'src/services/storage/localstorage';

interface LoginObject {
  email: string
  password: string
}

const Login = () => {
  const { user, updateUser } = useContext(GlobalContext);
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('An error has occured.');

  const handleClose = () => {
    setOpenModal(false);
  }
  useEffect(()=>{
    if ( user.navigation.length < 1){
      (async()=>{
        const userNavigation = await userServices.getUserNavigation4(null);
        if (userNavigation?.success){
          const api_access_token = globalLocalStorage.getItem('api_access_token');
          if (api_access_token){
            updateUser({ ...user, ...userNavigation.message.data, api_access_token})
            history.push('/home');
          }
        }
      })()
    } else if (globalLocalStorage.getItem('api_access_token')) {
      history.push('/home');
    }
  })

  const userLogin = async (email: string, password: string) => {
    const response = await userServices.login({ email, password });
    if (response.success) {
      updateUser({ ...response.message.data });
      history.push('/home');
    } else {
      setErrorMessage(response.message.data.data.message)
      setOpenModal(true);
    }
  };

  const onSubmit = (values:any) => {
    (async()=>{
      await userLogin(values.email, values.password);
    })()  
  };

  const modalBody = (
    <div className="card modal one">
      <div className="header">
        <h2 className="center bold">Error</h2>
      </div>
      <div className="body">
        <p className="center">{errorMessage}</p>
      </div>
    </div>
  )

  return (
    <section className="user login">
      <div className="card">
        <div className="header">
          <div className="logo">
            <img src="img/Logo-L.png" alt='logo-large'/>
          </div>
        </div>
        <BasicComponent.DynamicModal
          onOpen={openModal}
          body={modalBody}
          onClose={handleClose}
        />
        <div className="body">
          <h1 className="center">Log in</h1>
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email()
                .required('Please enter a valid e-mail address'),
            })}
            >
            {(props: FormikProps<LoginObject>) => {
              const {
                values, 
                touched,
                errors, 
                handleBlur,
                handleChange,
              } = props;
              return (
                <Form className="one grg40">
                  <div className="one grg20">
                    <TextField
                      name="email"
                      id="email"
                      placeholder="Email"
                      value={values.email}
                      type="email"
                      helperText={
                        errors.email && touched.email
                          ? errors.email
                          : ''
                      }
                      error={
                        !!(errors.email && touched.email)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={values.password}
                      type="password"
                      helperText={
                        errors.password && touched.password
                          ? 'Please enter a valid password. Nine characters, one uppercase, one lowercase, one special character, one number and no spaces.'
                          : ''
                      }
                      error={
                        !!(errors.password && touched.password)
                      }
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >Log in</Button>
                  <div className="links two gcg20">
                    <Link to="/forgotPassword" style={{color: 'grey'}}>
                      Forgot Password
                    </Link>
                    <Link to="/register" style={{color: 'grey'}}>
                      Register
                    </Link>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default Login;
