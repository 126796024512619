import { getRequest, postRequest, exportCSV } from '../root/restRequests';
import { endpoints } from '../root/endPoints';
import { globalLocalStorage } from '../../storage/localstorage';

const pageBuilder = async (pageName:string, body: any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(`${endpoints.pageData}/${pageName}`, body, token);
  return response;
};

const companyProjectBudgetData = async (budgetId:string, pageSectionId:number) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await getRequest(`${endpoints.companyProjectBudgetData}/${budgetId}/${pageSectionId}`, token);
  return response;
};

const filterPagination = async (pageName:string, body:any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(`${endpoints.pageData}/${pageName}`, body, token);
  return response;
};

const crudBudget = async (body:any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(endpoints.crudBudget, body, token);
  return response;
};

const crudItem = async (body:any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(endpoints.crudItem, body, token);
  return response;
};

const crudCharges = async (body:any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(endpoints.crudCharges, body, token);
  return response;
};

const exportTable = async (body:any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await exportCSV(endpoints.exportTable, body, token);
  return response;
};

export const pageServices = {
  pageBuilder,
  companyProjectBudgetData,
  crudBudget,
  crudItem,
  crudCharges,
  filterPagination,
  exportTable
};
