/**This component needs to be reworked.
 * Remove the bad practice from the component. Look at other Tables */
import React, { useState, useEffect } from "react";
import { PaginationType, useSearchAndFilterContext } from "../../../context/searchAndFilterProvider";
import EnhancedComponent from "../../../components/enhancedComponents";
import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TablePagination,
} from "@material-ui/core";
import Row from "./dynamicCollapsibleTableComponents/Row";

interface Props {
	tableName: string;
	data: any;
	total_results: number;
    section_type: number;
	pageSectionId?: number;
	onUpdate?: any;
    pagination: PaginationType;
}

export const DynamicCollapsibleTable = ({
	tableName,
	data,
	pageSectionId,
	total_results,
	onUpdate,
    section_type,
    pagination
}: Props) => {
	const { paginate } = useSearchAndFilterContext();
	const headerRow = data.header_row.cells;
	const [tableBody, setTableBody] = useState(data.body);

	const [open, setOpen] = useState(false);
	const [currentTarget, setCurrentTarget] = useState();
	const [valueData, setValueData] = useState();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect(() => {
		setTableBody(data.body);
	}, [data.body]);

    useEffect(() => {
        setPage(pagination?.page ?? 0);
        setRowsPerPage(pagination?.rows_per_page ?? 10);
      }, [pagination]);
	/** Search and filter functionality
	 * Start
	 */
	const openState = () => {
		setOpen(false);
	};

	const searchAndFilter = ({ currentTarget }: any, value: any) => {
		const { category_name } = value;
		if (category_name) {
			setOpen(false);
			setCurrentTarget(currentTarget);
			setValueData(value);
		}
	};

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		paginate(rowsPerPage, newPage, section_type);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		paginate(parseInt(event.target.value), 0, section_type);
	};
	/** Search and filter functionality
	 * end
	 */

	return (
		<TableContainer className={tableName}>
			<Table stickyHeader>
				<TableHead className='thead'>
					<TableRow>
						{headerRow.map((rowValue: any, rowIndex: number) => (
							<TableCell
								key={`theadCell-${rowIndex}`}
								align={rowValue.align}
							>
								{rowValue.text}
							</TableCell>
						))}
						<EnhancedComponent.SearchAndFilter
							open={open}
							currentTarget={currentTarget}
							data={valueData}
							openState={openState}
						/>
					</TableRow>
				</TableHead>
				<TableBody className='tbody' id='tbody'>
					{tableBody.map((rowValue: any, rowIndex: number) => (
						<Row
							key={`tbody-${rowValue.collapsed_rows_slug ?? rowIndex}`}
							row={rowValue}
							pageSectionId={pageSectionId}
							tableBody={tableBody}
							setTableBody={setTableBody}
							onUpdate={onUpdate}
						/>
					))}
				</TableBody>
			</Table>
			<TablePagination
				component='div'
				count={total_results}
				page={page}
				onChangePage={handleChangePage}
				rowsPerPage={rowsPerPage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</TableContainer>
	);
};
