import React, { useState } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

interface Props {
  className?: string,
  keyName: string,
  label?: string,
  val?: any,
  validation?: any,
  onUpdate: any,
  futureDates?: boolean,
  pastDates?: boolean
}

type ErrorValidation = {
  status: boolean,
  message: string
}

export const DatePicker = (props: Props) => {
  const { className, keyName, label, val, onUpdate, validation, futureDates = false, pastDates = true } = props;
  const [selectedDate, setSelectedDate] = useState<string | null>(
    val ? moment(new Date(val)).format('YYYY-MM-DD') : null
  );
  const [error, setError] = useState<ErrorValidation>({
    status: false,
    message: ''
  });

  const handleDateChange = (date: Date | null) => {
    const formatedDate = moment(date).format('YYYY/MM/DD');
    setSelectedDate(formatedDate);
    onUpdate(formatedDate, keyName);
  };

  const validate = (event: any) => {
    /** Validity boolean options
     * badInput, customError, patternMismatch, rangeOverflow, rangeUnderflow
     * stepMismatch, tooLong, tooShort, typeMismatch, valid, valueMissing
    */
    const { validationMessage } = event.target;
    const { valid } = event.target.validity;
    setError((prevErrorMessage) => ({
      ...prevErrorMessage,
      status: !valid,
      message: !!validationMessage && validationMessage
    }));
  };

  const handleClearClick = () => {
    setSelectedDate(null);
    onUpdate(null, keyName);
  };

  return (
    <MuiPickersUtilsProvider key={keyName} utils={DateFnsUtils}>
      <KeyboardDatePicker
        error={error.status}
        disableToolbar
        variant="inline"
        format="yyyy/MM/dd"
        id={label}
        className={`${className || 'date-picker'} custom-input-datepicker`}
        label={label}
        // placeholder={!val || val === '' ? "YYYY/MM/DD" : ''}
        value={selectedDate}
        onFocus={(event) => validate(event)}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        InputLabelProps={{
          shrink: true
        }}
        required={!!validation && validation.required}
        helperText={error.message}
        disableFuture={!futureDates}
        disablePast={!pastDates}
        InputProps={{
          readOnly: true,
          endAdornment: (

            <IconButton
              aria-label="clear data input"
              onClick={handleClearClick}
            >
              <Clear />
            </IconButton>
          )
        }}
        InputAdornmentProps={
          {
            position: 'start'
          }
        }
      />
    </MuiPickersUtilsProvider>
  );
};
