import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EnhancedComponent from '../../components/enhancedComponents';
import './styles.css';

const PaymentSuccessful = () => {
  const history = useHistory();

  const onOK = () => {
    return history.push('/settings-general');
  };

  return (
    <>
      <EnhancedComponent.SideDrawer/>
      <div className="container">
        <section className="payments success">
          <div className="card">
            <div className="header">
              <div className="logo">
                <img src="img/Logo-L.png" alt="logo"/>
              </div>
            </div>
            <form className="body" autoComplete="off">
              <h1 className="center">Subscribed</h1>
              <p className="center">Welcome to SkyBuild.</p>
              <p className="center">You now have access to more features. Remember to setup your general settings. Most importantly the user permissions</p>
              <Button type="submit" variant="contained" color="primary" onClick={onOK}>Ok</Button>
            </form>
          </div>
        </section>
      </div>
    </>
  );
};

export default PaymentSuccessful;
