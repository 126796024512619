import React, { useState } from 'react';
import EnhancedComponent from '../../../components/enhancedComponents';
import {
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';
import './styles.css';

interface ProjectSettingsState {
  projectId: number,
  data: any
}

const pageData = {
  heading: 'General Settings',
  projectId: 1,
  buttons: []
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="settings one grg20">
          {children}
        </div>
      )}
    </div>
  );
};

const a11yProps = (index: any) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
};

const GeneralSettings = () => {
  const [value, setValue] = useState(0);
  const [state] = useState<ProjectSettingsState>({
    projectId: pageData.projectId,
    data: pageData
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <EnhancedComponent.SideDrawer/>
      <div className="container">
        <section className="general-settings gcrg30">
          <div className="heading two gcrg20">
            <h1 className="uppercase pc1 jstart">{state.data.heading}</h1>
          </div>
          <div className="card one grg20">
            <AppBar className="tab-container" position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Company Details" {...a11yProps(0)} />
                <Tab label="Divisions" {...a11yProps(1)} />
                <Tab label="Cost Codes" {...a11yProps(2)} />
                <Tab label="Cost Types" {...a11yProps(3)} />
                <Tab label="Unit Types" {...a11yProps(4)} />
                <Tab label="Group Types" {...a11yProps(5)} />
                <Tab label="Workforce" {...a11yProps(6)} />
                <Tab label="Assets" {...a11yProps(7)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <EnhancedComponent.CompanyDetails projectId={state.projectId} settingType={'company_details'}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <EnhancedComponent.Settings projectId={state.projectId} settingType={'divisions'}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <EnhancedComponent.Settings projectId={state.projectId} settingType={'cost_codes'}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <EnhancedComponent.Settings projectId={state.projectId} settingType={'cost_types'}/>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <EnhancedComponent.Settings projectId={state.projectId} settingType={'unit_types'}/>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <EnhancedComponent.Settings projectId={state.projectId} settingType={'group_types'}/>
            </TabPanel>
            <TabPanel value={value} index={6}>
              <EnhancedComponent.Settings projectId={state.projectId} settingType={'workforce'}/>
            </TabPanel>
            <TabPanel value={value} index={7}>
              <EnhancedComponent.AssetsSettings projectId={state.projectId} settingType={'assets'}/>
            </TabPanel>
          </div>
        </section>
      </div>
    </>
  );
};

export default GeneralSettings;
