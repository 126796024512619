import React, { useState } from 'react';
import { useTableContext } from '../../../context/tableProvider';
import {
  IconButton,
  TextField
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import './input-type.styles.sass';

interface TextFieldButton {
  name: string,
  label?: string,
  value: number,
  text?: string,
  disabled: boolean,
  id: string
}

interface CurrencyRate {
  slug: string,
  data: {
    id: number,
    exchange_rate: string
  }
}

export const TextFieldButton: React.FC<TextFieldButton> = (props: TextFieldButton) => {
  const { name, label, value, disabled, id } = props;
  const { exchangeRateSet } = useTableContext();
  const [edit, setEdit] = useState(false);

  const body: CurrencyRate = {
    slug: 'change_job_exchange_rate_value',
    data: {
      id: 0,
      exchange_rate: ''
    }
  };

  const saveContent = () => {
    setEdit(false);
    exchangeRateSet(body.slug, body.data.id, body.data.exchange_rate);
  };

  const updateCurrency = (value:any, name:any) => {
    body.data.id = parseInt(id.split('-')[1]);
    body.data.exchange_rate = parseFloat(value).toFixed(2);
  };

  return (
    <div className="text-field-button two gcg10">
      { edit
        ? <TextField
          key={name}
          label={label}
          // value={value}
          defaultValue={value}
          disabled={disabled}
          onBlur={(event) => updateCurrency(event.target.value, name)}
          InputLabelProps={{
            shrink: true
          }}
        />
        : value
      }
      { !edit
        ? <IconButton
          aria-label="edit"
          className="edit-btn"
          onClick={() => setEdit(true)}
        ><EditIcon fontSize="small"/></IconButton>
        : <IconButton
          aria-label="edit"
          className="edit-btn"
          onClick={saveContent}
        ><SaveIcon fontSize="small"/></IconButton>
      }
    </div>
  );
};
