import React, { useState, useEffect } from 'react';
import BasicComponent from '../../../components/basicComponents/index';
import {
  Button
} from '@material-ui/core';
import { settingsServices } from '../../../services/api/helpers/settingsServices';

interface Props {
  settings: any,
  onBack?: any
}

interface SupplierDetails {
  settings?: any,
  pageData: PageData
}

interface PageData {
  name: string,
  buttons: any,
  slug: string,
  details: any,
  contacts?: any
}

interface Contacts {
  data: any,
  filters: any,
  total_results: number
}

const SupplierRegister = (props:Props) => {
  const { settings, onBack } = props;
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<SupplierDetails>({
    settings,
    pageData: {
      name: '',
      buttons: [],
      slug: '',
      details: {}
      // contacts: {}
    }
  });
  const [contacts, setContacts] = useState<Contacts>({
    data: [],
    filters: [],
    total_results: 0
  });

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: ''
  });

  useEffect(() => {
    (async function () {
      await pageData();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, []);

  const pageData = async () => {
    let body;
    const page = state.settings.split('-')[0];
    const id = parseInt(state.settings.split('-')[1]);
    if (state.settings === 'supplier_details-0') {
      body = {
        slug: 'create',
        data: {
          page
        }
      };
    } else {
      body = {
        slug: 'read',
        data: {
          id,
          page
        }
      };
    }

    const response = await settingsServices.crudSettings('supplier', body);
    if (response?.success) {
      const pageData = response.message.data.page_data;
      const contacts = response.message.data.page_data.sections.contacts;
      setState({
        ...state,
        pageData: {
          name: pageData.name,
          buttons: pageData.buttons,
          slug: pageData.slug,
          details: pageData.sections.supplier_details
          // contacts: pageData.sections.contacts
        }
      });
      setContacts({
        data: contacts,
        filters: [],
        total_results: 0
      });
    }
  };

  const onUpdate = async (updateState: any, data: any) => {
    /** Save data
     * Data received from the children
     * On success receive response object to update state or reload the page data */
    const body = {
      slug: updateState,
      data: {}
    };

    if (updateState === 'create') {
      const response = await settingsServices.crudSettings('supplier', data);
      if (response?.success) {
        const newTableRow = response.message.data.new_row;
        setContacts({
          ...contacts,
          data: {
            ...contacts.data,
            table: {
              ...contacts.data.table, body: [...contacts.data.table.body, newTableRow]
            }
          }
        });
      }
    }

    if (updateState === 'update') {
      const page = data.slug.split('-')[0];
      const id = parseInt(settings.split('-')[1]);
      if (settings.includes('supplier')) {
        if (data.slug.includes('details')) {
          Object.assign(body.data, { id, page, item: data.data });

          const response = await settingsServices.crudSettings('supplier', body);
          if (response?.success) {
            const pageData = response.message.data.page_data;
            const contactButtons = contacts.data.table.buttons.map((button:any) => {
              if (button.action === 'Create') { button.disabled = 0; }
              return button;
            });
            setState({
              settings: pageData.sections.supplier_details.slug,
              pageData: {
                ...state.pageData,
                name: pageData.name,
                slug: pageData.slug,
                details: pageData.sections.supplier_details
              }
            });
            setContacts({
              ...contacts,
              data: {
                ...contacts.data,
                table: {
                  ...contacts.data.table, buttons: contactButtons
                }
              }
            });
          }
        } else {
          const id = parseInt(data.slug.split('-')[1]);
          const supplier_id = parseInt(state.pageData.details.slug.split('-')[1]);
          Object.assign(body.data, { id, page, supplier_id, item: data.data });

          const response = await settingsServices.crudSettings('supplier', body);
          if (response?.success) {
            const newRow = response.message.data.new_row;
            const contactsTableBody = contacts.data.table.body;
            const newContactsTableBody = contactsTableBody.map((row:any) => {
              if (row.row_slug === newRow.row_slug || row.row_slug.includes('-0')) {
                return newRow;
              }
              return row;
            });

            setContacts({
              ...contacts,
              data: {
                ...contacts.data,
                table: {
                  ...contacts.data.table, body: newContactsTableBody
                }
              }
            });
          }
        }
      }
    }

    if (updateState === 'delete') {
      const page = data.split('-')[0];
      const id = parseInt(data.split('-')[1]);
      Object.assign(body.data, { id, page });

      const response = await settingsServices.crudSettings('supplier', body);
      if (response?.success) {
        const { body } = contacts.data.table;

        if (page.includes('details')) {
          onBack();
          setOpenAlert(true);
          setAlertMessage({
            message: response.message.data.message,
            severity: 'success'
          });
        } else {
          setOpenAlert(true);
          setAlertMessage({
            message: response.message.data.message,
            severity: 'success'
          });
          const newContacts = body.filter((contacts:any) => contacts.row_slug !== data);
          setContacts({
            ...contacts,
            data: {
              ...contacts.data,
              table: {
                ...contacts.data.table, body: newContacts
              }
            }
          });
        }
      }
    }
  };

  return (
    <>
      { loading
        ? <BasicComponent.Loader size={60} message="Loading data..." />
        : <section className="supplier-settings gcrg30">
          <div className="heading two gcrg20">
            <h1 className="uppercase pc1 jstart">{state.pageData.name}</h1>
            {state.pageData.buttons.map(({ action, slug, name }: any) => {
              if (action === 'Back') { return <Button key={slug} className="btn small jend" variant="contained" color="primary" onClick={onBack}>{name}</Button>; }
            })}
          </div>
          <div className="card one grg20">
            <div className="details one grg20">
              <BasicComponent.Details detailsPanel="supplier-details" data={state.pageData.details} onUpdate={onUpdate}/>
            </div>
            <div className="one grg20">
              <div className="heading underline">
                <h3 className="uppercase jstart">{contacts.data.heading}</h3>
              </div>
              <BasicComponent.DynamicSettingsTable key="contacts" tableName="table contacts one grg20 col2-span" {...contacts} onUpdate={onUpdate}/>
            </div>
          </div>
          <BasicComponent.CustomizedSnackbars message={alertMessage.message} severity={alertMessage.severity} onOpen={openAlert} onClose={(open:any) => setOpenAlert(open)}/>
        </section>
      }
    </>
  );
};

export default SupplierRegister;
