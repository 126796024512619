/* eslint-disable*/
import React, { useState } from 'react';
import { settingsServices } from '../../../services/api/helpers/settingsServices';
import BasicComponent from '../../../components/basicComponents/index';
import {
  Button
} from '@material-ui/core';
import './styles.sass';

const RegisterSuccessful: React.FC<{}> = () => {
  const [state, setState] = useState({
    form_fields: {
      merchant_id: '',
      merchant_key: '',
      return_url: '',
      cancel_url: '',
      notify_url: '',
      name_first: '',
      name_last: '',
      email_address: '',
      m_payment_id: '',
      amount: '',
      item_name: '',
      custom_int1: '',
      subscription_type: '',
      frequency: '',
      cycles: '',
      signature: ''
    },
    submit_url: ''
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: ''
  });

  const { merchant_id, merchant_key, return_url, cancel_url, notify_url, name_first, name_last, email_address, m_payment_id, amount, item_name, custom_int1, subscription_type, frequency, cycles, signature } = state.form_fields;

  const generateRequest = async () => {
    const response = await settingsServices.SubscriptionRequest(null);
    if (response?.success) {
      const { merchant_id, merchant_key, return_url, cancel_url, notify_url, name_first, name_last, email_address, m_payment_id, amount, item_name, custom_int1, subscription_type, frequency, cycles, signature } = response.message.data.form_fields;
      const submit_url = response.message.data.submit_url;
      setState({
        form_fields: {
          merchant_id,
          merchant_key,
          return_url,
          cancel_url,
          notify_url,
          name_first,
          name_last,
          email_address,
          m_payment_id,
          amount,
          item_name,
          custom_int1,
          subscription_type,
          frequency,
          cycles,
          signature
        },
        submit_url
      });

      /** SHIT code since Axios not responding */
      const sumbit = () => {
        const submit = document.getElementById('submitBTN');
        submit?.click();
      };
      sumbit();
    } else {
      setOpenAlert(true);
      setAlertMessage({
        message: 'Something happend!',
        severity: 'error'
      });
    }
  };

  return (
    <section className="user registration company">
      <div className="card">
        <div className="header">
          <div className="logo">
            <img src="img/Logo-L.png"/>
          </div>
        </div>
        <div className="body">
          <h1 className="center">Credit Card Payment</h1>
          <p className="center">Please wait while you are redirected to the secure payment page to complere payment by credit card. <br /><br />
          Once payment is completed we&apos;ll send a verification email to the provided address. Please click on the link in this email to confirm your subscription.</p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => generateRequest()}
          >OK</Button>
        </div>
      </div>
      <BasicComponent.CustomizedSnackbars message={alertMessage.message} severity={alertMessage.severity} onOpen={openAlert} onClose={(open:any) => setOpenAlert(open)}/>

      <form action={state.submit_url} method="POST" className="subscription-form" autoComplete="off">
        <input hidden key="merchant-id" name="merchant_id" value={merchant_id}/>
        <input hidden key="merchant-key" name="merchant_key" value={merchant_key}/>
        <input hidden key="return-url" name="return_url" value={return_url}/>
        <input hidden key="cancel-url" name="cancel_url" value={cancel_url}/>
        <input hidden key="notify-url" name="notify_url" value={notify_url}/>
        <input hidden key="name-first" name="name_first" value={name_first}/>
        <input hidden key="name-last" name="name_last" value={name_last}/>
        <input hidden key="email-address" name="email_address" value={email_address}/>
        <input hidden key="m-payment-id" name="m_payment_id" value={m_payment_id}/>
        <input hidden key="amount" name="amount" value={amount}/>
        <input hidden key="item-name" name="item_name" value={item_name}/>
        <input hidden key="custom-int1" name="custom_int1" value={custom_int1}/>
        <input hidden key="subscription-type" name="subscription_type" value={subscription_type}/>
        <input hidden key="frequency" name="frequency" value={frequency}/>
        <input hidden key="cycles" name="cycles" value={cycles}/>
        <input hidden key="signature" name="signature" value={signature}/>
        <input hidden id="submitBTN" type="submit" />
      </form>
    </section>
  );
};

export default RegisterSuccessful;
