import React, { useState, useEffect, useRef, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import './maps-style.sass';

function loadScript (src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface PlaceType {
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      },
    ];
  };
}

interface Props {
  keyName: string,
  label?: string,
  val: any,
  validation: any,
  onUpdate: any
}

type ErrorValidation = {
  status: boolean,
  message: string
}

const validationProps = (pattern: any) => {
  if (pattern) {
    return {
      inputProps: { pattern }
    };
  }
};

export const GoogleMaps = ({ keyName, label, val, validation, onUpdate }:Props) => {
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState(val || '');
  const [options, setOptions] = useState<PlaceType[]>([]);
  const loaded = useRef(false);
  const [error, setError] = useState<ErrorValidation>({
    status: false,
    message: ''
  });

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyCwL1V5P6_TNf5poO7jgpqVeFFzQn7W3rA&libraries=places&region=ZA',
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 500),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: (PlaceType)[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const validate = (event: any) => {
    /** Validity boolean options
     * badInput, customError, patternMismatch, rangeOverflow, rangeUnderflow
     * stepMismatch, tooLong, tooShort, typeMismatch, valid, valueMissing
    */
    const { validationMessage } = event.target;
    const { valid } = event.target.validity;
    setError((prevErrorMessage) => ({
      ...prevErrorMessage,
      status: !valid,
      message: !!validationMessage && validationMessage
    }));
  };

  return (
    <Autocomplete
      id="google-map"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={inputValue}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onUpdate(newValue?.description, keyName);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error.status}
          label={label}
          variant="outlined"
          fullWidth
          onFocus={(event) => validate(event)}
          onKeyUp={(event) => validate(event)}
          InputLabelProps={{ shrink: true }}
          { ...validationProps(!!validation && validation.pattern) }
          required={!!validation && validation.required}
          helperText={error.message}
        />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className="icon" />
            </Grid>
            <Grid item xs>
              {parts.map((part:any, index:any) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};
