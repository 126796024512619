import React from 'react';
import {
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export const CustomLink = (props:any) => {
  const { attachments, onDelete, button_permissions } = props;

  const deleteLink = (id:any) => {
    onDelete(id);
  };

  return (
    <List dense={true}>
      {attachments.map(({ id, path, name }:any) => (
        <ListItem key={name}>
          <Link href={path} target="_blank">{name}</Link>
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" disabled={!!(button_permissions === 0)} onClick={() => deleteLink(id)}>
              <DeleteIcon fontSize="small"/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};
