export const defaultBase = process.env.REACT_APP_API;

export const endpoints = {
  // User
  login: 'login',
  getUserNavigation: 'getUserNavigation',
  userProfile: 'userProfile',
  register: 'register',
  createAccount: 'createAccount',
  logout: 'logout',
  forgotPassword: 'forgotPassword',
  notifications: '/notifications/get',
  notificationsStatus: '/notifications/updateRead',

  getUserDetails: 'getUserDetails',
  updateUserDetails: 'updateUserDetails',
  getUserMetaData: 'getUserMetaData',

  // Authentication
  verificationEmail: 'verificationEmail',
  resendVerificationEmail: 'resendVerificationEmail',

  // Page Builder
  pageData: 'getData',
  companyProjectBudgetData: 'companyProjectBudgetData',
  crudBudget: 'crudBudget',
  crudItem: 'crudItem',
  exportTable: 'export',
  crudCharges: 'crudCharges',

  // Dasboard
  dashboard: 'dashboard',

  // Settings
  catalogue: 'catalogue',
  generalSettings: 'general',
  clientAndJobSettings: 'clientAndJob',
  subscriptionRequest: 'subscription/generateRequest',
  subscriptionCancel: 'subscription/cancel',
  duplicateValidator: 'validator'
};
