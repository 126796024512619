const FUEL_CHOICE_DATA = {
  name: 'fuel_choice',
  default_value: 'bowser',
  radio_options: [
    {
      id: 1,
      value: 'bowser',
      label: 'Bowser',
      placement: 'end'
    },
    {
      id: 2,
      value: 'plant',
      label: 'Plant & Machinery',
      placement: 'end'
    }
  ]
};

export default FUEL_CHOICE_DATA;
