/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import { globalLocalStorage } from '../../../services/storage/localstorage';
import BasicComponent from '../index';
import _ from 'lodash';
import {
  FormControl, NativeSelect, InputLabel, TextField
} from '@material-ui/core';
import './input-type.styles.sass';

interface FitlerDropdown {
  className?: string,
  keyName: string,
  val?: any,
  options?: any,
  reloadContent?: any,
  onClick?: any
}

export const FitlerDropdown: React.FC<FitlerDropdown> = (props: FitlerDropdown) => {
  // const page = globalLocalStorage.getItem('currentPage');
  const { searchResults } = useSearchAndFilterContext();
  const { className, keyName, options, reloadContent } = props;
  const [state, setState] = useState({
    value: '0'
  });
  const [filterDate, setFilterDate] = useState({
    start_date: '',
    end_date: ''
  });

  const [searchQuery, setSearchQuery] = useState({});

  useEffect(() => {
    if (state.value !== '') {
      inputSwitch();
    }
  }, []);

  useEffect(() => {
    setState({ value: '0' });
  }, [reloadContent]);

  useEffect(() => {
    if (!!filterDate.start_date && !!filterDate.end_date) {
      filterByDate(filterDate);
    }
  }, [filterDate]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setState({
      value: event.target.value
    });
  };

  const setDate = (val: any, key:any) => {
    if (key === 'start_date') {
      setFilterDate({ ...filterDate, start_date: val });
    } else {
      setFilterDate({ ...filterDate, end_date: val });
    }
  };

  const filterByDate = (date: any) => {
    const { start_date, end_date } = date;
    const { value } = state;
    const inputData = options.find((option:any) => (option.id === parseInt(value)));
    if (inputData) {
      const { category_name, title } = inputData;
      searchResults(category_name, `${start_date} - ${end_date}`, 'DESC', title);
    }
  };

  const searchHandler = ({ target: { value } }: any) => {
    const search = _.debounce(sendQuery, 1000);

    setSearchQuery((prevSearch:any) => {
      if (prevSearch.cancel) {
        prevSearch.cancel();
      }
      return search;
    });

    search(value);
  };

  const sendQuery = (val: any) => {
    const { value } = state;
    const inputData = options.find((option:any) => (option.id === parseInt(value)));
    if (inputData) {
      const { category_name, title } = inputData;
      const filter = val;
      searchResults(category_name, filter, 'DESC', title);
    }
  };

  const inputSwitch = () => {
    switch (state.value) {
    case '0':
      return null;

    case '1':
      return (
        <div className="date-picker">
          <InputLabel shrink htmlFor="date-picker">Filter: Date</InputLabel>
          <div className="picker two gcg10">
            <BasicComponent.DatePicker keyName="start_date" onUpdate={setDate}/>
            <BasicComponent.DatePicker keyName="end_date" onUpdate={setDate}/>
          </div>
        </div>
      );

    default:
      return (
        <div className="input-field">
          <TextField
            key={`text-filter-${state.value}`}
            label="Filter"
            defaultValue=""
            autoFocus
            onKeyUp={searchHandler}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
      );
    }
  };

  return (
    <div className="filter-dropwdown two gcrg20 acenter">
      {inputSwitch()}
      <FormControl key={keyName} className={className || 'dropdown'}>
        <InputLabel shrink htmlFor="filters">Filters</InputLabel>
        <NativeSelect
          value={state.value}
          onChange={handleChange}
          inputProps={{
            name: keyName
          }}
        >
          <option value="0" >No Filter</option>
          {options.map((option: any) => (
            <option key={option.title} value={option.id}>{option.title}</option>
          ))}
        </NativeSelect>
      </FormControl>
    </div>
  );
};
