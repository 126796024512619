import React from 'react';
import './style.css';

interface Props {
  filters: any
}

export const AppliedFilter = ({ filters }: Props) => {
  return (
    <div className="applied-filter one grg10">
      <h4 className="uppercase">Filters Applied</h4>
      <ul className="four gcrg20 jstart acenter">
        {filters.map((filter: any) => (
          <li key={filter.id}><span className="uppercase">{filter.filter_name}:</span> {filter.filter_value}</li>
        ))}
      </ul>
    </div>
  );
};
