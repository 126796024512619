import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  TextField,
  Button,
  Modal
} from '@material-ui/core';
// eslint-disable-next-line no-unused-vars
import { Formik, Form, FormikProps } from 'formik';
// @ts-ignore
import * as Yup from 'yup';
import { userServices } from '../../../services/api/helpers/userServices';
import { globalLocalStorage } from 'src/services/storage/localstorage';
import './styles.sass';

interface UserObject {
  first_name: string
  last_name: string
  email: string
  password: string
  confirm_password: string
}

const Register = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('an Error has occured.');

  const handleClose = () => {
    setOpen(false);
  };

  const createNewUser = async (data: UserObject, resetForm: Function) => {
    const response = await userServices.register(data);
    if (response.success) {
      globalLocalStorage.storeItem('api_access_token', response.message.data.api_access_token);
      history.push('/register-company');
    } else {
      setErrorMessage(response.message.data.data.error.email[0]);
      setOpen(true);
    }
  };

  const modalBody = (
    <div className="card modal one">
      <div className="header">
        <h2 className="center bold">Error</h2>
      </div>
      <div className="body">
        <p className="center">{errorMessage}</p>
      </div>
    </div>
  );

  return (
    <section className="user registration">
      <div className="card">
        <div className="header">
          <div className="logo">
            <img src="img/Logo-L.png"/>
          </div>
        </div>
        <Modal open={open} onClose={handleClose}>
          {modalBody}
        </Modal>
        <div className="body">
          <h1 className="center">Create Account</h1>
          <Formik
            initialValues={{
              first_name: '',
              last_name: '',
              email: '',
              password: '',
              confirm_password: ''
            }}
            onSubmit={(values: UserObject, actions) => {
              createNewUser(values, actions.resetForm);
              setTimeout(() => {
                actions.setSubmitting(false);
              }, 500);
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email()
                .required('Please enter a valid E-mail Address'),
              first_name: Yup.string().required('Please enter full name'),
              last_name: Yup.string().required('Please enter last name'),
              password: Yup.string()
                .matches(
                  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,20}\S$/
                )
                .required(
                  'Please enter a valid password. Nine characters, one uppercase, one lowercase, one special character, one number and no spaces.'
                ),
              confirm_password: Yup.string()
                .required('Required')
                .test(
                  'password-match',
                  'Password must match',
                  function (value: any) {
                    // @ts-ignore
                    return this.parent.password === value;
                  }
                )
            })}
          >
            {(props: FormikProps<UserObject>) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                isSubmitting
              } = props;
              return (
                <Form className="one grg40">
                  <div className="one grg20">
                    <TextField
                      name="first_name"
                      id="first_name"
                      placeholder="First Name"
                      value={values.first_name}
                      type="text"
                      helperText={
                        errors.first_name && touched.first_name
                          ? errors.first_name
                          : ''
                      }
                      error={
                        !!(errors.first_name && touched.first_name)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      name="last_name"
                      id="last_name"
                      placeholder="Last Name"
                      value={values.last_name}
                      type="text"
                      helperText={
                        errors.last_name && touched.last_name
                          ? errors.last_name
                          : ''
                      }
                      error={
                        !!(errors.last_name && touched.last_name)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      name="email"
                      id="email"
                      placeholder="E-mail"
                      value={values.email}
                      type="email"
                      helperText={
                        errors.email && touched.email
                          ? errors.email
                          : ''
                      }
                      error={
                        !!(errors.email && touched.email)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={values.password}
                      type="password"
                      helperText={
                        errors.password && touched.password
                          ? 'Please enter a valid password. Nine characters, one uppercase, one lowercase, one special character, one number and no spaces.'
                          : ''
                      }
                      error={
                        !!(errors.password && touched.password)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      name="confirm_password"
                      id="confirm_password"
                      placeholder="Confirm Password"
                      value={values.confirm_password}
                      type="password"
                      helperText={
                        errors.confirm_password &&
                        touched.confirm_password
                          ? errors.confirm_password
                          : ''
                      }
                      error={
                        !!(errors.confirm_password &&
                          touched.confirm_password)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >Next</Button>
                  <div className="links one">
                    <Link to="/login" style={{ color: 'grey' }}>
                      login
                    </Link>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default Register;
