import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { pageServices } from '../../../services/api/helpers/pageServices';
import './style.sass';

interface Props {
  data: any,
  searchQuery: any,
  onUpdate: any
}

type ErrorValidation = {
  status: boolean,
  message: string
}

const validationProps = (pattern: any) => {
  if (pattern) {
    return {
      inputProps: { pattern }
    };
  }
};

export const AutoComplete = ({ data, searchQuery, onUpdate }: Props) => {
  const { validation, value } = data;
  const { group_type_id } = data.options;
  const [options, setOptions] = useState(value ? data.options.options : []);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<ErrorValidation>({
    status: false,
    message: ''
  });
  // const loading = open && options.length === 0;
  const loading = open;

  let defaultProps = {};
  if (data.name === 'group_type_id') {
    defaultProps = {
      getOptionLabel: (option:any) => option.name,
      getOptionSelected: (option:any, value:any) => option.name.includes(value)
    };
  } else if (data.name === 'item_description_id') {
    defaultProps = {
      getOptionLabel: (option:any) => option.item_description,
      getOptionSelected: (option:any, value:any) => option.item_description.includes(value)
    };
  } else {
    defaultProps = {
      getOptionLabel: (option:any) => option.name,
      getOptionSelected: (option:any, value:any) => option.name.includes(value)
    };
  }

  useEffect(() => {
    if (open) {
      setOptions([]);
    }
  }, [open]);

  const getSearchedData = async (body:any) => {
    if (data.name === 'item_description_id' && body.data.group_type_id === null) {
      alert('Select group type first');
    } else if (data.name === 'item_category_id' && body.data.group_category_id === null) {
      alert('Select group category first');
    } else {
      const response = await pageServices.crudItem(body);
      if (response?.success) {
        setOptions(response.message.data.catalogue_items);
      };
    }
  };

  const search = async (event:any) => {
    const body = {
      slug: searchQuery.slug,
      data: {
        group_type_id: searchQuery.id ? searchQuery.id : group_type_id,
        group_category_id: searchQuery.id ? searchQuery.id : group_type_id,
        key: data.name,
        value: event.target.value
      }
    };
    validate(event);
    await getSearchedData(body);
  };

  const changeHandle = async (event:any, newValue:any) => {
    onUpdate(data.name, newValue ?? '');
    if (newValue != null) {
      validate(event);
    }
  };

  const validate = (event: any) => {
    /** Validity boolean options (errors on these options)
     * badInput, customError, patternMismatch, rangeOverflow, rangeUnderflow
     * stepMismatch, tooLong, tooShort, typeMismatch, valid, valueMissing
    */
    const { validationMessage } = event.target;
    setError((prevErrorMessage) => ({
      ...prevErrorMessage,
      status: !!validationMessage,
      message: !!validationMessage && validationMessage
    }));
  };

  const onFocusHandle = async (event: any) => {
    setOptions([]);
    search(event);
  };

  return (
    <Autocomplete
      options={options}
      {...defaultProps}
      defaultValue={!!data.value && data.options.options ? data.options.options[0] : []}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      onFocus={(event) => onFocusHandle(event)}
      onKeyUp={(event) => search(event)}
      onChange={(event, newValue) => changeHandle(event, newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error.status}
          label={data.label}
          variant="outlined"
          value={value}
          title={data.label.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) => letter.toUpperCase())}
          InputLabelProps={{
            shrink: false
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress className="input-loader" color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          { ...validationProps(!!validation && validation.pattern) }
          required={!!validation && validation.required}
          helperText={error.message}
        />
      )}
    />
  );
};
