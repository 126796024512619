import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import './../style.css';

/** Severity Options
 * error
 * warning
 * info
 * success
 */

interface Props {
  message: string,
  severity: string,
  onOpen: any,
  onClose: any
}

const Alert = (props:any) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const CustomizedSnackbars = ({ message, severity, onOpen, onClose }:Props) => {
  useEffect(() => {
    if (onOpen) {
      onClose(true);
    }
  }, [onOpen]);

  const handleClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose(false);
  };

  return (
    <div className={`snackbar ${severity}`}>
      <Snackbar open={onOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>{message}</Alert>
      </Snackbar>
    </div>
  );
};
