import React, { useState, useEffect } from 'react';
import BasicComponent from '../../../components/basicComponents/index';
import {
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  TextareaAutosize
} from '@material-ui/core';
import Image from 'material-ui-image';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { settingsServices } from 'src/services/api/helpers/settingsServices';

interface Props {
  detailsPanel: string,
  data: any,
  onUpdate: any
}

interface Details {
  data: any
}

interface UpdateDetails {
  slug: string,
  data: Array<any>
}

export const Details = ({ detailsPanel, data, onUpdate }: Props) => {
  const [detailsState, setDetailsState] = useState(data.slug?.includes('-0') ? 'edit' : 'read-only');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [details, setDetails] = useState<Details>({
    data: data
  });
  const [updateDetails, setUpdateDetails] = useState<UpdateDetails>({
    slug: data.slug,
    data: []
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: ''
  });

  useEffect(() => {
    // @MM Run conditional checks before 'loading' data;
    adjustConditionalRequired(data.details, data.details);
    setDetails({ data: data });
  }, [data]);

  const adjustConditionalRequired = (baseInputs: any[], checkAgainst: any[]) => {
    // @MM Feels like unnecessary extra logic but it solves the immediate problem - forced to loop after every input 'blur' & on load & on data change;
    let requiresRender = false;
    // @MM Get all inputs with conditional validation;
    const conditionalInputs = baseInputs.filter(
      (detail: { validation: { conditional: { key?: any; text?:any, value?: any }; }; }) => {
        const conditional = detail.validation?.conditional;
        return (conditional && conditional.key && conditional.text && conditional.value) ?? false;
      }
    );
    conditionalInputs.map((input: any) => {
      // @MM Find input's conditional dependency - either (local change){key, value} or (from db){name, text};
      const dependant = checkAgainst.find((item: { name?: any; key?: any }) => (item.name ?? item.key) === input.validation.conditional.key);
      // @MM If {name, text} then compare vs input's conditional text;
      const textEquals = dependant?.text === input.validation.conditional.text;
      // @MM If {key, value} then compare vs input's conditional value;
      const valueEquals = dependant?.value === String(input.validation.conditional.value);
      // @MM Only set if changed;
      if (input.validation.required !== (textEquals || valueEquals)) {
        input.validation.required = textEquals || valueEquals;
        // @MM Re-check inputs validity; Can't use focus / blur as it 'toggles' the error messages;
        const el = document.querySelector<HTMLInputElement>(`input[id="${input.name}"]`);
        el && el.checkValidity();
        // @MM Prompt for re-render;
        requiresRender = true;
      }
    });
    return requiresRender;
  };
  const editDetails = () => {
    setDetailsState('edit');
  };

  const saveDetails = async () => {
    const { data } = updateDetails;
    const email = data.find((input: any) => (input.key.includes('email')));

    let validated: boolean = true;
    const inputs = document.querySelectorAll('input');
    const selects = document.querySelectorAll('select');

    inputs.forEach((input: any) => {
      if (input.validity.patternMismatch) {
        validated = false;
        input.focus();
        input.blur();
        return false;
      }
      if (input.required && !input.value) {
        validated = false;
        input.focus();
        input.blur();
        return false;
      }
    });

    selects.forEach((select: any) => {
      if (select.required && select.options.selectedIndex === 0) {
        validated = false;
        return false;
      }
    });

    if (validated) {
      if (email) {
        const duplicateCheck = {
          slug: 'user_email',
          // id: slug.split('-').pop(),
          value: email.value
        };
        const validator = await settingsServices.DuplicateValidator(duplicateCheck);
        if (!(validator?.success)) {
          setOpenAlert(true);
          setAlertMessage({
            message: validator?.message.data.message,
            severity: 'error'
          });
          return false;
        }
      }
      setDetailsState('read-only');
      onUpdate('update', updateDetails);
    } else {
      setOpenAlert(true);
      setAlertMessage({
        message: 'Make sure all required fields are filled in.',
        severity: 'error'
      });
    }
  };

  const deleteDetails = () => {
    onUpdate('delete', details.data.slug);
  };

  const updateInfo = (value:string, cellName:string) => {
    let newDataObject;
    details.data.details.map((info:any) => {
      if (info.name === cellName) {
        newDataObject = { key: cellName, value: value };
      }

      if (info.type === 'text_field_dropdown' && info.options.name === cellName) {
        newDataObject = { key: cellName, value: value };
      }
    });

    const currentState = updateDetails.data;
    const foundItem = currentState.find((keyValue) => (keyValue.key === cellName));
    if (foundItem) {
      // Replace the found item
      const rightIndex = currentState.indexOf(foundItem);
      currentState[rightIndex].value = value;
      // @MM Run conditional checks and re-set details if changes have been made;
      adjustConditionalRequired(details.data.details, currentState) && setDetails({ ...details });
    } else {
      // Create new item
      setUpdateDetails({
        ...updateDetails,
        data: [...updateDetails.data, newDataObject]
      });
      // @MM Run conditional check including the new object;
      adjustConditionalRequired(details.data.details, [...updateDetails.data, newDataObject]);
    }
  };

  const inputType = (input:any, i:number) => {
    const inputType = input.type;
    switch (inputType) {
    case 'empty':
      return (
        <div/>
      );
    case 'text':
      return (
        input.text
      );
    case 'text_field':
      return (
        <div key={i} className="detail">
          <BasicComponent.CustomTextField {...input} updateCell={updateInfo}/>
        </div>
      );
    case 'dropdown':
      return (
        <div key={i} className="detail">
          <BasicComponent.Dropdown keyName={input.name} label={input.label} val={input.dropdown.default} options={input.dropdown.options} validation={input.validation} onBlur={updateInfo}/>
        </div>
      );
    case 'date':
      return (
        <div key={i} className="detail">
          <BasicComponent.DatePicker keyName={input.name} label={input.label} val={input.value ? input.value : input.text} futureDates={input.future_dates} pastDates={input.past_dates} onUpdate={updateInfo}/>
        </div>
      );
    case 'text_area':
      return (
        <div key={i} className="detail">
          <FormControl key={`text-area-${i}`} className="col3-span">
            <InputLabel shrink htmlFor="label">{input.label}</InputLabel>
            <TextareaAutosize aria-label={input.label} rowsMin={3} placeholder="Comment" />
          </FormControl>
        </div>
      );
    case 'image':
      return (
        <div className="image">
          <BasicComponent.UploadImage keyName={input.name} upload={updateInfo} size={150}/>
        </div>
      );
    case 'google_maps':
      return (
        <div key={i} className="detail maps">
          <BasicComponent.GoogleMaps keyName={input.name} label={input.label} val={input.text} validation={input.validation} onUpdate={updateInfo}/>
        </div>
      );
    case 'text_field_dropdown':
      return (
        <div className="detail custom-text-field">
          <TextField
            key={`${input.name}-${i}`}
            id={`${input.name}-${i}`}
            label={input.label}
            defaultValue={input.value}
            disabled = {input.disabled}
            onBlur={(event) => updateInfo(event.target.value, input.name)}
            InputLabelProps={{
              shrink: true
            }}
            required={!!input.validation && input.validation.required}
          />
          <BasicComponent.Dropdown keyName={input.options.name} val={input.options.dropdown.default} options={input.options.dropdown.options} validation={input.validation} onBlur={updateInfo}/>
        </div>
      );

    default:
      break;
    }
  };

  return (
    <>
      <div className="heading underline two gcrg20">
        <h3 className="uppercase jstart">{details.data.heading}</h3>
        <div className="buttons jend">
          {details.data.buttons.map((button: any) => {
            if (button.action === 'Edit') {
              return (
                detailsState === 'read-only'
                  ? <IconButton key={button.slug} aria-label="edit" className="edit-btn" disabled={!button.has_permissions} onClick={() => editDetails()}><EditIcon color={!button.has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                  : <IconButton key={button.slug} aria-label="edit" className="edit-btn" disabled={!button.has_permissions} onClick={saveDetails}><SaveIcon color={!button.has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
              );
            } else if (button.action === 'Delete') {
              return (
                <>
                  <IconButton aria-label="delete" className="delete-btn" disabled={!button.has_permissions} onClick={() => setConfirmOpen(true)}><DeleteIcon color={!button.has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                  <BasicComponent.ConfirmDialog confirm={true} open={confirmOpen} setOpen={setConfirmOpen} onConfirm={deleteDetails} >
                    <p>Are you sure you want to delete this item?</p>
                  </BasicComponent.ConfirmDialog>
                </>
              );
            }
          })}
        </div>
      </div>
      <div className={`${detailsPanel} one gcrg20`}>
        <div className="five layout gcrg20">
          {detailsState === 'read-only'
            ? details.data.details.map((detail:any, i:number) => {
              if (detail.type === 'image') {
                return <div key={detail.slug} className="image">
                  <InputLabel shrink htmlFor="label">{detail.label}</InputLabel>
                  { !detail.image || detail.image === ''
                    ? <AccountCircleIcon fontSize="large" color="primary"/>
                    : <Image
                      src={detail.image}
                    />
                  }

                </div>;
              } else if (detail.type === 'text_field_dropdown') {
                return <div key={i} className="detail">
                  <InputLabel shrink htmlFor="label">{detail.label}</InputLabel>
                  <p>{detail.text} / {detail.options.text}</p>
                </div>;
              } else {
                return <div key={i} className="detail">
                  <InputLabel shrink htmlFor="label">{detail.label}</InputLabel>
                  <p>{(detail.type === 'date' && detail.currency?.text) || detail.text}</p>
                </div>;
              }
            })
            : details.data.details.map((detail:any, i:number) => (inputType(detail, i)))
          }
        </div>
      </div>
      <BasicComponent.CustomizedSnackbars message={alertMessage.message} severity={alertMessage.severity} onOpen={openAlert} onClose={(open:any) => setOpenAlert(open)}/>
    </>
  );
};
