import React, { useState, useEffect } from 'react';
import BasicComponent from '../../basicComponents/index';
import EnhancedComponent from '../index';
import { settingsServices } from '../../../services/api/helpers/settingsServices';
import './style.sass';
import { useSearchAndFilterContext } from 'src/context/searchAndFilterProvider';

interface onLoad {
  children?: React.ReactNode;
  projectId: number,
  settingType: string
}

interface PageData {
  projectId: number,
  data: any,
  pagination: any
}

export const CompanyDetails = (props:onLoad) => {
  const { filtersData, pagination, paginateSet } = useSearchAndFilterContext();
  const { projectId, settingType } = props;
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<PageData>({
    projectId: 1,
    data: [],
    pagination: {}
  });

  useEffect(() => {
    (async function () {
      await pageData();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, []);

  const pageData = async () => {
    const { filters } = filtersData;
    const body = {
      slug: 'read',
      data: { tab: settingType },
      filters: filters ?? [],
      pagination: {}
    };
    if (pagination && pagination.length > 0) body.pagination = pagination[0];

    const response = await settingsServices.crudGeneralSettings(body);
    if (response?.success) {
      const pageData = response.message.data.page_data;
      const { pagination } = response.message;
      setState({
        projectId,
        data: pageData,
        pagination
      });
      paginateSet(pagination ? [pagination] : []);
    } else {
      paginateSet(pagination);
    }
  };

  const onUpdate = async (updateState: any, data: any) => {
    const { filters } = filtersData;
    const body = {
      slug: updateState,
      data: {},
      filters: filters ?? [],
      pagination: {}
    };
    if (pagination && pagination.length > 0) body.pagination = pagination[0];

    if (updateState === 'update') {
      Object.assign(body.data, { tab: settingType, company_details: data.data });
      const response = await settingsServices.crudGeneralSettings(body);
      if (response?.message) {
        const pageData = response.message.data.page_data;
        const { pagination } = response.message;
        setState({
          ...state,
          data: pageData,
          pagination
        });
        paginateSet(pagination ? [pagination] : []);
      } else {
        paginateSet(pagination);
      }
    }
  };

  return (
    <>
      { loading
        ? <BasicComponent.Loader size={60} message="Loading data..." />
        : <>
          <BasicComponent.Details detailsPanel="company-details" data={state.data} onUpdate={onUpdate}/>
          <EnhancedComponent.PaymentGateway {...state.data.billing}/>
        </>
      }
    </>
  );
};
