import React, { useState, useContext } from 'react';
import { GlobalContext } from '../../../context/globalState';
import S3FileUpload from 'react-s3';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

interface Props {
  keyName: string,
  upload: any,
  size: number,
  folder?: string
}

export const UploadImage = ({ keyName, upload, size, folder }: Props) => {
  const { user } = useContext(GlobalContext);
  const [image, setImage] = useState({ preview: '', raw: '' });

  const config = {
    bucketName: process.env.REACT_APP_STORAGE_NAME,
    dirName: `assets/${user.company_name}/${folder || 'profilePictures'}`,
    region: 'eu-west-1',
    accessKeyId: process.env.REACT_APP_STORAGE_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_STORAGE_ACCESS_KEY
  };

  const handleChange = (e:any) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
      S3FileUpload.uploadFile(e.target.files[0], config)
        .then((data:any) => {
          upload(data.location, keyName);
        })
        .catch((err:any) => {
          alert(err);
        });
    }
  };

  return (
    <div className="upload">
      <label htmlFor="upload-button">
        {image.preview ? (
          <img src={image.preview} alt="dummy" width={size} />
        ) : (
          <>
            <CloudUploadIcon fontSize="large" />
            <h5 className="center">Upload</h5>
          </>
        )}
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: 'none' }}
        onChange={handleChange}
      />
    </div>
  );
};
