/* eslint-disable */
import * as React from 'react';

type NavFilterType = {
  navFilterOptions: any;
  filtering: boolean;
  navFilter: any;
  isActive: any;
  companyDashboardPermissions: boolean,
  projectDashboardPermissions: boolean,
  filteringSet: (toggle: boolean) => void;
  navIsActiveSet: (menuSlug: string, subMenuSlug: string) => void;
  navFilterOptionsSet: (filters: any) => void;
  navFilterFunc: (job_ids?: Array<[]>, status_ids?: any, search?: string, sort?: string) => void;
  changeFiltersFunc: (filters: any) => void;
  updateNavFilterOptions: (filters: any) => void;
  companyDashboardPermissionsSet: (permission: any) => void,
  projectDashboardPermissionsSet: (permission: any) => void,
}

interface NavFilterData {
  filters: {
    job_ids: Array<[]>,
    status_ids: Array<[]>,
    search: string,
    sort: string
  }
}

interface NavActiveState {
  menuSlug: string,
  subMenuSlug: string
}

export const NavFilterContext = React.createContext<NavFilterType>({
  navFilterOptions: {},
  filtering: false,
  navFilter: {},
  isActive: {},
  companyDashboardPermissions: false,
  projectDashboardPermissions: false,
  filteringSet: () => null,
  navIsActiveSet: () => null,
  navFilterOptionsSet: () => null,
  navFilterFunc: () => null,
  changeFiltersFunc: () => null,
  updateNavFilterOptions: () => null,
  companyDashboardPermissionsSet: () => null,
  projectDashboardPermissionsSet: () => null,
});

export const useNavFilterContext = () => React.useContext(NavFilterContext);

const NavFilterProvider: React.FC<{}> = ({ children }) => {
  const [filtering, setFiltering] = React.useState<boolean>(false);
  const [navFilterOptions, setNavFilterOptions] = React.useState({});
  const [navFilter, setNavFilter] = React.useState<NavFilterData>({
    filters: {
      job_ids: [],
      status_ids: [],
      search: '',
      sort: ''
    }
  });
  const [isActive, setIsActive] = React.useState<NavActiveState>({
    menuSlug: '',
    subMenuSlug: ''
  });
  const [companyDashboardPermissions, setCompanyDashboardPermissions] = React.useState(false);
  const [projectDashboardPermissions, setProjectDashboardPermissions] = React.useState(false);

  function companyDashboardPermissionsSet(permission: any) {
    setCompanyDashboardPermissions(permission);
  }

  function projectDashboardPermissionsSet(permission: any) {
    setProjectDashboardPermissions(permission);
  }

  function filteringSet (toggle: boolean) {
    setFiltering(toggle)
  }

  function navIsActiveSet (menuSlug: string, subMenuSlug: string) {
    setIsActive({
      menuSlug,
      subMenuSlug
    });
  }

  function updateNavFilterOptions (filters: any) {
    setFiltering(true);
    setNavFilterOptions(filters);
  }

  function navFilterOptionsSet (filters: any) {
    const { divisions, project_status } = filters;
    setFiltering(true);
    setNavFilterOptions(filters);
    navFilterFunc(divisions, project_status);
  }

  function navFilterFunc (job_ids?: any, status_ids?: any, search?: string, sort?: string) {
    setFiltering(true);
    const newFilter = {
      filters: {
        job_ids: !!job_ids ? jobIds(job_ids) : navFilter.filters.job_ids,
        status_ids: !!status_ids ? listItemsIds(status_ids) : navFilter.filters.status_ids,
        search: !!search ? search : navFilter.filters.search,
        sort: !!sort ? sort : navFilter.filters.sort
      }
    }
    setNavFilter(newFilter);
  }

  function jobIds (jobs: Array<[]>) {
    let filterJobIds: any[] = [];
    jobs.map(({ list_items }: any) => (
      list_items.forEach((item: any) => {
        filterJobIds.push(item.id);
      })
    ))
    return filterJobIds;
  }

  function listItemsIds (listItems: any) {
    const {list_items} = listItems;
    let filterStatusIds: any[] = [];
    list_items.forEach((item: any) => {
      filterStatusIds.push(item.id);
    })
    return filterStatusIds;
  }

  function changeFiltersFunc (filters: any) {
    setFiltering(true);
    setNavFilter(filters);
  }

  return (
    <NavFilterContext.Provider value={{
      navFilterOptions,
      filtering,
      navFilter,
      isActive,
      companyDashboardPermissions,
      projectDashboardPermissions,
      filteringSet,
      navIsActiveSet,
      navFilterOptionsSet,
      navFilterFunc,
      changeFiltersFunc,
      updateNavFilterOptions,
      companyDashboardPermissionsSet,
      projectDashboardPermissionsSet
    }}>
      {children}
    </NavFilterContext.Provider>
  );
};

export default NavFilterProvider;

// Build provider
// Create hook (expose context)
// index.tsx - context folder. Wrap childers with providers
// main app wrap in index.tsx
