import React from 'react';
import { GlobalProvider } from './context/globalState';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Routes from './routes/routes';
import './App.css';
import { AppProvider } from './context';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1884F0' },
    secondary: { main: '#001C38' }
  },
  overrides: {
    MuiButton: { root: { borderRadius: '30px', fontSize: '15px' }, label: { textTransform: 'initial' } },
    MuiFormControlLabel: { root: { marginRight: '0px' } },
    MuiInputBase: { input: { fontSize: '14px', border: '1px solid black', borderRadius: '5px', padding: '5px' } },
    MuiFormControl: { root: { display: 'grid' } },
    MuiPopover: { paper: { borderRadius: '20px' } }
  }
});

const App = () => {
  return (
    <GlobalProvider>
      <AppProvider>
        <MuiThemeProvider theme={theme}>
          <Routes/>
        </MuiThemeProvider>
      </AppProvider>
    </GlobalProvider>
  );
};

export default App;
