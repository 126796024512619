import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import BasicComponent from '../../../components/basicComponents/index';
import EnhancedComponent from '../../../components/enhancedComponents';
import {
  Button
} from '@material-ui/core';
import { userServices } from '../../../services/api/helpers/userServices';
import './style.css';

interface ProfileDetails {
  pageData: PageData
}

interface PageData {
  name: string,
  buttons: any,
  slug: string,
  details: any
}

const Profile = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<ProfileDetails>({
    pageData: {
      name: '',
      buttons: [],
      slug: '',
      details: {}
    }
  });

  useEffect(() => {
    (async function () {
      await pageData();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, []);

  const pageData = async () => {
    const body = {
      slug: 'read',
      data: {}
    };

    const response = await userServices.userProfile(body);
    if (response?.success) {
      const pageData = response.message.data.page_data;
      setState({
        ...state,
        pageData: {
          name: pageData.name,
          buttons: pageData.buttons,
          slug: pageData.slug,
          details: pageData.sections.user_details
        }
      });
    }
  };

  const onUpdate = async (updateState: any, data: any) => {
    /** Save data
     * Data received from the children
     * On success receive response object to update state or reload the page data */
    const body = {
      slug: updateState,
      data: {}
    };

    if (updateState === 'update') {
      Object.assign(body.data, { item: data.data });

      const response = await userServices.userProfile(body);
      if (response?.success) {
        const pageData = response.message.data.page_data;
        setState({
          ...state,
          pageData: {
            ...state.pageData,
            name: pageData.name,
            slug: pageData.slug,
            details: pageData.sections.user_details
          }
        });
      }
    }
  };

  return (
    <>
      <EnhancedComponent.SideDrawer/>
      <div className="container">
        { loading
          ? <BasicComponent.Loader size={60} message="Loading data..." />
          : <section className="profile-settings gcrg30">
            <div className="heading two gcrg20">
              <div className="text two gcg10">
                <div className="icon"><h1 className="uppercase acenter jstart">{state.pageData.name.charAt(0)}</h1></div>
                <h1 className="uppercase pc1 acenter jstart">{state.pageData.name}</h1>
              </div>
              {state.pageData.buttons.map(({ action, slug, name }: any) => {
                if (action === 'Back') { return <Button key={slug} className="btn small jend" variant="contained" color="primary" onClick={() => history.push('/')}>{name}</Button>; }
              })}
            </div>
            <div className="card one grg20">
              <div className="profile-details one grg20">
                <BasicComponent.Details detailsPanel="profile-details" data={state.pageData.details} onUpdate={onUpdate}/>
              </div>
            </div>
          </section>
        }
      </div>
    </>
  );
};

export default Profile;
