import React, { useEffect, useState } from 'react';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import BasicComponent from '../../../components/basicComponents/index';
import EnhancedComponent from '../../../components/enhancedComponents';
import { settingsServices } from '../../../services/api/helpers/settingsServices';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  TablePagination,
  IconButton,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Checkbox,
  Button
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import '.././style.css';

interface UpdateTableRow {
  slug: string,
  data: Array<any>
}

const UPDATE_TABLE_ROW_SKELETON = {
  slug: '',
  data: []
};
const Row = (row:any) => {
  const { cells, collapsed_rows_included, row_slug, buttons, onUpdate } = row;
  const [tableState, setTableState] = useState(!!row_slug.includes('-0'));
  const [expanded, setExpandable] = useState(!!row_slug.includes('-0'));
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [updateTableRow, setUpdateTableRow] = useState<UpdateTableRow>(UPDATE_TABLE_ROW_SKELETON);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: ''
  });

  const ExpandTable = () => {
    setExpandable((state: boolean) => {
      setUpdateTableRow && setUpdateTableRow(UPDATE_TABLE_ROW_SKELETON);
      return !state;
    });
    setTableState(false);
  };

  const editContent = () => {
    setTableState(true);
    setExpandable(true);
  };

  const copyContent = () => {};

  const deleteContent = () => {
    onUpdate('delete', row_slug);
  };

  const saveContent = async () => {
    const { slug, data } = updateTableRow;
    const jobNumber = data.find((input: any) => (input.key.includes('job_number')));
    const inputs = document.querySelectorAll('input');
    const selects = document.querySelectorAll('select');
    let validated: boolean = true;

    inputs.forEach((input: any) => {
      if (input.validity.patternMismatch) {
        validated = false;
        input.focus();
        input.blur();
        return false;
      }
      if (input.required && !input.value) {
        validated = false;
        input.focus();
        input.blur();
        return false;
      }
    });

    selects.forEach((select: any) => {
      if (select.required && select.options.selectedIndex === 0) {
        validated = false;
        select.focus();
        return false;
      }
    });

    if (validated) {
      if (jobNumber) {
        const duplicateCheck = {
          slug: jobNumber.key,
          id: slug.split('-').pop(),
          value: jobNumber.value
        };
        const validator = await settingsServices.DuplicateValidator(duplicateCheck);
        if (!(validator?.success)) {
          setOpenAlert(true);
          setAlertMessage({
            message: validator?.message.data.message,
            severity: 'error'
          });
          return false;
        }
      }
      setTableState(false);
      setExpandable(false);
      onUpdate('update', updateTableRow);
    } else {
      setOpenAlert(true);
      setAlertMessage({
        message: 'Make sure all required fields are filled in.',
        severity: 'error'
      });
    }
  };

  const updateCell = (value:string, cellName:string) => {
    /** Update table cell.
     * push data to update State called 'updateTableRow' for global save. */
    /** Parent cell update */
    let newDataObject;
    cells.forEach((cell:any) => {
      if (cell.name === cellName) {
        newDataObject = { key: cellName, value: value };
      }
    });
    /** Collapsed Rows cell update */
    collapsed_rows_included.forEach((collapsedRows:any) => {
      collapsedRows.cells.forEach((cell:any) => {
        if (cell.name === cellName) {
          newDataObject = { key: cellName, value: value };
        }
      });
    });

    const currentState = updateTableRow.data;
    const foundItem = currentState.find((keyValue) => (keyValue.key === cellName));
    if (foundItem) {
      // Replace the found item
      const rightIndex = currentState.indexOf(foundItem);
      currentState[rightIndex].value = value;
    } else {
      // Create new item
      setUpdateTableRow({
        ...updateTableRow,
        slug: row_slug,
        data: [...updateTableRow.data, newDataObject]
      });
    }
  };

  const inputType = (input:any, i:number) => {
    /** Component render switch.
     * Whenever you edit a cell
     */
    const inputType = input.type;
    switch (inputType) {
    case 'empty':
      return (
        <div/>
      );
    case 'text':
      return (
        input.text
      );
    case 'text_field':
      return (
        <BasicComponent.CustomTextField {...input} updateCell={updateCell}/>
      );
    case 'checkbox':
      return (
        <Checkbox
          key={`checkbox-${i}`}
          defaultChecked
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      );
    case 'dropdown':
      return (
        <BasicComponent.Dropdown keyName={input.name} label={input.label} val={input.dropdown.default} options={input.dropdown.options} validation={input.validation} onBlur={updateCell}/>
      );
    case 'date':
      return (
        <BasicComponent.DatePicker keyName={input.name} label={input.label} val={input.text} validation={input.validation} futureDates={input.future_dates} pastDates={input.past_dates} onUpdate={updateCell}/>
      );
    case 'text_area':
      return (
        <FormControl key={`text-area-${i}`} className="col3-span">
          <InputLabel shrink htmlFor="label">{input.label}</InputLabel>
          <TextareaAutosize aria-label={input.label} rowsMin={3} placeholder="Comment" />
        </FormControl>
      );

    default:
      break;
    }
  };

  return (
    <>
      <TableRow className={`collapse-parent ${!expanded ? '' : 'opened'}`}>
        {cells.map((value:any, i:number) => (
          {
            ...!tableState
              ? <TableCell key={`tbodyCell-${i}`} align={value.align}> { expanded ? <InputLabel>{value.label}</InputLabel> : null } {value.text}</TableCell>
              : <TableCell key={`tbodyCell-${i}`} align={value.align}>{inputType(value, i)}</TableCell>
          }
        ))}
        { buttons &&
          <TableCell key={row.id} className="table-btns">
            <div className="buttons">
              <IconButton aria-label="expand row" className="dropdown-btn" size="small" onClick={ExpandTable}>{expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
              { buttons.map(({ action, has_permissions }: any) => {
                if (action === 'Edit') {
                  return (
                    {
                      ...!tableState
                        ? <IconButton aria-label="edit" className="edit-btn" disabled={!has_permissions} onClick={() => editContent()}><EditIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                        : <IconButton aria-label="edit" className="edit-btn" disabled={!has_permissions} onClick={saveContent}><SaveIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                    }
                  );
                } else if (action === 'Copy') {
                  return <IconButton aria-label="copy" className="copy-btn" disabled={!has_permissions} onClick={() => copyContent()}><FileCopyIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>;
                } else if (action === 'Delete') {
                  return (
                    <>
                      <IconButton aria-label="delete" className="delete-btn" disabled={!has_permissions} onClick={() => setConfirmOpen(true)}><DeleteIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                      <BasicComponent.ConfirmDialog confirm={true} open={confirmOpen} setOpen={setConfirmOpen} onConfirm={deleteContent} >
                        <p>Are you sure you want to delete this item?</p>
                      </BasicComponent.ConfirmDialog>
                    </>
                  );
                }
              })}
            </div>
          </TableCell>
        }
      </TableRow>
      <TableRow className={`collapse-child ${!expanded ? '' : 'opened-container'}`}>
        <TableCell className="collapsed" colSpan={cells.length + 1}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Table>
              <TableBody>
                {collapsed_rows_included.map((collapsedRows:any, i:number) => (
                  <TableRow key={i}>
                    { !tableState
                      ? collapsedRows?.cells.map((value:any) => (
                        value.label
                          ? <TableCell key={value.name} align={value.align}><InputLabel>{value.label}</InputLabel>{value.text}</TableCell>
                          : <TableCell key={value.name} align={value.align}>{value.text}</TableCell>
                      ))
                      : collapsedRows?.cells.map((value:any) => (
                        <TableCell key={value.name} align={value.align}>{inputType(value, i)}</TableCell>
                      ))
                    }
                    <TableCell key="empty" align="left" className="empty-cell"><div></div></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
      <BasicComponent.CustomizedSnackbars message={alertMessage.message} severity={alertMessage.severity} onOpen={openAlert} onClose={(open:any) => setOpenAlert(open)}/>
    </>
  );
};

export const DynamicSettingsCollapsibleTable = (props: any) => {
  const { paginate } = useSearchAndFilterContext();
  const { tableName, filters, total_results, onUpdate, section_type, pagination } = props;
  const { slug } = props.data;
  const { header_row, body, buttons } = props.data.table;

  const [open, setOpen] = useState(false);
  const [currentTarget, setCurrentTarget] = useState();
  const [valueData, setValueData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setPage(pagination?.page ?? 0);
    setRowsPerPage(pagination?.rows_per_page ?? 10);
  }, [pagination]);
  /** Search and filter functionality
   * Start
  */
  const openState = () => {
    setOpen(false);
  };

  const searchAndFilter = ({ currentTarget }: any, value: any) => {
    const { category_name } = value;
    if (category_name) {
      setOpen(true);
      setCurrentTarget(currentTarget);
      setValueData(value);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    paginate(rowsPerPage, newPage, section_type);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    paginate(parseInt(event.target.value), 0, section_type);
  };
  /** Search and filter functionality
   * end
  */

  const createTableItem = () => {
    const newRow = {
      cells: [
        {
          type: 'text_field',
          align: 'left',
          name: 'job-1',
          input: null,
          dropdown: null,
          button: null,
          text: 'N/A',
          date: null,
          span: null
        },
        {
          type: 'text_field',
          align: 'left',
          name: 'job-2',
          input: null,
          dropdown: null,
          button: null,
          text: 'N/A',
          checked: true,
          date: null,
          span: null
        },
        {
          type: 'text_field',
          align: 'left',
          name: 'job-3',
          input: null,
          dropdown: null,
          button: null,
          text: 'N/A',
          date: null,
          span: null
        },
        {
          type: 'text_field',
          align: 'left',
          name: 'job-4',
          input: null,
          dropdown: null,
          button: null,
          text: 'N/A',
          date: null,
          span: null
        }
      ],
      collapsed_rows_included: [
        {
          cells: [
            {
              type: 'date',
              align: 'left',
              name: 'more_details-1',
              label: 'START DATE',
              dropdown: null,
              button: null,
              text: 'N/A',
              date: null
            },
            {
              type: 'text_field',
              align: 'left',
              name: 'more_details-2',
              label: 'JOB DURATION (MONTHS)',
              dropdown: null,
              button: null,
              text: 'N/A',
              date: null
            },
            {
              type: 'dropdown',
              align: 'left',
              name: 'more_details-3',
              label: 'MONTHLY ROLL OVER DATE',
              dropdown: {
                options: [
                  {
                    name: '1st Day of the Month',
                    slug: 'roll_over-1',
                    id: 1,
                    placeholder: null
                  },
                  {
                    name: '15th Day of the Month',
                    slug: 'roll_over-2',
                    id: 2,
                    placeholder: null
                  },
                  {
                    name: '28th Day of the Month',
                    slug: 'roll_over-3',
                    id: 3,
                    placeholder: null
                  }
                ],
                default: 2
              },
              button: null,
              text: 'N/A',
              date: null
            },
            {
              type: 'dropdown',
              align: 'left',
              name: 'more_details-4',
              label: 'SELECTED BUDGET',
              dropdown: {
                options: [
                  {
                    name: 'Budget 1',
                    slug: 'roll_over-1',
                    id: 1,
                    placeholder: null
                  },
                  {
                    name: 'Budget 2',
                    slug: 'roll_over-2',
                    id: 2,
                    placeholder: null
                  },
                  {
                    name: 'Budget 3',
                    slug: 'roll_over-3',
                    id: 3,
                    placeholder: null
                  }
                ],
                default: 0
              },
              button: null,
              text: 'N/A',
              date: null
            },
            {
              type: 'text_field',
              align: 'left',
              name: 'more_details-5',
              label: 'ALERT AT',
              dropdown: null,
              button: null,
              text: 'N/A',
              date: null
            },
            {
              type: 'dropdown',
              align: 'left',
              name: 'more_details-6',
              label: 'JOB CURRENCY',
              dropdown: {
                options: [
                  {
                    name: 'R - South African Rand',
                    slug: 'currency-1',
                    id: 1,
                    placeholder: null
                  },
                  {
                    name: 'USD - US Dollar',
                    slug: 'currency-2',
                    id: 2,
                    placeholder: null
                  },
                  {
                    name: 'EUR - Euro',
                    slug: 'currency-3',
                    id: 3,
                    placeholder: null
                  }
                ],
                default: 0
              },
              button: null,
              text: 'N/A',
              date: null
            },
            {
              type: 'text',
              align: 'left',
              name: 'more_details-7',
              label: '',
              dropdown: null,
              button: null,
              text: '',
              date: null
            }
          ],
          collapsed_rows_included: null,
          collapsed_rows_slug: 'more_details'
        }
      ],
      redirect: false,
      row_slug: `job_manager_details-${body.length + 1}`
    };

    body.push(newRow);
    onUpdate(slug, body);
  };

  return (
    <>
      <TableContainer className={tableName}>
        { !!filters && filters.length > 0
          ? <BasicComponent.FilterResults filters={filters}/>
          : null
        }
        <Table stickyHeader>
          <TableHead className="thead">
            <TableRow>
              {header_row.cells.map((value:any, i:number) => (
                <TableCell key={`theadCell-${i}`} align={value.align} onClick={(event) => searchAndFilter(event, value)}>{value.text}</TableCell>
              ))}
              <EnhancedComponent.SearchAndFilter open={open} currentTarget={currentTarget} data={valueData} openState={openState}/>
            </TableRow>
          </TableHead>
          <TableBody className="tbody">
            {body.map((row:any) => (
              <Row key={row.row_slug} {...row} buttons={buttons} onUpdate={onUpdate}/>
            ))}
          </TableBody>
        </Table>
        {buttons && buttons.map(({ action, name, slug }: any) => {
          if (action === 'Create') { return <Button key={slug} className="btn small jstart" variant="contained" color="primary" onClick={createTableItem}>{name}</Button>; }
        })}
        <TablePagination
          component="div"
          count={total_results}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};
