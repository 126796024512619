import React from 'react';
import {
  CircularProgress
} from '@material-ui/core';

import './loader.styles.css';

interface Props {
  className?: string,
  size: number,
  message: string
}

export const Loader = ({ className, size, message }:Props) => (
  <div className={`${className || ''} loader`}>
    <CircularProgress className="jcenter" size={size}/>
    {message ? <p className="center">{message}</p> : null}
  </div>
);
