import React, { useState } from 'react';
import BasicComponent from '../index';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

export const NestedCheckboxList = (props: any) => {
  const { name } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className="list"
      dense={true}
    >
      <ListItem button disableRipple onClick={handleClick}>
        {open ? <ExpandLess /> : <ExpandMore />}
        <ListItemText primary={name} />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <BasicComponent.CheckboxList {...props} name="divisions"/>
      </Collapse>
    </List>
  );
};
