import React, { useState, useEffect } from 'react';
import { useTableContext } from '../../../context/tableProvider';
import BasicComponent from '../../../components/basicComponents/index';
import {
  TextField, Button, Popover, IconButton
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import './input-type.styles.sass';

interface OtherCharges {
  name: string,
  label: string,
  value: any,
  options: any,
  charges: Array<any>,
  validation: any,
  rowId: string,
  onBlur: any
}

const Charge = (props: any) => {
  const { updateSet, deleteSet, otherCharges, otherChargesSet } = useTableContext();
  const { id, name, value, text, rowId, currencySymbol } = props;
  const [edit, setEdit] = useState(!id);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [charge, setCharge] = useState({
    id,
    name,
    value,
    text
  });

  useEffect(() => {
    setCharge({ ...charge, id: id });
  }, [id]);

  const saveCharge = () => {
    const data = {
      row_id: parseInt(rowId.split('-')[1]),
      charges: charge
    };
    updateSet(data);
    setEdit(false);
  };

  const deleteCharge = () => {
    if (id === null) {
      otherChargesSet(otherCharges.charges.filter(charge => charge.id !== null));
    } else {
      const data = {
        row_id: parseInt(rowId.split('-')[1]),
        charge_id: id
      };
      deleteSet(data);
    }
  };

  return (
    <div className="charge three gcg10">
      { edit
        ? <>
          <TextField
            key={`charge-${id}`}
            defaultValue={name}
            disabled = {!edit}
            onBlur={(event) => setCharge({ ...charge, name: event.target.value })}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            key={name}
            defaultValue={value}
            disabled = {!edit}
            onBlur={(event) => setCharge({ ...charge, value: event.target.value, text: currencySymbol + event.target.value })}
            InputLabelProps={{
              shrink: true
            }}
          />
        </>
        : <>
          <p>{name}</p>
          <p>{text}</p>
        </>
      }
      <div className="charge-btns">
        { !edit
          ? <IconButton
            aria-label="edit"
            className="edit-btn"
            onClick={() => setEdit(true)}
          ><EditIcon fontSize="small"/></IconButton>
          : <IconButton
            aria-label="edit"
            className="edit-btn"
            onClick={saveCharge}
          ><SaveIcon fontSize="small"/></IconButton>
        }
        <IconButton
          aria-label="delete"
          className="delete-btn"
          onClick={() => setConfirmOpen(true)}
        ><DeleteIcon fontSize="small"/></IconButton>
        <BasicComponent.ConfirmDialog confirm={true} open={confirmOpen} setOpen={setConfirmOpen} onConfirm={deleteCharge} >
          <p>Are you sure you want to delete this item?</p>
        </BasicComponent.ConfirmDialog>
      </div>
    </div>
  );
};

export const OtherCharges: React.FC<OtherCharges> = (props: OtherCharges) => {
  const { name, label, value, options, charges, validation, rowId, onBlur } = props;
  const { otherCharges, otherChargesSet, updateChargeFunc } = useTableContext();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<string | undefined>(undefined);
  const [otherChargesTotal, setOtherChargesTotal] = useState<number>(value);

  useEffect(() => {
    otherChargesSet(charges);
  }, []);

  useEffect(() => {
    const isOpen = Boolean(anchorEl);
    setOpen(isOpen);
    setId(isOpen ? 'charges-popover' : undefined);
  }, [anchorEl]);
  useEffect(() => {
    setOtherChargesTotal(totalCharges(otherCharges.charges));
  }, [otherCharges.charges]);

  const totalCharges = (charges: any) => {
    let total = 0;
    if (charges.length > 0) {
      charges.forEach((charge: any) => {
        total += parseFloat(charge.value);
      });
    }
    return Number(total);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onBlur(otherChargesTotal, name);
  };

  const addCharges = () => {
    const newCharge = {
      id: null,
      name: '',
      value: 0,
      text: 'R0'
    };
    updateChargeFunc(newCharge);
  };

  const disabledState = () => {
    const disabled = otherCharges.charges.find((charge:any) => (charge.id === null));
    if (disabled) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="text_dropdown_button">
      <TextField
        key={name}
        label={`${label} / ${options.label}`}
        defaultValue={otherChargesTotal.toFixed(2)}
        value={otherChargesTotal.toFixed(2)}
        disabled = {true}
        // onBlur={(event) => updateCell(event.target.value, input.name)}
        InputLabelProps={{
          shrink: true
        }}
      />
      <BasicComponent.Dropdown keyName={options.name} val={options.dropdown.default} options={options.dropdown.options} validation={validation} onBlur={onBlur}/>
      <IconButton aria-label="edit" className="other-charges-btn" onClick={handleClick}><SettingsApplicationsIcon fontSize="small"/></IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className="card one grg10">
          <h2 className="uppercase title">Other Charges</h2>
          <div className="charges one grg5">
            <div className="headings two gcg10">
              <h2>Charge</h2>
              <h2>Amount</h2>
            </div>
            { otherCharges.charges.map((charge: any, i: number) => (
              <Charge key={`charge-${i}`} {...charge} currencySymbol={otherCharges.currency_symbol} rowId={rowId}/>
            ))}
            <div className="total three gcrg10">
              <Button className="add-btn" variant="contained" color="primary" disabled={disabledState()} onClick={addCharges}>+ Charges</Button>
              <h2 className="uppercase jend">total</h2>
              <p>{otherCharges.currency_symbol + otherChargesTotal.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};
