/* eslint-disable */
import * as React from 'react';

type SettingsType = {
  reloadDetails: boolean,
  reloadDetailsSet: (toggle:boolean) => void;
}

export const SettingsContext = React.createContext<SettingsType>({
  reloadDetails: false,
  reloadDetailsSet: () => null,
});

export const useSettingsContext = () => React.useContext(SettingsContext);

const SettingsProvider: React.FC<{}> = ({ children }) => {
  const [reloadDetails, setReloadDetails] = React.useState(false);

  function reloadDetailsSet (toggle:boolean) {
    setReloadDetails(toggle);
  }
  
  return (
    <SettingsContext.Provider value={{
      reloadDetails,
      reloadDetailsSet
    }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;

// Build provider
// Create hook (expose context)
// index.tsx - context folder. Wrap childers with providers
// main app wrap in index.tsx
