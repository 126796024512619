import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AscendingIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <g xmlns="http://www.w3.org/2000/svg">
        <polygon points="20,21 16,21 20,15 20,13 13,13 13,15 17,15 13,21 13,23 20,23   "/>
        <path d="M18,2h-2l-4,10h3l1-2h2l1,2h3L18,2z M16,8l1-2l0,0l1,2H16z"/>
        <polygon points="8,2 5,2 5,17 2,17 6.5,23 11,17 8,17   "/>
      </g>
    </SvgIcon>
  );
};

const DescendingIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <g xmlns="http://www.w3.org/2000/svg">
        <polygon points="20,10 16,10 20,4 20,2 13,2 13,4 17,4 13,10 13,12 20,12   "/>
        <path d="M18,13h-2l-4,10h3l1-2h2l1,2h3L18,13z M16,19l1-2l0,0l1,2H16z"/>
        <polygon points="8,2 5,2 5,17 2,17 6.5,23 11,17 8,17   "/>
      </g>
    </SvgIcon>
  );
};

const FilterIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path xmlns="http://www.w3.org/2000/svg" d="M22.9,1.7C23,2.1,23,2.5,22.6,2.8L15,10.4v11.5c0,0.4-0.2,0.7-0.6,0.9c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.5-0.1-0.7-0.3  l-4-4C9.1,18.5,9,18.2,9,18v-7.5L1.4,2.8C1,2.5,1,2.1,1.1,1.7c0.2-0.4,0.5-0.6,0.9-0.6h19.9C22.4,1.1,22.7,1.3,22.9,1.7z"/>
    </SvgIcon>
  );
};

export default {
  AscendingIcon,
  DescendingIcon,
  FilterIcon
};
