import { postRequest } from '../root/restRequests';
import { endpoints } from '../root/endPoints';
import { globalLocalStorage } from '../../storage/localstorage';

const crudCatalogue = async (body:any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(`settings/${endpoints.catalogue}`, body, token);
  return response;
};

const crudGeneralSettings = async (body:any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(`settings/${endpoints.generalSettings}`, body, token);
  return response;
};

const crudClientAndJobSettingsSettings = async (body:any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(`settings/${endpoints.clientAndJobSettings}`, body, token);
  return response;
};

const crudSettings = async (endpoint:string, body:any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(`settings/${endpoint}`, body, token);
  return response;
};

const SubscriptionRequest = async (body:any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(endpoints.subscriptionRequest, body, token);
  return response;
};

const SubscriptionCancel = async (body:any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(endpoints.subscriptionCancel, body, token);
  return response;
};

const DuplicateValidator = async (body:any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(endpoints.duplicateValidator, body, token);
  return response;
};

export const settingsServices = {
  crudCatalogue,
  crudGeneralSettings,
  crudClientAndJobSettingsSettings,
  crudSettings,
  SubscriptionRequest,
  SubscriptionCancel,
  DuplicateValidator
};
