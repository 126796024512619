import React from 'react';
import {
  BarChart, Bar, XAxis,
  YAxis, CartesianGrid,
  Tooltip, Legend
} from 'recharts';
// import { Grid } from '@material-ui/core';
import { BAR_COLORS as COLORS, ChartLegend } from '../chartLegend/chartLegend.component';
import './bar-chart.sass';

interface CustomizedShape {
  fill: string,
  x: number,
  y: number,
  width: number,
  height: number
};

export interface BarChartComponentProps{
  width:number, height:number, data:any[], currencySymbol?:any, showLegend:boolean,
    heading?:string, showYAxisLabel:boolean, showTickLabel:boolean
}

export const BarChartComponent = (props: BarChartComponentProps) => {
  const {
    width, height, data, currencySymbol, showLegend,
    heading, showYAxisLabel, showTickLabel
  } = props;

  return (
    <>
      {
        heading &&
          <h3>{heading}</h3>
      }
      <div className="bar-chart">
        <BarChart
          width={width || 640}
          height={height || 300}
          data={data}
        >
          <Tooltip
            contentStyle={{ borderRadius: 5, backgroundColor: 'rgba(240,240,240,0.85)' }}
            labelStyle={{ fontWeight: 'bold' }}
            itemStyle={{ fontWeight: 600 }}
          />
          <CartesianGrid
            vertical={false}
          />
          {
            showTickLabel && <XAxis
              dataKey={'name'}
              interval={0}
              height={80}
              tick={<CustomizedAxisTick />}
              tickLine={false}
            />
          }
          <YAxis
            label={ showYAxisLabel
              ? { value: `Ammount (${currencySymbol})`, position: 'insideLeft', angle: -90, offset: -1 } : {}
            }
            axisLine={false}
            tickLine={false}
            tickFormatter={yAxisTickFormatter}
          />
          <Bar
            isAnimationActive
            legendType={'square'}
            animationEasing={'ease'}
            animationBegin={500}
            animationDuration={1500}
            shape={<CustomizedShape/>}
            dataKey={'actualPayment'} fill={COLORS[0]} name={'Actual Payment'}/>
          <Bar
            isAnimationActive
            animationEasing={'ease'}
            animationBegin={500}
            animationDuration={1500}
            legendType={'square'}
            shape={<CustomizedShape/>}
            dataKey={'actualVariance'} fill={COLORS[1]} name={'Actual Value Variance'}/>
          <Bar
            isAnimationActive
            animationEasing={'ease'}
            animationBegin={500}
            animationDuration={1500}
            legendType={'square'}
            shape={<CustomizedShape/>}
            dataKey={'budgetPayment'} fill={COLORS[2]} name={'Budget Value'}/>
          <Bar
            isAnimationActive
            animationEasing={'ease'}
            animationBegin={500}
            animationDuration={1500}
            legendType={'square'}
            shape={<CustomizedShape/>}
            dataKey={'budgetVariance'} fill={COLORS[3]} name={'Budget Value Variance'}/>
          {
            showLegend && true && <Legend
              align={'right'}
              verticalAlign={'top'}
              layout={'vertical'}
              content={renderLegend}
              // wrapperStyle={{ paddingLeft: 30, paddingTop: 50 }}
            />
          }
        </BarChart>
      </div>
    </>
  );
};

const yAxisTickFormatter = (props:number) => {
  const precision = 1;
  const abbrev = ['', 'K', 'M', 'B', 'T'];
  const unrangifiedOrder = Math.floor(Math.log10(Math.abs(props)) / 3);
  const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length - 1));
  const suffix = abbrev[order];
  return (props / Math.pow(10, order * 3)).toFixed(precision) + suffix;
};

const renderLegend = (props:any) => {
  const { payload } = props;
  const legend = payload.map((load: any) => ({ name: load.value, color: load.color }));
  return ChartLegend(legend);
};

const truncate = (str:string, n:number) => {
  return (str.length > n) ? str.substr(0, n - 1) : str;
};

const concatName = (name:any) => {
  if (name.length > 11 && name.includes('&')) {
    const firstPart = truncate(name.split(' & ')[0], 5);
    const secondPart = truncate(name.split(' & ')[1], 5);

    return `${firstPart} & ${secondPart}`;
  } else if (name.length > 11) {
    const firstPart = truncate(name, 5);

    return firstPart;
  } else {
    return name;
  }
};

const CustomizedAxisTick = (props:any) => {
  const {
    x, y, payload
  } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        fontWeight={'bold'}
        textAnchor={'end'}
        fill={'#000'}
        transform={'rotate(-45)'}
      >
        {
          concatName(payload.value)
        }
      </text>
    </g>
  );
};

const CustomizedShape = (props:any) => {
  const {
    fill, x, y, width, height
  } = props;

  if (height < 0) {
    return <rect x={x} y={y + height} width={width} height={height * -1} rx={3} fill={fill} />;
  }
  return <rect x={x} y={y} width={width} height={height} rx={3} fill={fill} />;
};
