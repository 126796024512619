import React from 'react';
import {
  BarChart, Bar, XAxis,
  YAxis, CartesianGrid,
  Tooltip
} from 'recharts';
import { BAR_COLORS as COLORS } from '../chartLegend/chartLegend.component';
import BasicComponent from './../';
import './bar-chart.sass';

interface CustomizedShape {
  fill: string,
  x: number,
  y: number,
  width: number,
  height: number
};

export interface BarChartComponentProps{
  width:number, height:number, data:any[], showLegend:boolean,
    heading?:string, showYAxisLabel:boolean, showTickLabel:boolean
}

// const names = ['actualPayment', 'actualVariance', 'budgetPayment', 'budgetVariance'];

export const SimpleBarChartComponent = (props: BarChartComponentProps) => {
  const {
    width, height, data, showLegend,
    heading, showTickLabel
  } = props;

  return (
    <>
      {
        heading &&
        <h3>{heading}</h3>
      }
      <div className="chart">
        <div className="percentage">
          <BarChart
            width={width || 640}
            height={height || 300}
            data={data}
          >
            <Tooltip
              contentStyle={{ borderRadius: 5, backgroundColor: 'rgba(240,240,240,0.85)' }}
              labelStyle={{ fontWeight: 'bold' }}
              itemStyle={{ fontWeight: 600 }}
            />
            <CartesianGrid
              vertical={false}
            />
            {
              showTickLabel && <XAxis
                dataKey={'name'}
                height={80}
                tick={<CustomizedAxisTick />}
              />
            }
            <YAxis
              axisLine={false}
              unit={'%'}
              tickLine={false}
            />
            <Bar
              isAnimationActive
              legendType={'square'}
              animationEasing={'ease'}
              animationBegin={500}
              animationDuration={1500}
              shape={<CustomizedShape/>}
              dataKey={'value'}
              name={'Actual Payment'}
            />
          </BarChart>
        </div>
        {
          showLegend &&
            <div className="legend">
              {customLegend(data)}
            </div>
        }
      </div>
    </>
  );
};
const names: string[] = [];
const customLegend = (data:any[]) => {
  const pieLegend = data.map((item, index) => {
    names.push(item.name);
    return {
      name: item.name,
      percentage: `${(item.value).toFixed(0)}%`,
      color: COLORS[index % COLORS.length]
    };
  });
  return BasicComponent.PieChartLegend(pieLegend);
};

const CustomizedAxisTick = (props:any) => {
  const {
    x, y, payload
  } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        fontWeight={'bold'}
        textAnchor={'end'}
        fill={'#000'}
        transform={'rotate(-25)'}
      >
        {
          payload.value
        }
      </text>
    </g>
  );
};
const CustomizedShape = (props:any) => {
  const {
    x, y, width, height, name
  } = props;

  if (height < 0) {
    return <rect x={x} y={y + height} width={width} height={height * -1} rx={3} fill={COLORS[names.indexOf(name) % COLORS.length]} />;
  }
  return <rect x={x} y={y} width={width} height={height} rx={3} fill={COLORS[names.indexOf(name) % COLORS.length]} />;
};
