import { postRequest } from '../root/restRequests';
import { endpoints } from '../root/endPoints';
import { globalLocalStorage } from '../../storage/localstorage';

const getDashboard = async (body:any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(`${endpoints.dashboard}`, body, token);
  return response;
};

const toggleCheckboxDashboard = async (body:any) => {
  const token = globalLocalStorage.getItem('api_access_token');
  if (!token) return null;
  const response = await postRequest(`${endpoints.dashboard}`, body, token);
  return response;
};

export const dashboardServices = {
  getDashboard,
  toggleCheckboxDashboard
};
