/* eslint-disable */
import React from 'react';
import NotificationsProvider from './notificationsProvider';
import NavFilterProvider from './navFilterProvider';
import SettingsProvider from './settingsProvider';
import TableProvider from './tableProvider';
import SearchAndFilterProvider from './searchAndFilterProvider';

export const AppProvider: React.FC = ({ children }) => {
  return  <NavFilterProvider>
            <NotificationsProvider>
              <SettingsProvider>
                <TableProvider>
                  <SearchAndFilterProvider>
                    {children}
                  </SearchAndFilterProvider>
                </TableProvider>
              </SettingsProvider>
            </NotificationsProvider>;
          </NavFilterProvider>
};
