import React, { useEffect, useState } from 'react';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import EnhancedComponent from '../../../components/enhancedComponents';
import BasicComponent from '../../../components/basicComponents/index';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  TablePagination,
  IconButton,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Checkbox,
  Button
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { settingsServices } from '../../../services/api/helpers/settingsServices';
import './style.css';

interface Props {
  tableName: string,
  slug?: string,
  colSpan?: number,
  data: any,
  filters: any,
  total_results: any,
  onFilter: any,
  onUpdate: any,
  section_type: number,
  pagination: any
}

interface UpdateTableRow {
  slug: string,
  data: any
}

// interface ItemCategory {
//   name: string,
//   asset_id: number,
//   id: number
// }

export const AssetsTable = ({ tableName, slug, colSpan, data, filters, total_results, onFilter, onUpdate, section_type, pagination }: Props) => {
  const { paginate } = useSearchAndFilterContext();
  const tableHead = data.header_row;
  const tableBody = data.body;

  const [open, setOpen] = useState(false);
  const [currentTarget, setCurrentTarget] = useState();
  const [valueData, setValueData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setPage(pagination?.page ?? 0);
    setRowsPerPage(pagination?.rows_per_page ?? 10);
  }, [pagination]);
  /** Search and filter functionality
   * Start
  */
  const openState = () => {
    setOpen(false);
  };

  const searchAndFilter = ({ currentTarget }: any, value: any) => {
    const { category_name } = value;
    if (category_name) {
      setOpen(true);
      setCurrentTarget(currentTarget);
      setValueData(value);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    paginate(rowsPerPage, newPage, section_type);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    paginate(parseInt(event.target.value), 0, section_type);
  };
  /** Search and filter functionality
   * end
  */

  const Row = (props: {row: any}) => {
    const { row } = props;
    const [tableRow, setTableRow] = useState({ row: row });
    const [tableState, setTableState] = useState<{state: boolean, level?: string, index?: number}>({ state: false });
    const [expanded] = useState(true);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmOpenParent, setConfirmOpenParent] = useState(false);
    const rowSlug = tableRow.row.row_slug;
    const rowId = parseInt(rowSlug.split('-')[1]);
    const [itemCategoryDeleteId, setItemCategoryDeleteId] = useState(0);
    const [updateTableRow, setUpdateTableRow] = useState<UpdateTableRow>({
      slug: rowSlug,
      data: {
        asset: [],
        item_categories: []
      }
    });

    useEffect(() => {
      rowId === 0 && editContent('parent');
    }, [rowId, setTableRow]);

    // useEffect(() => tableRow.row?.row_slug && setUpdateTableRow(update => ({ ...update, slug: tableRow.row.row_slug })), [tableRow.row?.row_slug]);
    // const ExpandTable = () => {
    //   setExpandable(!expanded);
    //   setTableState({ ...tableState, tableState: 'read-only' });
    // };

    const editContent = (level:string, index?: number) => {
      setTableState({ state: true, level, index });
      setUpdateTableRow({
        ...updateTableRow,
        data: {
          asset: assets(),
          item_categories: itemCategories(tableRow.row.collapsed_rows_included)
        }
      });
    };

    const createContent = async () => {
      const body = {
        slug: 'create',
        data: {
          tab: 'assets',
          asset_id: rowId
        }
      };
      const response = await settingsServices.crudGeneralSettings(body);
      if (response?.success) {
        const newCollapsedRow = response.message.data.new_row.collapsed_rows_included;
        setTableRow({ ...tableRow, row: { ...tableRow.row, collapsed_rows_included: newCollapsedRow } });
        setTableState({ state: true, level: 'child', index: newCollapsedRow.length - 1 });
        setUpdateTableRow({
          ...updateTableRow,
          data: {
            asset: assets(),
            item_categories: itemCategories(newCollapsedRow)
          }
        });
      }
    };

    const deleteContent = (level:string, id?:any) => {
      if (level === 'parent') {
        const body = {
          slug: 'delete',
          data: {
            tab: 'assets',
            id: parseInt(id.split('-')[1])
          }
        };
        onUpdate('delete', body, level);
      } else {
        const category_id = itemCategoryDeleteId;
        const body = {
          slug: 'delete',
          data: {
            tab: 'assets',
            category_id
          }
        };
        onUpdate('delete', body, level);
      }
    };

    const saveContent = (level:string) => {
      const inputs = document.querySelectorAll('input');
      const selects = document.querySelectorAll('select');
      let validated: boolean = true;

      inputs.forEach((input: any) => {
        if (input.validity.patternMismatch) {
          validated = false;
          input.focus();
          input.blur();
          return false;
        }
        if (input.required && !input.value) {
          validated = false;
          input.focus();
          input.blur();
          return false;
        }
      });

      selects.forEach((select: any) => {
        if (select.required && select.options.selectedIndex === 0) {
          validated = false;
          select.focus();
          return false;
        }
      });

      if (validated) {
        setTableState({ state: false });
        onUpdate('update', updateTableRow, level);
      }
    };

    const assets = () => {
      const assetArray: Array<{}> = [];
      tableRow.row.cells.forEach((cell:any) => {
        if (cell.name.includes('group_category')) {
          assetArray.push({ key: cell.name, value: cell.text });
        }
      });
      return assetArray;
    };

    const itemCategories = (collapsed_rows_included: any[]) => {
      const itemCategoryArray: Array<{}> = [];
      collapsed_rows_included.forEach((collapsedRows:any) => {
        collapsedRows.cells.forEach((cell:any) => {
          if (cell.name.includes('item_category_name')) {
            itemCategoryArray.push({ name: cell.text, asset_id: rowId, id: parseInt(cell.id) });
          }
        });
      });
      return itemCategoryArray;
    };

    const updateCell = (value:string, cellName:string, index:number) => {
      /** Update table cell.
       * push data to update State called 'updateTableRow' for global save. */
      /** Parent cell update */
      if (cellName.includes('group_category')) {
        updateTableRow.data.asset[0].value = value;
      }
      /** Collapsed Rows cell update */
      if (cellName.includes('item_category_name')) {
        updateTableRow.data.item_categories[index].name = value;
      }
      setUpdateTableRow(updateTableRow);

      // const currentState = updateTableRow.data;
      // const foundItem = currentState.find((keyValue) => (keyValue.key === cellName));
      // if (foundItem) {
      //   // Replace the found item
      //   const rightIndex = currentState.indexOf(foundItem);
      //   currentState[rightIndex].value = value;
      // } else {
      //   // Create new item
      //   setUpdateTableRow({
      //     ...updateTableRow,
      //     slug: rowSlug,
      //     data: [...updateTableRow.data, newDataObject]
      //   });
      // }
    };

    const deleteItemCategory = (slug: string) => {
      const category_id = parseInt(slug.split('-')[2]);
      setItemCategoryDeleteId(category_id);
      setConfirmOpen(true);
    };

    const deleteContentParent = (id?:any) => {
      const body = {
        slug: 'delete',
        data: {
          tab: 'assets',
          id: parseInt(id.split('-')[1])
        }
      };
      onUpdate('delete', body, 'parent');
    };

    const inputType = (input:any, i:number) => {
      /** Component render switch.
       * Whenever you edit a cell
       */
      const inputType = input.type;
      switch (inputType) {
      case 'empty':
        return (
          <div/>
        );
      case 'text':
        return (
          input.text
        );
      case 'text_field':
        return (
          <BasicComponent.CustomTextField {...input} updateCell={(value: string, cellName: string) => updateCell(value, cellName, i)}/>
        );
      case 'checkbox':
        return (
          <Checkbox
            key={`checkbox-${i}`}
            defaultChecked
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        );
      case 'dropdown':
        return (
          <BasicComponent.Dropdown keyName={input.name} label={input.label} val={input.value} options={input.dropdown.options} validation={input.validation} onBlur={updateCell}/>
        );
      case 'date':
        return (
          <BasicComponent.DatePicker keyName={input.name} label={input.label} val={input.value} futureDates={input.future_dates} pastDates={input.past_dates} onUpdate={updateCell}/>
        );
      case 'text_area':
        return (
          <FormControl key={`text-area-${i}`} className="col3-span">
            <InputLabel shrink htmlFor="label">{input.label}</InputLabel>
            <TextareaAutosize aria-label={input.label} rowsMin={3} placeholder="Comment" />
          </FormControl>
        );

      default:
        break;
      }
    };

    return (
      <>
        <TableRow className="collapse-parent">
          {tableRow.row.cells.map((value:any, i:number) =>
            !(tableState.state && tableState.level === 'parent')
              ? value.type !== 'button'
                ? <TableCell key={`tbodyCell-${i}`} align={value.align}>{value.text}</TableCell>
                : <TableCell key={`tbodyCell-${i}`} align={value.align}><Button key={value.slug} className="btn small jstart" variant="contained" color="primary" disabled={!value.has_permissions} onClick={createContent}>{value.name}</Button></TableCell>
              : <TableCell key={`tbodyCell-${i}`} align={value.align}>{inputType(value, i)}</TableCell>

          )}
          {data.buttons
            ? <TableCell key={row.id} className="table-btns">
              <div className="buttons">
                {data.buttons.map(({ action, has_permissions }: any) =>
                  action === 'Edit'
                    ? (
                      !(tableState.state && tableState.level === 'parent')
                        ? <IconButton aria-label="edit" className="edit-btn" disabled={!has_permissions} onClick={() => editContent('parent')}><EditIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                        : <IconButton aria-label="edit" className="edit-btn" disabled={!has_permissions} onClick={() => saveContent('parent')}><SaveIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                    )
                    : (
                      action === 'Delete' &&
                      // <>
                      //   <IconButton aria-label="delete" className="delete-btn" onClick={() => deleteContent('parent', rowSlug)}><DeleteIcon fontSize="small" /></IconButton>
                      // </>
                      <>
                        <IconButton aria-label="delete" className="delete-btn" disabled={!has_permissions} onClick={() => setConfirmOpenParent(true)}><DeleteIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                        <BasicComponent.ConfirmDialog confirm={true} open={confirmOpenParent} setOpen={setConfirmOpenParent} onConfirm={() => deleteContentParent(rowSlug)} >
                          <p>Are you sure you want to delete this item?</p>
                        </BasicComponent.ConfirmDialog>
                      </>
                    )
                )}
              </div>
            </TableCell>
            : null
          }
        </TableRow>
        <TableRow className="collapse-child">
          <TableCell className="collapsed" colSpan={colSpan}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Table>
                <TableBody>
                  {tableRow.row.collapsed_rows_included.map((collapsedRow:any, i:number) => (
                    <TableRow key={i}>
                      {!(tableState.state && tableState.level === 'child' && tableState.index === i)
                        ? collapsedRow?.cells.map((value:any) => (
                          value.label
                            ? <TableCell key={value.name} align={value.align}><InputLabel>{value.label}</InputLabel>{value.text}</TableCell>
                            : <TableCell key={value.name} align={value.align}>{value.text}</TableCell>
                        ))
                        : collapsedRow?.cells.map((value:any) => (
                          <TableCell key={value.name} align={value.align}>{inputType(value, i)}</TableCell>
                        ))
                      }
                      {data.buttons
                        ? <TableCell key={row.id} className="table-btns">
                          <div className="buttons">
                            {data.buttons.map(({ action, has_permissions }: any) =>
                              action === 'Edit'
                                ? (
                                  !(tableState.state && tableState.level === 'child' && tableState.index === i)
                                    ? <IconButton aria-label="edit" className="edit-btn" disabled={!has_permissions} onClick={() => editContent('child', i)}><EditIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                                    : <IconButton aria-label="edit" className="edit-btn" disabled={!has_permissions} onClick={() => saveContent('child')}><SaveIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                                )
                                : (action === 'Delete' &&
                                // <>
                                //   <IconButton aria-label="delete" className="delete-btn" onClick={() => deleteContent('child', collapsedRows.row_slug)}><DeleteIcon fontSize="small" /></IconButton>
                                // </>
                                  <>
                                    <IconButton aria-label="delete" className="delete-btn" disabled={!has_permissions} onClick={() => { deleteItemCategory(collapsedRow.row_slug); }}><DeleteIcon color={!has_permissions ? 'disabled' : 'inherit'} fontSize="small" /></IconButton>
                                    <BasicComponent.ConfirmDialog confirm={true} open={confirmOpen} setOpen={setConfirmOpen} onConfirm={() => deleteContent('child', collapsedRow.row_slug)} >
                                      <p>Are you sure you want to delete this item?</p>
                                    </BasicComponent.ConfirmDialog>
                                  </>
                                )
                            )}
                          </div>
                        </TableCell>
                        : null
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <TableContainer className={`${tableName} assets`}>
        { !!filters && filters.length > 0
          ? <BasicComponent.FilterResults filters={filters}/>
          : null
        }
        <Table stickyHeader>
          <TableHead className="thead">
            <TableRow>
              {tableHead.cells.map((value:any, i:number) => (
                <TableCell key={`theadCell-${i}`} align={value.align} onClick={(event) => searchAndFilter(event, value)}>{value.text}</TableCell>
              ))}
              <EnhancedComponent.SearchAndFilter open={open} currentTarget={currentTarget} data={valueData} openState={openState}/>
            </TableRow>
          </TableHead>
          <TableBody className="tbody">
            {tableBody.map((row:any, i:number) => (
              <Row key={`tbody-${i}`} row={row} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total_results}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};
