/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { GlobalContext } from '../../context/globalState';
import { useSearchAndFilterContext } from '../../context/searchAndFilterProvider';
import { useTableContext } from '../../context/tableProvider';
import BasicComponent from '../../components/basicComponents/index';
import EnhancedComponent from '../../components/enhancedComponents';
import { BarChartComponentProps } from '../../components/basicComponents/barChart/barChart.component';
import { ChartPair } from '../../components/enhancedComponents/dashboard/dashboardComponent';
import { dashboardServices } from '../../services/api/helpers/dashboardServices';
import { ChartLegend } from '@components/basicComponents/chartLegend/chartLegend.component';

// import { PieChartComponentProps } from '@components/basicComponents/barChart/barChart.component';
interface Dashboard {
  actualPayment: [],
  actualPaymentVariance: [],
  actualBudget: [],
  actualBudgetVariance: [],
  projectSections: [],
  totals: [],
  summaryTable: []
}

const CompanyDashboard = () => {
  const { currentPage } = useContext(GlobalContext);
  const { exchangeRate } = useTableContext();
  const [loading, setLoading] = useState(true);
  const [currencySymbol, setCurrencySymbol] = useState('R');
  const [barData1, setMainBarChart] = useState<any[]>([]);
  const [barData2, setTotalBarChart] = useState<any[]>([]);
  const [paymentPieChart, setPaymentPieChart] = useState<any[]>([]);
  const [paymentVarianceBarChart, setPaymentVarianceBarChart] = useState<any[]>([]);
  const [budgetPieChart, setBudgetPieChart] = useState<any[]>([]);
  const [budgetVarianceBarChart, setBudgetVarianceBarChart] = useState<any[]>([]);
  const [summaryTable, setSummaryTable] = useState();
  const [filters, setFilters] = useState<any>([]);
  const { pagination, pageAndFilterStatus, paginateSet } = useSearchAndFilterContext();

  const body = {
    slug: 'get_company_dashboard'
  };

  useEffect(() => {
    (async () => {
      await initializeData(body);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, []);

  useEffect(() => {
    if (pageAndFilterStatus === 'UPDATED') {
      initializeData(body);
    }
  }, [pageAndFilterStatus]);

  useEffect(() => {
    (async () => {
      if (exchangeRate.data.id !== 0 && exchangeRate.data.exchange_rate !== '') {
        await initializeData(exchangeRate);
      }
    })();
  }, [exchangeRate]);

  const initializeData = async (body: any) => {
    if (!body.pagination && pagination && pagination.length > 0) {
      body.pagination = pagination[0];
    }
    const resp = await dashboardServices.getDashboard(body);
    if (resp?.success) {
      const { chart } = resp.message.data;

      const {
        sections_bar_data, totals_bar_data,
        actual_payments_pie_data, actual_payments_variance_bar_data,
        budget_pie_data, budget_variance_bar_data
      } = chart;

      const { summary_table, pagination, total_results } = resp.message.data;

      setCurrencySymbol(chart.currency_symbol);
      setMainBarChart(sections_bar_data);
      setTotalBarChart(totals_bar_data);
      setPaymentPieChart(actual_payments_pie_data);
      setPaymentVarianceBarChart(actual_payments_variance_bar_data);
      setBudgetPieChart(budget_pie_data);
      setBudgetVarianceBarChart(budget_variance_bar_data);

      setSummaryTable({ ...summary_table, pagination, total_results });
      paginateSet(pagination ? [pagination] : []);
    } else {
      paginateSet(pagination);
    }
  };

  const barData1Presensation = {
    showLegend: false,
    showYAxisLabel: true,
    showTickLabel: true,
    width: 1000,
    height: 400
  };

  const barData2Presensation = {
    showLegend: true,
    showYAxisLabel: true,
    showTickLabel: true,
    width: 360,
    height: 400
  };

  const barData3Presensation = {
    showLegend: true,
    showYAxisLabel: false,
    showTickLabel: false,
    width: 160,
    height: 160
  };

  const firstRowChartProps: BarChartComponentProps[] = [
    {
      ...barData1Presensation,
      heading: 'Chart one',
      data: barData1
    },
    {
      ...barData2Presensation,
      heading: 'Chart two',
      data: barData2
    }
  ];
  const secondRowChartProps: ChartPair[] = [
    {
      PieChartComponent: {
        heading: 'ACTUAL PAYMENT',
        data: paymentPieChart,
        showLegend: true,
        width: 160,
        height: 160

      },
      barChartComponent: {
        ...barData3Presensation,
        heading: 'ACTUAL VALUE VARIANCES',
        data: paymentVarianceBarChart
      }
    },
    {
      PieChartComponent: {
        heading: 'ACTUAL BUDGET',
        data: budgetPieChart,
        showLegend: true,
        width: 160,
        height: 160
      },
      barChartComponent: {
        ...barData3Presensation,
        heading: 'BUDGET VALUE VARIANCES',
        data: budgetVarianceBarChart
      }
    }
  ];

  const update = async (update:any) => {
    if (update.slug === 'reload') {
      initializeData({ ...body, filters });
    }
    if (update.slug.includes('created_at')) {
      const filterArray = [
        {
          category_name: update.slug,
          filter: `${update.start_date} - ${update.end_date}`,
          sort: 'DESC',
          title: update.title
        }
      ];
      setFilters(filterArray);
      const newBody = {
        ...body,
        filters
      };
      initializeData(newBody);
    } else {
      if (!update.pagination && pagination && pagination.length > 0) {
        update.pagination = pagination[0];
      }
      const response = await dashboardServices.toggleCheckboxDashboard(update);
      if (response?.success) {
        const { chart } = response.message.data;

        const {
          sections_bar_data, totals_bar_data,
          actual_payments_pie_data, actual_payments_variance_bar_data,
          budget_pie_data, budget_variance_bar_data
        } = chart;

        const { summary_table, pagination, total_results } = response.message.data;

        setMainBarChart(sections_bar_data);
        setTotalBarChart(totals_bar_data);
        setPaymentPieChart(actual_payments_pie_data);
        setPaymentVarianceBarChart(actual_payments_variance_bar_data);
        setBudgetPieChart(budget_pie_data);
        setBudgetVarianceBarChart(budget_variance_bar_data);

        setSummaryTable({ ...summary_table, pagination, total_results });
        paginateSet(pagination ? [pagination] : []);
      } else {
        paginateSet(pagination);
      }
    };
  };

  return (
    <>
      <EnhancedComponent.SideDrawer/>
      <div className="container">
        {
          loading ? <BasicComponent.Loader size={60} message="Loading data..." />
            : <EnhancedComponent.Dashboard
              title={'Company Dashboard'}
              type={'company'}
              currencySymbol={currencySymbol}
              firstRowChartProps={firstRowChartProps}
              secondRowChartProps={secondRowChartProps}
              summaryTable={summaryTable}
              onUpdate={update}
            />
        }
      </div>
    </>
  );
};

export default CompanyDashboard;
