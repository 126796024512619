import { TableCell, TextField } from '@material-ui/core';
import React from 'react';

const EditRow = (props: {
    data: any;
    onUpdate: (
        inputValue: any,
        inputName: string,
        collapsedRowSlug: string
    ) => void;
}) => {
  const { data, onUpdate } = props;

  const editableData = data;

  const handleOnBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowInput: { value: any; name: string; [other: string]: any }
  ) => {
    // eslint-disable-next-line no-inline-comments
    if (event.target.value /* newValue */ !== rowInput.value /* oldValue */) {
      // @MM: Only trigger change if value differs [opportunity for react memo]
      onUpdate(
        event.target.value,
        rowInput.name,
        editableData.collapsed_rows_slug
      );
    }
  };

  return (
    <React.Fragment key={editableData.collapsed_rows_slug}>
      {editableData.cells.map((cellValue: any, cellIndex: number) => {
        return !cellValue.input ? (
          <TableCell
            key={`expanded-${cellIndex}`}
            align={cellValue.align}
          >
            {cellValue.text}
          </TableCell>
        ) : (
          <TableCell
            key={`expanded-${cellIndex}`}
            align={cellValue.align}
          >
            <TextField
              key={
                                cellValue.input?.name ||
                                `expanded-edit-${cellIndex}`
              }
              defaultValue={cellValue.input.value}
              onBlur={(event) =>
                handleOnBlur(event, cellValue.input)
              }
            />
          </TableCell>
        );
      })}
    </React.Fragment>
  );
};

export default EditRow;
