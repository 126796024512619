import React from 'react';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import { Button } from '@material-ui/core';
import './sort-filter.css';

// interface Props {
//   props: any
// }

export const SortFilter = (props: any) => {
  const { filtersData, removeFilter, sortTableBy } = useSearchAndFilterContext();
  const { data } = props;

  const sortBy = (category_name: any, name: any) => {
    // searchResults(category_name, '', 'ASC', name);
    const remove = filtersData.filters.find((filter: any) => (filter.title.includes(name)));
    if (remove) {
      removeFilter(remove.category_name);
    } else {
      sortTableBy(category_name, '', 'ASC', name);
    }
  };

  return (
    <div className="sort-by six gcrg10">
      <h4 className="uppercase col6-span">Sort By</h4>
      {data.map(({ id, category_name, name, selected }: any) => (
        <Button key={id} className="sort-btn astart" variant={!selected ? 'contained' : 'outlined'} color="primary" onClick={() => sortBy(category_name, name)}>{name}</Button>
      ))}
    </div>
  );
};
