import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../../../context/globalState';
import { useTableContext } from '../../../context/tableProvider';
import { useSearchAndFilterContext } from '../../../context/searchAndFilterProvider';
import { globalLocalStorage } from '../../../services/storage/localstorage';
import BasicComponent from '../../../components/basicComponents/index';
import EnhancedComponent from '../../../components/enhancedComponents';
import StatelessComponents from '../../../components/statelessComponents';
import {
  Button,
  FormControl,
  NativeSelect,
  CircularProgress,
  TextField,
  IconButton,
  Link,
  ClickAwayListener
} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { pageServices } from '../../../services/api/helpers/pageServices';
import './styles.sass';

import FUEL_CHOICE_DATA from './fuelChoice.data';

interface ProjectSectionState {
  projectId: number,
  projectSectionId: number,
  budgetId: number,
  budgetName: string,
  data: Array<any>
}

interface CrudButton {
  action: string,
  name: string,
  slug: string
}

interface FiltersAndPagination {
  filters: Array<any>,
  pagination?: any,
  total_results: number
}

const ProjectSection = () => {
  const { currentPage } = useContext(GlobalContext);
  const { update, deleteData, otherChargesSet, editItemsSet, editBudgetSet } = useTableContext();
  const page = currentPage || globalLocalStorage.getItem('currentPage');
  const currentSection = globalLocalStorage.getItem('currentSection');
  const { filtersData, pagination, pageAndFilterStatus, paginateSet } = useSearchAndFilterContext();
  const [state, setState] = useState<ProjectSectionState>({
    projectId: 0,
    projectSectionId: 0,
    budgetId: 1,
    budgetName: '',
    data: []
  });

  const [summaryFilter, setSummaryFilter] = useState<FiltersAndPagination>({
    filters: [],
    total_results: 0
  });
  const [itemsFilter, setItemsFilter] = useState<FiltersAndPagination>({
    filters: [],
    total_results: 0
  });

  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState({
    openBudget: false,
    createBudget: false,
    copyBudget: false,
    openRenameBudget: false
  });
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: ''
  });

  useEffect(() => {
    (async function () {
      if (!currentPage) {
        const page = globalLocalStorage.getItem('currentPage');
        await pageData(page);
      } else {
        globalLocalStorage.storeItem('currentPage', currentPage);
        await pageData(currentPage);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, [currentPage]);

  useEffect(() => {
    if (pageAndFilterStatus === 'UPDATED') {
      filterAndPagination(
        filtersData.filters && filtersData.filters.length >= 0 ? filtersData : null,
        pagination && pagination.length >= 0 ? pagination : null
      );
    }
  }, [filtersData, pageAndFilterStatus]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(update.data, 'row_id')) {
      otherCharges(update);
    }
  }, [update]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(deleteData.data, 'charge_id')) {
      deleteOtherCharges(deleteData);
    }
  }, [deleteData]);

  const filterAndPagination = async (filtersData: any, paginationData: any) => {
    const { filters } = filtersData;

    const body: {[key: string]: any} = {
      filters
    };
    if (paginationData && paginationData.length > 0) body.pagination = paginationData;

    if (state.data.length > 0) {
      const fuelSection = state.data.find(section => section.heading === 'Fuel');
      if (fuelSection) {
        body.data = {
          table_type: fuelSection.table_type
        };
      }
    }

    const response = await pageServices.filterPagination(page || currentPage, body);
    if (response?.success) {
      const { page_sections, project_id, project_section_id } = response.message.data;

      const summaryFilters = page_sections.filter((pageSection:any) => (pageSection.type === 2)).map((section:any) => {
        const summaryFilters = {
          filters: section.filters,
          total_results: section.total_results
        };
        return summaryFilters;
      });

      const itemsFilters = page_sections.filter((pageSection:any) => (pageSection.type === 3)).map((section:any) => {
        const itemsFilters = {
          filters: section.filters,
          total_results: section.total_results
        };
        return itemsFilters;
      });

      setState({
        ...state,
        projectId: project_id,
        projectSectionId: project_section_id,
        data: page_sections
      });

      setSummaryFilter(summaryFilters[0]);
      setItemsFilter(itemsFilters[0]);
      // @MM Should set to returned pagination once backend is up to date; REMOVE
      const temp_pagination = page_sections.map((section: any) => section.pagination);
      paginateSet(temp_pagination);
    } else {
      paginateSet(pagination);
    }
  };

  const pageData = async (currentPageId:any) => {
    const { filters } = filtersData;
    const body: {[key: string]: any} = {
      data: {
        table_type: state.data.find(section => section.heading === 'Fuel')?.table_type
      },
      filters
    };
    if (pagination && pagination.length > 0) body.pagination = pagination;

    const response = await pageServices.pageBuilder(currentPageId, body);
    if (response?.success) {
      const { page_sections, project_id, project_section_id } = response.message.data;
      /** Get current section name.
       * This is used to do custom styling. */
      globalLocalStorage.storeItem('currentSection', page_sections[0].heading.split(' ')[0].toLowerCase());
      /** Get Default Budget id */
      let defaultBudgetId = 0;
      let defaultBudgetName = '';
      let itemsFilters: FiltersAndPagination = itemsFilter;

      page_sections.forEach((pageSection:any) => {
        if (pageSection.type === 2) {
          if (pageSection.drop_down.budgets.length !== 0) {
            const newBudgets = pageSection.drop_down.budgets;
            const defaultBudgetIndex = pageSection.drop_down.default;
            defaultBudgetId = newBudgets[defaultBudgetIndex].id;
            defaultBudgetName = newBudgets[defaultBudgetIndex].name;
          }
        }
        if (pageSection.type === 3) {
          const { filters, total_results } = pageSection;
          itemsFilters = { filters, total_results };
        }
      });

      setState({
        projectId: project_id,
        projectSectionId: project_section_id,
        budgetId: defaultBudgetId,
        budgetName: defaultBudgetName,
        data: page_sections
      });

      setItemsFilter(itemsFilters);
      const temp_pagination = page_sections.map((section: any) => section.pagination);
      paginateSet(temp_pagination);
    } else {
      paginateSet(pagination);
    }
  };

  const otherCharges = async (newCharge:any) => {
    const response = await pageServices.crudCharges(newCharge);
    if (response?.success) {
      const { charges } = response.message.data;
      otherChargesSet(charges);
    }
  };

  const deleteOtherCharges = async (charges: any) => {
    const response = await pageServices.crudCharges(charges);
    if (response?.success) {
      const { charges } = response.message.data;
      otherChargesSet(charges);
    }
  };

  const onUpdate = async (updateState: any, pageSectionId:number, data: any) => {
    /** Table CRUD functionality/state management */
    if (updateState === 'reload_data') {
      setTimeout(() => {
        const page = globalLocalStorage.getItem('currentPage');
        pageData(page);
      }, 500);
    }

    if (updateState === 'update') {
      const newProjectSectionState = state.data.map((pageSection:any) => {
        if (pageSection.page_section_id === pageSectionId) {
          const newTableBody = pageSection.table.body.map((tableRow: any) => {
            if (tableRow.collapsed_rows_slug === data.collapsed_rows_slug) {
              return data;
            }
            return tableRow;
          });
          const newPageSection = {
            ...pageSection,
            table: {
              ...pageSection.table, body: newTableBody
            }
          };
          return newPageSection;
        }
        return pageSection;
      });
      setState({ ...state, data: newProjectSectionState });
    }

    if (updateState === 'delete-row') {
      const newProjectSectionState = state.data.map((pageSection:any) => {
        if (pageSection.page_section_id === pageSectionId) {
          const newTableBody = pageSection.table.body.filter((tableRow: any) =>
            data.find((dataRow: any) => tableRow.collapsed_rows_slug === dataRow.collapsed_rows_slug) !== undefined
          );
          const newPageSection = {
            ...pageSection,
            table: {
              ...pageSection.table, body: newTableBody
            }
          };
          return newPageSection;
        }
        return pageSection;
      });
      setState({ ...state, data: newProjectSectionState });
    }

    if (updateState === 'delete-attachments') {
      const newProjectSectionState = state.data.map((pageSection:any) => {
        if (pageSection.page_section_id === pageSectionId) {
          const newTableBody = pageSection.table.body.map((tableRow: any) => {
            if (tableRow.collapsed_rows_slug === data.collapsed_rows_slug) {
              const newAttachments = {
                ...tableRow,
                extra: {
                  ...tableRow.extra, attachments: data.attachments
                }
              };
              return newAttachments;
            }
            return tableRow;
          });
          const newPageSection = {
            ...pageSection,
            table: {
              ...pageSection.table, body: newTableBody
            }
          };
          return newPageSection;
        }
        return pageSection;
      });
      setState({ ...state, data: newProjectSectionState });
    }
  };

  const openBudgetOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (!open.openBudget) {
      setOpen({ ...open, openBudget: true, createBudget: false, copyBudget: false, openRenameBudget: false });
    } else {
      setOpen({ ...open, openBudget: false, createBudget: false, copyBudget: false });
    }
  };

  const openRenameBudget = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (!open.openRenameBudget) {
      setOpen({ ...open, openBudget: false, createBudget: false, copyBudget: false, openRenameBudget: true });
    } else {
      setOpen({ ...open, openRenameBudget: false });
    }
  };

  const defaultBudget = async (pageSectionId:number) => {
    const body = {
      slug: 'set_default_budget',
      data: {
        budget_id: state.budgetId,
        project_budget_name: state.budgetName
      }
    };

    crudBudget(body, pageSectionId);
  };

  const createBudget = async (event:any, pageSectionId:number) => {
    const value = event.target.value;
    if (event.keyCode === 13) {
      const check = await checkDuplicateBudgets(value);

      if (check) {
        const body = {
          slug: 'add_budget',
          data: {
            project_budget: {
              name: value,
              project_id: state.projectId,
              project_section_id: state.projectSectionId
            },
            budget_copy_from_id: null,
            project_budget_name: null
          }
        };

        crudBudget(body, pageSectionId);
      }
    }
  };

  const copyBudget = async (budgetId:number, budgetName:string, pageSectionId:number) => {
    const body = {
      slug: 'add_budget',
      data: {
        budget_copy_from_id: budgetId,
        project_budget_name: `${budgetName} CPY`,
        project_budget: null
      }
    };

    crudBudget(body, pageSectionId);
  };

  const renameBudget = async (event:any, pageSectionId:number) => {
    const value = event.target.value;

    if (event.keyCode === 13) {
      const check = await checkDuplicateBudgets(value);

      if (check) {
        const body = {
          slug: 'rename_budget',
          data: {
            budget_id: state.budgetId,
            project_budget_name: value
          }
        };

        crudBudget(body, pageSectionId);
      }
    }
  };

  const deleteBudget = async (slug:string, budgetId:number, pageSectionId:number) => {
    const body = {
      slug: slug + budgetId
    };
    const response = await pageServices.crudBudget(body);
    if (response?.success) {
      const { message } = response.message.data;
      pageData(page);
      setOpenAlert(true);
      setAlertMessage({
        message: message,
        severity: 'success'
      });
    } else {
      setOpenAlert(true);
      setAlertMessage({
        message: response?.message.data.data.message,
        severity: 'error'
      });
    }
  };

  const addGroupType = async (slug:string, budgetId:number, pageSectionId:number, budgetGroupTypes: any) => {
    if (budgetGroupTypes.length !== 0) {
      /** Currently sending excatly the excat details needed for the new item created. Back-end selects by this data.
       * Back-end: Update to return new item with dropdown active set to -1
       * Front-end: Select empty option with -1
      */
      const body = {
        slug: slug + budgetId,
        data: {
          group_type_id: budgetGroupTypes[0].id,
          name: budgetGroupTypes[0].name,
          description: budgetGroupTypes[0].description,
          table_type: state.data.find(section => section.heading === 'Fuel')?.table_type
        }
      };

      const response = await pageServices.crudBudget(body);
      if (response?.success) {
        const newProjectSectionState = state.data.map((pageSection:any) => {
          if (pageSection.page_section_id === pageSectionId) {
            const newPageSection = {
              ...pageSection,
              project_budget_group_types: response.message.data.project_budget_group_types,
              table: {
                ...pageSection.table, body: [...pageSection.table.body, response.message.data.group_type]
              }
            };
            return newPageSection;
          }
          return pageSection;
        });
        editBudgetSet(true);
        setState({ ...state, budgetId, data: newProjectSectionState });
      }
    } else {
      setOpenAlert(true);
      setAlertMessage({
        message: 'No budget group type available to add',
        severity: 'error'
      });
    }
  };

  const checkDuplicateBudgets = async (value: any) => {
    const { project_section_budgets } = state.data[1];
    const checkDuplicateBudgets = project_section_budgets.find((budget: any) => (budget.name.toLowerCase() === value.toLowerCase()));

    if (checkDuplicateBudgets) {
      setOpenAlert(true);
      setAlertMessage({
        message: 'There is already a budget with this name.',
        severity: 'error'
      });

      return false;
    }
    return true;
  };
  /** CRUDBUDGET SET FUNCTIONALITY */
  const crudBudget = async (body: any, pageSectionId: number) => {
    const response = await pageServices.crudBudget({
      ...body,
      table_type: state.data.find(section => section.heading === 'Fuel')?.table_type
    });
    if (response?.success) {
      setOpen({ ...open, openBudget: false, copyBudget: false, openRenameBudget: false });
      const { message } = response.message.data;
      const crudBudget = response.message.data;

      if (body.slug === 'add_budget') {
        const newBudgets = crudBudget.budgets_drop_down.budgets;
        const defaultBudgetIndex = crudBudget.budgets_drop_down.default;
        const defaultBudget = newBudgets[defaultBudgetIndex];
        const newProjectSectionState = state.data.map((pageSection:any) => {
          if (pageSection.page_section_id === pageSectionId) {
            const newPageSection = {
              ...pageSection,
              project_section_budgets: crudBudget.budgets_drop_down.budgets,
              table: {
                ...pageSection.table, body: crudBudget.new_budget_table_body
              }
            };
            return newPageSection;
          }
          return pageSection;
        });
        setState({ ...state, budgetId: defaultBudget.id, budgetName: defaultBudget.name, data: newProjectSectionState });
      }

      if ((body.slug === 'set_default_budget') || (body.slug === 'rename_budget')) {
        const { project_budget_name } = body.data;
        const newProjectSectionState = state.data.map((pageSection:any) => {
          if (pageSection.page_section_id === pageSectionId) {
            const newPageSection = {
              ...pageSection,
              project_section_budgets: crudBudget.project_section_budgets
            };
            return newPageSection;
          }
          return pageSection;
        });
        setState({ ...state, budgetName: project_budget_name, data: newProjectSectionState });
      }

      setOpenAlert(true);
      setAnchorEl(null);
      setAlertMessage({
        message: message,
        severity: 'success'
      });
    } else {
      setOpenAlert(true);
      setAlertMessage({
        message: response?.message.data.data ? response?.message.data.data.message : 'Something broke!',
        severity: 'error'
      });
    }
  };

  const createItem = async (pageSectionId:number, special_flag?:object) => {
    const body = {
      slug: 'add_project_item',
      data: {
        project_id: state.projectId,
        project_section_id: state.projectSectionId,
        special_flag,
        table_type: state.data.find(section => section.heading === 'Fuel')?.table_type
      }
    };

    const response = await pageServices.crudItem(body);
    if (response?.success) {
      await pageData(currentPage);
      const newProjectSectionState = state.data.map((pageSection:any) => {
        const createdItem = response.message.data;
        if (pageSection.page_section_id === pageSectionId) {
          const newPageSection = {
            ...pageSection,
            table: {
              ...pageSection.table, body: [createdItem.project_item, ...pageSection.table.body]
            }
          };
          return newPageSection;
        }
        return pageSection;
      });
      editItemsSet(true);
      setState((prevState) => ({ ...prevState, data: newProjectSectionState }));
    } else {
      alert(response?.message.data.data.message);
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>, pageSectionId:number) => {
    setOpen({ ...open, openBudget: false, copyBudget: false, openRenameBudget: false });
    const budgetId = parseInt(event.target.value);
    const response = await pageServices.companyProjectBudgetData(event.target.value, pageSectionId);
    if (response?.success) {
      const { project_section_budgets } = state.data[1];
      const selectedBudget = project_section_budgets.find((budget: any) => (budget.id === budgetId));

      const newProjectSectionState = state.data.map((pageSection:any) => {
        if (pageSection.page_section_id === pageSectionId) {
          const newPageSection = {
            ...pageSection,
            project_budget_group_types: response.message.data.remaining_group_types,
            table: {
              ...pageSection.table, body: response.message.data.project_budget
            }
          };
          return newPageSection;
        }
        return pageSection;
      });
      setState({ ...state, budgetId, budgetName: selectedBudget.name, data: newProjectSectionState });
    }
  };

  const changeFuelCategory = async (value:string, pageSectionId:number) => {
    const { filters } = filtersData;
    const body: { [key: string]: any } = {
      slug: 'toggle_fuel_summary',
      data: {
        project_id: state.projectId,
        table_type: value
      },
      filters
    };

    const response = await pageServices.crudItem(body);
    if (response?.success) {
      const { data } = state;
      const { sort_filter_header, table, table_type, type, pagination } = response.message.data.summary_table;
      const summaryTable = data.map((section:any) => {
        if (section.page_section_id === pageSectionId) {
          const newSection = {
            ...section,
            sort_filter_header,
            table,
            table_type,
            type,
            pagination
          };
          return newSection;
        }
        return section;
      });

      setState({
        ...state,
        data: summaryTable
      });
    }
  };

  const renderSwitch = (section: any) => {
    const sectionType = section.type;
    switch (sectionType) {
    case 1:
      return <section key={sectionType} className="project-costs grg30">
        <div className="heading underline two gcrg20">
          <h1 className="uppercase">{section.heading}</h1>
          <BasicComponent.Tooltip
            content={(
              <React.Fragment>
                {section.tooltip?.split(';').map((str: string, index: number) => (<p key={index}>{str}</p>))}
              </React.Fragment>
            )}
            direction="bottom">
            <InfoOutlinedIcon className="info-icon" fontSize="small" />
          </BasicComponent.Tooltip>
        </div>
        <div className={`card two gcg40 grg20 ${state.data[0].table_type}`}>
          { state.projectSectionId === 5 && <BasicComponent.RadioButtons {...FUEL_CHOICE_DATA} pageSectionId={section.page_section_id} controlledValue={section.table_type} onChange={changeFuelCategory}/> }
          { state.projectSectionId === 5 && <div className="separator col2-span"></div> }
          <BasicComponent.SortFilter data={section.sort_filter_header.sort_items} sort={summaryFilter}/>
          <StatelessComponents.AppliedFilter filters={section.sort_filter_header.filter_text}/>
          <div className="separator col2-span"></div>
          <BasicComponent.DynamicTable
            key={section.page_section_id}
            section_type={sectionType}
            tableName="table summary-table-v2 col2-span"
            data={section.table}
            pagination={section.pagination}
            total_results={section.total_results}/>
        </div>
      </section>;

    case 2:
      if (!section.can_view_budgets) {
        return null;
      }
      return <section key={sectionType} className="budget grg30">
        <div className="heading underline two gcrg20">
          <h1 className="uppercase">{section.heading}</h1>
          { section.exportable
            ? <IconButton
              aria-label="default"
              className="default-btn"
              color="primary">
              <Link
                href={`${process.env.REACT_APP_API}export/export_project_budget/${section.exportable.project_id}/${section.exportable.section_id}`}
                style={{ color: 'grey' }}
              ><ImportExportIcon fontSize="large" /></Link>
            </IconButton>
            : null
          }
        </div>
        <div className="card two gcrg20">
          <div className="budgets four gcg5 grg20 col2-span jstart">
            <FormControl key="budget-dropdown" className="custom-dropdown budget jstart">
              <NativeSelect
                variant={'filled'}
                value={state.budgetId}
                onChange={(event) => handleChange(event, section.page_section_id)}
                inputProps={{
                  name: 'budget-dropdown'
                }}>
                {
                  section.project_section_budgets.map((option:any) => {
                    if (option.is_default) {
                      return <option key={option.slug} value={option.id} >{option.name} ✓</option>;
                    }
                    return <option key={option.slug} value={option.id} >{option.name}</option>;
                  })
                }
              </NativeSelect>
            </FormControl>
            <IconButton aria-label="default" className="default-btn" disabled={!section.can_set_default_budget} onClick={() => defaultBudget(section.page_section_id)}><CheckCircleOutlineIcon fontSize="small" /></IconButton>
            <ClickAwayListener onClickAway={() => setOpen({ ...open, openRenameBudget: false })}>
              <div className="budget-rename">
                <IconButton aria-label="edit" className="edit-btn" disabled={!section.buttons[0].has_permissions} onClick={openRenameBudget}><EditIcon fontSize="small" /></IconButton>
                <Popper className="popper" open={open.openRenameBudget} anchorEl={anchorEl} placement={'bottom'}>
                  <div className="card">
                    <TextField
                      id="budget-name"
                      label="Rename Budget"
                      defaultValue={state.budgetName}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onKeyUp={(event) => renameBudget(event, section.page_section_id)}/>
                  </div>
                </Popper>
              </div>
            </ClickAwayListener>
            <ClickAwayListener onClickAway={() => setOpen({ ...open, openBudget: false, createBudget: false, copyBudget: false })}>
              <div className="budget-options">
                {section.buttons.map((button:CrudButton) => {
                  if (button.slug === 'add_budget-') {
                    return <Button className="btn jstart" variant="contained" color="primary" disabled={!section.buttons[0].has_permissions} onClick={openBudgetOptions}>{button.name}</Button>;
                  }
                })}
                <Popper className="popper" open={open.openBudget} anchorEl={anchorEl} placement={'bottom'}>
                  <div className="card custom-padding">
                    <a className="btn-link" onClick={() => setOpen({ ...open, createBudget: true })}>Create New Budget</a>
                    <Popper className="popper" open={open.createBudget} anchorEl={anchorEl} placement={'bottom'}>
                      <div className="card">
                        <TextField
                          id="budget-name"
                          label="Budget Name"
                          InputLabelProps={{
                            shrink: true
                          }}
                          onKeyUp={(event) => createBudget(event, section.page_section_id)}/>
                      </div>
                    </Popper>
                    <a className="btn-link" onClick={() => setOpen({ ...open, copyBudget: true })}>Copy Existing Budget</a>
                    <Popper className="popper" open={open.copyBudget} anchorEl={anchorEl} placement={'bottom'}>
                      <div className="card custom-padding">
                        {section.project_section_budgets.map((budget:any) => (
                          <a key={budget.slug} className="btn-link" onClick={() => copyBudget(budget.id, budget.name, section.page_section_id)}>{budget.name}</a>
                        ))}
                      </div>
                    </Popper>
                  </div>
                </Popper>
              </div>
            </ClickAwayListener>
          </div>
          <BasicComponent.DynamicCollapsibleTable
            key={section.page_section_id}
            section_type={sectionType}
            tableName="table budget one grg20 col2-span"
            data={section.table}
            pagination={section.pagination}
            total_results={section.total_results}
            pageSectionId={section.page_section_id}
            onUpdate={onUpdate}/>
          {section.buttons.map((button:CrudButton) => {
            if (button.slug === 'add_budget_group_type-') {
              return <Button
                className="btn jstart"
                variant="contained"
                color="primary"
                disabled={!section.buttons[1].has_permissions}
                onClick={() => addGroupType(button.slug, state.budgetId, section.page_section_id, section.project_budget_group_types)}
              >{button.name}</Button>;
            }
            if (button.action === 'Delete') {
              return <>
                <Button
                  className="btn jend"
                  variant="outlined"
                  color="primary"
                  disabled={!section.buttons[2].has_permissions}
                  onClick={() => setConfirmOpen(true)}
                >{button.name}</Button>
                <BasicComponent.ConfirmDialog confirm={true} open={confirmOpen} setOpen={setConfirmOpen} onConfirm={() => deleteBudget(button.slug, state.budgetId, section.page_section_id)} >
                  <p>Are you sure you want to delete this item?</p>
                </BasicComponent.ConfirmDialog>
              </>;
            }
          })}
        </div>
      </section>;

    case 3:
      return <section key={sectionType} className="items grg30">
        <div className="heading underline two gcrg20">
          <h1 className="uppercase acenter">{section.heading}</h1>
          <BasicComponent.FitlerDropdown keyName="filter-dropdown" options={section.filter_options} reloadContent={page}/>
          { section.exportable
            ? <IconButton
              aria-label="default"
              className="default-btn"
              color="primary">
              <Link
                href={`${process.env.REACT_APP_API}export/export_project_items/${section.exportable.project_id}/${section.exportable.section_id}`}
                style={{ color: 'grey' }}
              ><ImportExportIcon fontSize="large" /></Link>
            </IconButton>
            : null
          }
        </div>
        <div className="card two gcrg20">
          { state.projectSectionId === 5
            ? <div className="two gcrg20 jstart">
              <Button
                className="btn jstart"
                variant="contained"
                color="primary"
                disabled={!section.buttons[0].has_permissions}
                onClick={() => {
                  createItem(section.page_section_id, { key: 'item_type', value: 'bowser' });
                }
                }
              >+ Bowser Fuel</Button>
              <Button
                className="btn jstart"
                variant="contained"
                color="primary"
                disabled={!section.buttons[0].has_permissions}
                onClick={() => createItem(section.page_section_id)}
              >+ Plant Fuel</Button>
            </div>
            : <Button
              className="btn jstart"
              variant="contained"
              color="primary"
              disabled={!section.buttons[0].has_permissions}
              onClick={() => createItem(section.page_section_id)}
            >+ Item</Button>
          }
          <BasicComponent.DynamicCollapsibleItemsTable
            key={section.page_section_id}
            section_type={sectionType}
            tableName="table items col2-span"
            {...section}
            filter={itemsFilter}
            onUpdate={onUpdate}/>
        </div>
      </section>;
    }
  };

  return (
    <>
      <EnhancedComponent.SideDrawer/>
      <div className={`container ${currentSection}`}>
        {
          loading ? <div className="loader">
            <CircularProgress className="jcenter" size={60}/>
            <p className="center">Loading data...</p>
          </div> : <> {state.data.map((section:any) => (
            renderSwitch(section)
          ))}
          <BasicComponent.CustomizedSnackbars message={alertMessage.message} severity={alertMessage.severity} onOpen={openAlert} onClose={(open:any) => setOpenAlert(open)}/>
          </>
        }
      </div>
    </>
  );
};

export default ProjectSection;
