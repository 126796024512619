import { TableCell, FormControl, NativeSelect } from '@material-ui/core';
import React from 'react';

const EditCell = (props: {
    data: any;
    onChange: (params: any) => any;
    value: any;
    tableRow: any;
    onSetTableRow: (params: any) => any;
    updateTableRow: any;
    onSetUpdateTableRow: (params: any) => any;
}) => {
  const {
    data,
    onChange,
    value,
    tableRow,
    onSetTableRow,
    updateTableRow,
    onSetUpdateTableRow
  } = props;
  const editableData = data;

  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    data: any
  ) => {
    const newGroupTypeId = parseInt(event.target.value);
    onChange(newGroupTypeId);

    const newgroupTypeData = {
      group_type_id: 0,
      budget_group_type_id: 0,
      budget_group_type_name: '',
      budget_group_type_description: ''
    };

    data.dropdown.group_types.forEach((groupType: any) => {
      if (groupType.id === newGroupTypeId) {
        const name = { ...tableRow.cells[0], text: groupType.name };
        const description = {
          ...tableRow.cells[1],
          text: groupType.description
        };
        newgroupTypeData.group_type_id = newGroupTypeId;
        newgroupTypeData.budget_group_type_id = value;
        newgroupTypeData.budget_group_type_name = groupType.name;
        newgroupTypeData.budget_group_type_description =
                    groupType.description;

        onSetTableRow({
          ...tableRow,
          cells: [name, description, ...tableRow.cells.slice(2, 6)]
        });
      }
    });
    onSetUpdateTableRow({
      ...updateTableRow,
      data: {
        ...updateTableRow.data,
        group_type_id: newgroupTypeData.group_type_id,
        budget_group_type_id: newgroupTypeData.budget_group_type_id
      }
    });
  };

  return (
    <React.Fragment>
      {!editableData.dropdown ? (
        <TableCell align={editableData.align}>
          {editableData.text}
        </TableCell>
      ) : (
        <TableCell align={editableData.align}>
          <FormControl
            key='cell-dropdown'
            className='dropdown jstart'
          >
            <NativeSelect
              value={value}
              onChange={(event) =>
                handleChange(event, editableData)
              }
              inputProps={{
                name: 'cell-dropdown'
              }}
            >
              <option value='-1'>Select</option>
              {editableData.dropdown.group_types.map(
                (cell: any) => (
                  <option
                    key={cell.id}
                    value={cell.id}
                  >{`${cell.name} - ${cell.description}`}</option>
                )
              )}
            </NativeSelect>
          </FormControl>
        </TableCell>
      )}
    </React.Fragment>
  );
};

export default EditCell;
