/* eslint-disable */
import * as React from 'react';

type TableType = {
  filterChecks: boolean,
  globalEdit: boolean,
  editBudget: boolean,
  editItems: boolean,
  exchangeRate: {
    slug: string,
    data: {
      id: number,
      exchange_rate: string
    }
  };
  update: {
    slug: string,
    data: any
  };
  updateJobAccess: {
    slug: string,
    data: {
      page: string,
      user_id: number,
      items: Array<any>
    }
  };
  deleteData: {
    slug: string,
    data: any
  };
  otherCharges: {
    currency_symbol: string,
    charges: Array<any>
  }
  filterCheckSet: (toggle:boolean) => void;
  globalEditSet: (toggle:boolean) => void;
  editBudgetSet: (toggle:boolean) => void;
  editItemsSet: (toggle:boolean) => void;
  exchangeRateSet: (slug: string, id: number, exchange_rate: string) => void;
  updateSet: (data: any) => void;
  updateJobAccessSet: (data: any) => void;
  deleteSet: (data: any) => void;
  otherChargesSet: (charges: any) => void;
  updateChargeFunc: (charge: any) => void;
}

export const TableContext = React.createContext<TableType>({
  filterChecks: false,
  globalEdit: false,
  editBudget: false,
  editItems: false,
  exchangeRate: {
    slug: "change_job_exchange_rate_value",
    data: {
      id: 0,
      exchange_rate: ''
    }
  },
  update: {
    slug: "update",
    data: {}
  },
  updateJobAccess: {
    slug: "update",
    data: {
      page: "user_jobs",
      user_id: 0,
      items: [{}]
    }
  },
  deleteData: {
    slug: "delete",
    data: {}
  },
  otherCharges: {
    currency_symbol: 'R',
    charges: Array<any>()
  },
  filterCheckSet: () => null,
  globalEditSet: () => null,
  editBudgetSet: () => null,
  editItemsSet: () => null,
  exchangeRateSet: () => null,
  updateSet: () => null,
  updateJobAccessSet: () => null,
  deleteSet: () => null,
  otherChargesSet: () => null,
  updateChargeFunc: () => null
});

export const useTableContext = () => React.useContext(TableContext);

const TableProvider: React.FC<{}> = ({ children }) => {
  const [filterChecks, setFilterChecks] = React.useState(false);
  const [globalEdit, setGlobalEdit] = React.useState(false);
  const [editBudget, setEditBudget] = React.useState(false);
  const [editItems, setEditItems] = React.useState(false);
  const [exchangeRate, setExchangeRate] = React.useState({
    slug: "change_job_exchange_rate_value",
    data: {
      id: 0,
      exchange_rate: ''
    }
  });
  const [update, setUpdate] = React.useState({
    slug: "update",
    data: {}
  });
  const [updateJobAccess, setUpdateJobAccess] = React.useState({
    slug: "update",
    data: {
      page: "user_jobs",
      user_id: 0,
      items: [{}]
    }
  });
  const [deleteData, setDeleteData] = React.useState({
    slug: "delete",
    data: {}
  });
  const [otherCharges, setOtherCharges] = React.useState({
    currency_symbol: 'R',
    charges: Array<any>()
  })

  function filterCheckSet (toggle:boolean) {
    setFilterChecks(toggle);
  }

  function globalEditSet (toggle:boolean) {
    setGlobalEdit(toggle);
  }

  function editBudgetSet (toggle:boolean,) {
    setEditBudget(toggle);
  }

  function editItemsSet (toggle:boolean,) {
    setEditItems(toggle);
  }
  
  function exchangeRateSet (slug: string, id: number, exchange_rate: string) {
    setExchangeRate({
      slug,
      data: {
        id,
        exchange_rate
      }
    })
  };

  function updateSet (data: any) {
    setUpdate({
      ...update,
      data: {...data}
    })
  };

  function updateJobAccessSet (job: any) {
    const foundItem = updateJobAccess.data.items.find((item: any) => (item.key === job.item.key));
    if (foundItem && updateJobAccess.data.items && updateJobAccess.data.items.length > 0) {
      // Replace the found item
      const index = updateJobAccess.data.items.indexOf(foundItem);
      updateJobAccess.data.items[index] = job.item
    } else {
      // Create new item
      setUpdateJobAccess({
        ...updateJobAccess,
        data: {
          ...updateJobAccess.data,
          items: [ ...updateJobAccess.data.items, job.item ]
        }
      });
    }
  };

  function deleteSet (data: any) {
    setDeleteData({
      ...deleteData,
      data
    })
  };

  function otherChargesSet (charges: any) {
    // const newCharges = otherCharges.charges.find
    if (charges.length > 0) {
      const currency_symbol = charges[0].text.charAt(0)
      setOtherCharges({
        currency_symbol,
        charges: charges
      })
    } else {
      setOtherCharges({
        ...otherCharges,
        charges: []
      })
    }
  };

  function updateChargeFunc (charge: any) {
    setOtherCharges({
      ...otherCharges,
      charges: [ ...otherCharges.charges, charge]
    })
  }
  
  return (
    <TableContext.Provider value={{
      filterChecks,
      globalEdit,
      editBudget,
      editItems,
      exchangeRate,
      update,
      updateJobAccess,
      deleteData,
      otherCharges,
      filterCheckSet,
      globalEditSet,
      editBudgetSet,
      editItemsSet,
      exchangeRateSet,
      updateSet,
      updateJobAccessSet,
      deleteSet,
      otherChargesSet,
      updateChargeFunc
    }}>
      {children}
    </TableContext.Provider>
  );
};

export default TableProvider;

// Build provider
// Create hook (expose context)
// index.tsx - context folder. Wrap childers with providers
// main app wrap in index.tsx
