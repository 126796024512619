import React from 'react';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import './style.css';

export const Tooltip = (props: any) => {
  const { delay, children, content, direction } = props;

  return (
    <MuiTooltip title={
      <div className={`Tooltip-Tip ${direction || 'top'}`}>
        <p>{content}</p>
      </div>
    } enterDelay={delay}>
      {children}
    </MuiTooltip>
  );
};
