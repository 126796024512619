import React, { useContext } from 'react';
import { GlobalContext } from '../../../context/globalState';
import S3FileUpload from 'react-s3';
import './upload.style.css';

interface Props {
  upload: any,
  id?:number,
  size?: number,
  folder?: string
  disabled?: boolean
}

export const UploadFile = ({ upload, id, size, folder, disabled }: Props) => {
  const { user } = useContext(GlobalContext);

  const config = {
    bucketName: process.env.REACT_APP_STORAGE_NAME,
    dirName: `assets/${user.company_name}/${folder || 'attachments'}`,
    region: 'eu-west-1',
    accessKeyId: process.env.REACT_APP_STORAGE_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_STORAGE_ACCESS_KEY
  };

  const handleChange = (e:any) => {
    if (e.target.files.length) {
      S3FileUpload.uploadFile(e.target.files[0], config)
        .then((data:any) => {
          upload(data.location);
        })
        .catch((err:any) => {
          alert(err);
        });
    }
  };

  return (
    <div className={`upload ${disabled ? 'disabled' : ''}`}>
      <label htmlFor={`upload-button-${id}`}>
        <div className="button">
          <h1 className="center">+ Attachment</h1>
        </div>
      </label>
      <input
        type="file"
        id={`upload-button-${id}`}
        disabled={disabled}
        style={{ display: 'none' }}
        onChange={handleChange}
      />
    </div>
  );
};
